import { ListingRequest } from '@/api/types';
import { client } from '@/client';
import { AccountCourseDelivery, AccountFormState } from '@/client/accounts';
import { LoadingStateType } from '@/common/constants';
import {
  availableLanguagesDropdown,
  courseOnlyLanguages,
} from '@/common/constants/languages';
import { accountSchema } from '@/components/accounts/forms/validations/accounts';
import {
  FormikCheckbox,
  FormikDropdown,
  FormikWrappedSwitch,
} from '@/components/form';
import { FormikChips } from '@/components/form/FormikChips';
import { FormikInput } from '@/components/form/FormikInput';
import { FormikLazyDropdown } from '@/components/form/FormikLazyDropdown';
import { AppButton } from '@/ui/buttons';
import { AppCalendar } from '@/ui/calendar';
import { CheckboxesContainer } from '@/ui/checkboxes-container';
import { FlexContainer, FormContainer } from '@/ui/styled-ui';
import { courseDeliveryLmsOptions, isOutsideOfEfront } from '@/utils/helpers';
import { Field, Form, Formik } from 'formik';
import moment from 'moment';
import { Checkbox, CheckboxChangeEvent } from 'primereact/checkbox';
import { DropdownChangeEvent } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { FormEvent } from 'primereact/ts-helpers';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SendHisoricalDataModal } from '../modals';

type AccountFormProps = {
  isCreate: boolean;
  initialValues: AccountFormState;
  state?: LoadingStateType;
  disabled?: boolean;
  locked?: boolean;
  accountId?: string;
  onSubmit: (data: AccountFormState) => void;
};

export const AccountForm: React.FC<AccountFormProps> = ({
  isCreate,
  initialValues,
  state,
  disabled,
  locked,
  accountId,
  onSubmit,
}) => {
  const { t } = useTranslation();
  const [showLms, setShowLms] = useState<boolean>(false);
  const [showSendHistoricalDataModal, setShowSendHistoricalDataModal] =
    useState(false);

  useEffect(() => {
    setShowLms(initialValues.courseDelivery !== AccountCourseDelivery.CP_APP);
  }, [initialValues]);

  const fetchResellers = async (params: ListingRequest) =>
    await client.resellers.getResellers(params);

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={accountSchema(t, isCreate)}
        onSubmit={onSubmit}
        validateOnMount
      >
        {({ values, errors, setFieldValue, setFieldTouched, validateForm }) => {
          // Dont use useEffect in render functions
          /*useEffect(() => {
            if (!values.freeTrialCheck) {
              validateForm();
            }
          }, [values]);*/

          return (
            <FormContainer width={100}>
              <Form className="w-full" autoComplete="off">
                <FlexContainer
                  gap={40}
                  width="100%"
                  justify="space-between"
                  align="flex-start"
                  wrap="wrap"
                >
                  <div className="flex-1">
                    <div className="field w-full mb-4">
                      <Field
                        id="name"
                        name="name"
                        label={t('generic.name')}
                        className="w-full"
                        component={FormikInput}
                        placeholder={t('account.accountName')}
                        required={!initialValues.name}
                        disabled={disabled || locked}
                      />
                    </div>
                    {isCreate && !disabled && (
                      <div className="field w-full mb-4">
                        <Field
                          id="hubspotId"
                          name="hubspotId"
                          label={t('hubspot.companyId')}
                          className="w-full"
                          component={FormikInput}
                          placeholder={t('hubspot.companyId')}
                          required={!initialValues.hubspotId && !values.type}
                          disabled={disabled || values.type || locked}
                        />
                      </div>
                    )}
                    {isCreate && !disabled && (
                      <div className="field w-full mb-4">
                        <Field
                          id="hubspotDomains"
                          name="hubspotDomains"
                          label={t('hubspot.domains')}
                          className="w-full"
                          component={FormikChips}
                          placeholder={t('hubspot.domains')}
                          required={
                            !initialValues.hubspotDomains?.length &&
                            !values.type &&
                            isCreate
                          }
                          disabled={disabled || values.type || !isCreate}
                        />
                      </div>
                    )}
                    <div className="field w-full mb-4">
                      <Field
                        id="subdomain"
                        name="subdomain"
                        label={t('account.subdomain')}
                        className="w-full"
                        component={FormikInput}
                        disabled={initialValues.subdomain || disabled || locked}
                      />
                    </div>
                    <div className="field w-full mb-4">
                      <Field
                        id="defaultLanguage"
                        label={t('account.defaultLanguage')}
                        name="defaultLanguage"
                        filter
                        onChange={(e: DropdownChangeEvent) =>
                          setFieldValue('defaultLanguage', e.value)
                        }
                        onBlur={() => setFieldTouched('defaultLanguage', true)}
                        className="w-full"
                        component={FormikDropdown}
                        placeholder={t('generic.select')}
                        options={availableLanguagesDropdown.filter(
                          ({ value }) => !courseOnlyLanguages.includes(value),
                        )}
                        disabled={locked}
                      />
                    </div>
                    {!disabled && (
                      <>
                        {!values?.mainAccountOfReseller && (
                          <div className="field w-full mb-4">
                            <Field
                              id="reseller"
                              label={t('reseller')}
                              name="reseller"
                              showClear
                              filter
                              onChange={(e: DropdownChangeEvent) =>
                                setFieldValue('reseller', e.value)
                              }
                              onBlur={() => setFieldTouched('reseller', true)}
                              optionLabel="name"
                              className="w-full"
                              component={FormikLazyDropdown}
                              placeholder={t('generic.select')}
                              fetchService={fetchResellers}
                              disabled={disabled || locked}
                            />
                          </div>
                        )}
                        {!!values?.mainAccountOfReseller && (
                          <div className="field w-full mb-4">
                            <label htmlFor="mainAccountOfReseller">
                              {t('reseller.mainAccount.forReseller')}
                            </label>
                            <InputText
                              value={
                                values.mainAccountOfReseller?.name ??
                                values.mainAccountOfReseller?.id
                              }
                              id="mainAccountOfReseller"
                              name="mainAccountOfReseller"
                              className="w-full"
                              disabled={true}
                            />
                          </div>
                        )}
                        <CheckboxesContainer
                          label={t('generic.status')}
                          className="toggle-container mb-4"
                        >
                          <Field
                            inputId="active"
                            name="active"
                            label={t('account.setActive')}
                            component={FormikWrappedSwitch}
                            disabled={disabled || locked}
                          />
                        </CheckboxesContainer>
                      </>
                    )}
                  </div>
                  <div className="flex-1">
                    {!disabled && isOutsideOfEfront() && (
                      <>
                        <CheckboxesContainer label={t('account.type')}>
                          <div className="field-checkbox mb-0">
                            <Field
                              inputId="type"
                              name="type"
                              label={t('account.mark.test')}
                              component={FormikCheckbox}
                              disabled={disabled || locked}
                            />
                          </div>
                          <div className="field-checkbox mb-0">
                            <Field
                              inputId="isPhishingOnly"
                              name="isPhishingOnly"
                              label={t('account.mark.phishingOnly')}
                              component={FormikCheckbox}
                              disabled={disabled || locked}
                              tooltip={t('account.mark.phishingOnly.tooltip')}
                              tooltipId="isPhishingOnly-info"
                            />
                          </div>
                          <div className="field-checkbox mb-0">
                            <Field
                              inputId="freeTrialCheck"
                              name="freeTrialCheck"
                              label={t('account.set.freeTrial')}
                              onChange={async (e: CheckboxChangeEvent) => {
                                setFieldValue('freeTrialCheck', e.checked);
                                if (!e.checked) {
                                  setFieldValue('freeTrialCheck', false);
                                  setFieldValue('freeTrialEndsAt', null);
                                }

                                setTimeout(async () => {
                                  await validateForm();
                                }, 0);
                              }}
                              component={FormikCheckbox}
                              disabled={disabled || locked}
                            />
                          </div>
                          {values.freeTrialCheck && (
                            <div className="field without-spacing">
                              <label htmlFor="freeTrialEndsAt">
                                {t('account.freeTrial.endDate')}
                              </label>
                              <AppCalendar
                                disabled={disabled || locked}
                                id="free-trial-ends-at"
                                name="freeTrialEndsAt"
                                className="w-full white-input"
                                showIcon
                                iconPos="left"
                                minDate={new Date()}
                                placeholder={t(
                                  'account.select.freeTrial.endDate',
                                )}
                                value={
                                  values.freeTrialEndsAt
                                    ? moment(values.freeTrialEndsAt?.toString())
                                        .utc(true)
                                        .toDate()
                                    : null
                                }
                                onChange={(event: FormEvent) =>
                                  setFieldValue(
                                    'freeTrialEndsAt',
                                    moment(event.value?.toString())
                                      .utc(true)
                                      .toDate(),
                                  )
                                }
                                showButtonBar
                                onClearButtonClick={() => {
                                  setFieldValue('freeTrialEndsAt', null);
                                }}
                              />
                              <br />
                              {!values.freeTrialEndsAt && (
                                <small className="p-error">
                                  {t('account.error.select.freeTrial.endDate')}
                                </small>
                              )}
                            </div>
                          )}
                          <div className="field-checkbox mb-0">
                            <Checkbox
                              checked={showLms}
                              name="showLms"
                              onChange={(e) => {
                                setShowLms(!!e.checked);
                                if (!e.checked) {
                                  setFieldValue(
                                    'courseDelivery',
                                    AccountCourseDelivery.CP_APP,
                                  );
                                }
                              }}
                              disabled={disabled || locked}
                            />
                            <label htmlFor="courseDelivery">
                              {t('account.set.courseDelivery')}
                            </label>
                          </div>
                          {!disabled && showLms && (
                            <div className="field w-8 mb-0">
                              <Field
                                id="courseDelivery"
                                label={t('account.courseDelivery')}
                                name="courseDelivery"
                                filter
                                onBlur={() =>
                                  setFieldTouched('courseDelivery', true)
                                }
                                className="w-full white"
                                component={FormikDropdown}
                                placeholder={t('generic.select')}
                                options={courseDeliveryLmsOptions(t)}
                                disabled={disabled || locked}
                              />
                            </div>
                          )}
                        </CheckboxesContainer>
                        <CheckboxesContainer
                          label={t('account.courseSettings')}
                          tooltip={t('account.courseSettings.tooltip')}
                          tooltipId="course-settings"
                        >
                          <div className="field-checkbox mb-0">
                            <Field
                              inputId="autoCourseAvailability"
                              name="autoCourseAvailability"
                              label={t('account.autoCourseAvailability')}
                              component={FormikCheckbox}
                              disabled={disabled || locked}
                            />
                          </div>
                          <div className="field-checkbox mb-0">
                            <Field
                              inputId="autoCourseEnrollment"
                              name="autoCourseEnrollment"
                              label={t('account.autoCourseEnrollment')}
                              component={FormikCheckbox}
                              disabled={disabled || locked}
                            />
                          </div>
                        </CheckboxesContainer>
                        <CheckboxesContainer
                          label={t('account.other.settings')}
                        >
                          <div className="field-checkbox mb-0">
                            <Field
                              inputId="isTrackingEnabled"
                              name="isTrackingEnabled"
                              label={t('account.isTrackingEnabled')}
                              component={FormikCheckbox}
                              disabled={disabled || locked}
                            />
                            <AppButton
                              icon="pi pi-refresh"
                              size="sm"
                              type="text"
                              className="ml-1 p-0 h-auto"
                              ariaLabel={t('account.send.historicalData')}
                              tooltip={t('account.send.historicalData')}
                              tooltipOptions={{ position: 'top' }}
                              isDisabled={
                                isCreate ||
                                !initialValues.isTrackingEnabled ||
                                !values.isTrackingEnabled ||
                                locked
                              }
                              onClick={() =>
                                setShowSendHistoricalDataModal(true)
                              }
                            />
                          </div>
                          <div className="field-checkbox mb-0">
                            <Field
                              inputId="showCampaignOpenEvents"
                              name="showCampaignOpenEvents"
                              label={t('account.show.emailsOpened')}
                              component={FormikCheckbox}
                              disabled={disabled || locked}
                            />
                          </div>
                          <div className="field-checkbox mb-0">
                            <Field
                              inputId="enableCoursePlanner"
                              name="enableCoursePlanner"
                              label={t('account.enable.trainingPlanner')}
                              component={FormikCheckbox}
                              disabled={
                                disabled ||
                                locked ||
                                !!initialValues?.enableCoursePlanner
                              }
                              tooltip={t(
                                'account.enabled.trainingPlanner.tooltip',
                              )}
                              tooltipId="trainingPlanner-info"
                            />
                          </div>
                          <div className="field-checkbox mb-0">
                            <Field
                              inputId="enableMaterialsUpload"
                              name="enableMaterialsUpload"
                              label={t('account.enable.enableMaterialsUpload')}
                              component={FormikCheckbox}
                              disabled={disabled || locked}
                            />
                          </div>
                        </CheckboxesContainer>
                      </>
                    )}
                  </div>
                </FlexContainer>
                {!locked && (
                  <AppButton
                    isSubmit
                    severity="secondary"
                    label={
                      initialValues.name ? t('button.save') : t('button.create')
                    }
                    className="w-2 min-w-min mt-2 mb-5"
                    state={state}
                    isDisabled={!!Object.keys(errors).length}
                  />
                )}
              </Form>
            </FormContainer>
          );
        }}
      </Formik>
      <SendHisoricalDataModal
        visible={showSendHistoricalDataModal}
        accountId={accountId}
        onHide={() => setShowSendHistoricalDataModal(false)}
      />
    </>
  );
};
