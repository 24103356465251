import { like } from '@/api/helpers';
import { FiltersType } from '@/api/types';
import { client } from '@/client';
import { SendingProfile } from '@/client/sender-profiles';
import {
  AutoComplete,
  AutoCompleteCompleteEvent,
} from 'primereact/autocomplete';
import React, { HTMLAttributes, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const StyledAutoComplete = styled(AutoComplete)`
  input {
    width: 100%;
  }
`;

type SenderProfileAutocompleteInputProps = {
  onChange: (value: SendingProfile) => void;
  defaultValue?: SendingProfile;
  itemTemplate?: React.ReactNode;
  additionalFilters?: FiltersType;
} & Omit<HTMLAttributes<HTMLInputElement>, 'defaultValue' | 'onChange'>;

export const SenderProfileAutocompleteInput: React.FC<
  SenderProfileAutocompleteInputProps
> = ({ onChange, defaultValue, itemTemplate, additionalFilters, ...rest }) => {
  const { t } = useTranslation();
  const ref = useRef<any>();
  const [searchResults, setSearchResults] = useState<SendingProfile[]>([]);
  const [fieldValue, setFieldValue] = useState<SendingProfile | undefined>(
    defaultValue,
  );

  const onCompleteMethod = async (e: AutoCompleteCompleteEvent) => {
    const senderProfiles = await client.senderProfiles.getSenderProfiles({
      take: 50,
      filters: [like('name', e.query), ...(additionalFilters ?? [])],
      sort: ['name,asc'],
    });

    setSearchResults(
      senderProfiles.result.map((a) => ({
        ...a,
        name: a.name,
      })),
    );
  };

  useEffect(() => {
    setFieldValue(defaultValue);
  }, [defaultValue]);

  return (
    <StyledAutoComplete
      ref={ref}
      itemTemplate={itemTemplate}
      dropdown
      value={fieldValue}
      field="name"
      placeholder={!fieldValue ? t('sender.searchName') : ''}
      suggestions={searchResults}
      completeMethod={onCompleteMethod}
      onChange={(e) => {
        setFieldValue(e.value);
        onChange(e.value);
      }}
      {...rest}
      onSelect={undefined}
      autoCorrect="off"
    />
  );
};
