import { SquareEditIcon } from '@/utils/helpers';
import { t } from 'i18next';
import { Tooltip } from 'primereact/tooltip';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { FlexContainer } from '../styled-ui';

const StyledText = styled.div`
  max-width: calc(100% - 32px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StyledIcon = styled.i`
  cursor: pointer;
  padding: var(--xsmall-padding);
  color: var(--gray-darker);
  font-size: var(--heading-small-font-size);

  > svg {
    display: flex;
  }
`;

const StyledInput = styled.input`
  font-family: inherit !important;
  font-size: inherit !important;
  line-height: inherit !important;
  font-weight: inherit !important;
  padding: 0;
  border: none;
  box-shadow: none;
  outline: none;
  min-width: 50px;
  max-width: 100%;
  background: var(--beige-main);
  margin-right: var(--xsmall-padding);
  caret-color: var(--red-main);
`;

const HiddenSpan = styled.span`
  position: absolute;
  left: -9999px;
  display: inline-block;
  min-width: 50px;
  visibility: hidden;
`;

type EditableTextProps = {
  value: string;
  saveValue: (text: string) => void;
};

export const EditableText: React.FC<EditableTextProps> = ({
  value,
  saveValue,
}) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [text, setText] = useState(value);
  const textRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const hiddenSpanRef = useRef<HTMLElement>(null);
  const [textWidth, setTextWidth] = useState<number>(0);

  useEffect(() => {
    if (textRef.current && !isEditMode) {
      setTextWidth(textRef.current.offsetWidth);
    } else if (inputRef.current && isEditMode) {
      inputRef.current.style.width = `${textWidth}px`;
    }
  }, [isEditMode, textWidth]);

  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (hiddenSpanRef.current) {
      hiddenSpanRef.current.innerHTML = e.target.value.replace(/\s/g, '&nbsp;');
      e.target.style.width = `${hiddenSpanRef.current.offsetWidth}px`;
    }
    setText(e.target.value);
  };

  const handleSave = () => {
    saveValue(text);
    setIsEditMode(false);
  };

  const handleCancel = () => {
    setText(value);
    setIsEditMode(false);
  };

  return !isEditMode ? (
    <div className="flex align-items-center">
      <StyledText ref={textRef}>{text}</StyledText>
      <StyledIcon className="edit ml-1" onClick={() => setIsEditMode(true)}>
        <SquareEditIcon />
      </StyledIcon>
      <Tooltip position="right" target=".edit" content={t('generic.edit')} />
    </div>
  ) : (
    <FlexContainer justify="flex-start" className="relative" width="auto">
      <StyledInput
        ref={inputRef}
        autoFocus
        value={text}
        onChange={handleTextChange}
      />
      <HiddenSpan ref={hiddenSpanRef} />
      <StyledIcon
        className={`pi pi-check-circle ${text ? 'save' : 'p-disabled save'}`}
        onClick={handleSave}
      />
      <Tooltip position="right" target=".save" content={t('button.save')} />
      <StyledIcon className="pi pi-times cancel" onClick={handleCancel} />
      <Tooltip position="right" target=".cancel" content={t('button.cancel')} />
    </FlexContainer>
  );
};
