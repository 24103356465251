import { equal, notEqual } from '@/api/helpers';
import { ListingRequest } from '@/api/types';
import { client } from '@/client';
import { Account } from '@/client/accounts';
import { Group } from '@/client/groups';
import { Role } from '@/client/roles/types';
import { Actions, Subjects, SystemRoles, UserFormState } from '@/client/users';
import { LoadingStateType } from '@/common/constants';
import {
  availableLanguagesDropdown,
  courseOnlyLanguages,
} from '@/common/constants/languages';
import { roleTranslations } from '@/common/constants/roles';
import {
  FormikDropdown,
  FormikMultiSelect,
  FormikSwitch,
} from '@/components/form';
import { FormikInput } from '@/components/form/FormikInput';
import { FormikLazyDropdown } from '@/components/form/FormikLazyDropdown';
import { PartialRequestMultiselect } from '@/components/form/generic';
import {
  createUserSchema,
  updateUserSchema,
} from '@/components/users/forms/validations';
import { useAppSelector } from '@/hooks/store';
import { usePermission } from '@/hooks/usePermission';
import { selectCurrentAccount } from '@/store/features/account';
import { selectCurrentUser } from '@/store/features/users';
import { AppButton } from '@/ui/buttons';
import { FlexContainer, FormContainer } from '@/ui/styled-ui';
import { branchAdminCheck, isOutsideOfEfront } from '@/utils/helpers';
import { Field, Form, Formik, FormikValues } from 'formik';
import { DropdownChangeEvent } from 'primereact/dropdown';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

type UserFormProps = {
  initialValues: UserFormState;
  state?: LoadingStateType;
  onSubmit: (data: FormikValues) => void;
  isCreate?: boolean;
  isReadOnlyView?: boolean;
  roles: Role[];
  groups?: Group[];
  disabled?: boolean;
  isSSOEnabled?: boolean;
};

export const UserForm: React.FC<UserFormProps> = ({
  initialValues,
  state,
  onSubmit,
  isCreate,
  isReadOnlyView,
  roles,
  groups,
  disabled,
  isSSOEnabled,
}) => {
  const { t } = useTranslation();

  const account = useAppSelector(selectCurrentAccount);
  const user = useAppSelector(selectCurrentUser);
  const { can } = usePermission();
  const isBranchAdmin = branchAdminCheck(user, account);

  const fetchAccounts = disabled
    ? undefined
    : async (params: ListingRequest) =>
        await client.accounts.getAccounts(params);

  const [passwordVisibility, setPasswordVisibility] = useState(false);

  // If you are a CP admin, restrict the options super-admin and developer
  const modifyRoleOptions = roles
    ?.filter((role) => {
      if (account?.isSystem) {
        return role.code !== SystemRoles.MULTIPLE_ACCOUNTS_ADMIN;
      } else if (user?.account.isSystem) {
        return (
          role.code !== SystemRoles.DEVELOPER &&
          role.code !== SystemRoles.SUPER_ADMIN
        );
      } else if (!account?.isSystem) {
        return role.code !== SystemRoles.DEVELOPER &&
          role.code !== SystemRoles.SUPER_ADMIN &&
          disabled
          ? true
          : role.code !== SystemRoles.MULTIPLE_ACCOUNTS_ADMIN;
      } else {
        return true;
      }
    })
    .map((role) => ({
      ...role,
      name: roleTranslations(role, t),
    }));

  const fetchBranches = async (params: ListingRequest) =>
    await client.branches.getBranches(
      { ...params, sort: ['name,asc'] },
      account?.id,
    );

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={
        isCreate
          ? createUserSchema(t, isBranchAdmin)
          : updateUserSchema(t, isBranchAdmin)
      }
      onSubmit={onSubmit}
    >
      {({ values, errors, setFieldValue, setFieldTouched }) => (
        <FormContainer>
          <Form className="w-full" autoComplete="off">
            <div className="field w-full mb-4">
              <Field
                id="username"
                label={t('generic.username')}
                type="username"
                name="username"
                className="w-full"
                component={FormikInput}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setFieldValue('username', e.target.value.replace(/ /g, ''))
                }
                placeholder={t('generic.username')}
                required
                disabled={!isCreate || disabled}
              />
            </div>
            <div className="field w-full mb-4">
              <Field
                id="first-name"
                label={t('generic.firstName')}
                name="firstName"
                className="w-full"
                component={FormikInput}
                placeholder={t('generic.firstName')}
                required
                disabled={disabled}
              />
            </div>
            <div className="field w-full mb-4">
              <Field
                id="last-name"
                label={t('generic.lastName')}
                name="lastName"
                className="w-full"
                component={FormikInput}
                placeholder={t('generic.lastName')}
                required
                disabled={disabled}
              />
            </div>
            <div className="field w-full mb-4">
              <Field
                id="email"
                label={t('generic.email')}
                name="email"
                className="w-full"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setFieldValue('email', e.target.value.replace(/ /g, ''))
                }
                component={FormikInput}
                placeholder={t('generic.email')}
                required
                disabled={disabled}
              />
            </div>
            {isOutsideOfEfront() &&
              !isCreate &&
              !isReadOnlyView &&
              !isSSOEnabled && (
                <FlexContainer align="flex-start" className="mb-4">
                  <div className="field w-full mb-0">
                    <Field
                      id="password"
                      label={t('generic.newPassword')}
                      name="password"
                      type={passwordVisibility ? 'text' : 'password'}
                      className="w-full"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setFieldValue(
                          'password',
                          e.target.value.replace(/ /g, ''),
                        )
                      }
                      component={FormikInput}
                      disabled={disabled}
                    />
                  </div>
                  <AppButton
                    icon={passwordVisibility ? 'pi pi-eye' : 'pi pi-eye-slash'}
                    type="text"
                    severity="secondary"
                    onClick={() => setPasswordVisibility((state) => !state)}
                    className="input-eye-button"
                  />
                </FlexContainer>
              )}
            <div className="field w-full mb-4">
              <Field
                id="role"
                label={t('generic.role')}
                name="role"
                filter
                onChange={(e: DropdownChangeEvent) =>
                  setFieldValue('role', e.value)
                }
                onBlur={() => setFieldTouched('role', true)}
                optionLabel="name"
                className="w-full"
                component={FormikDropdown}
                placeholder={t('generic.select')}
                options={modifyRoleOptions}
                required
                disabled={disabled}
              />
            </div>
            {values.role?.code === SystemRoles.MULTIPLE_ACCOUNTS_ADMIN && (
              <div className="field">
                <label>{t('user.assignedAccounts')}</label>
                <PartialRequestMultiselect
                  id="assignedAccounts"
                  name="assignedAccounts"
                  filter
                  dataKey="id"
                  optionLabel="name"
                  display="chip"
                  handleOnChange={(values: Account[]) =>
                    setFieldValue('assignedAccounts', values)
                  }
                  className="w-full"
                  placeholder={t('accounts.select')}
                  fetchService={fetchAccounts}
                  maxSelectedLabels={3}
                  additionalFilters={[
                    equal('active', true),
                    notEqual('id', account?.id),
                  ]}
                  selectedOptions={values?.assignedAccounts}
                  isDisabled={disabled}
                />
              </div>
            )}
            <div className="field w-full mb-4">
              <Field
                id="language"
                label={t('generic.lang')}
                name="language"
                filter
                onChange={(e: DropdownChangeEvent) =>
                  setFieldValue('language', e.value)
                }
                onBlur={() => setFieldTouched('language', true)}
                className="w-full"
                component={FormikDropdown}
                placeholder={t('generic.select')}
                options={availableLanguagesDropdown.filter(
                  ({ value }) => !courseOnlyLanguages.includes(value),
                )}
                required
                disabled={disabled}
              />
            </div>
            <div className="field w-full mb-4">
              <Field
                id="branch"
                showClear={!isBranchAdmin}
                label={t('branch')}
                name="branch"
                filter
                onChange={(e: DropdownChangeEvent) =>
                  setFieldValue('branch', e.value)
                }
                onBlur={() => setFieldTouched('branch', true)}
                optionLabel="name"
                className="w-full"
                component={FormikLazyDropdown}
                placeholder={t('generic.select')}
                fetchService={fetchBranches}
                additionalFilters={[equal('account', account?.id)]}
                disabled={disabled}
                required={isBranchAdmin}
              />
            </div>

            {can(Actions.UPDATE, Subjects.GROUPS) && !isBranchAdmin && (
              <div className="field w-full mb-4">
                <Field
                  id="groups"
                  showClear
                  label={t('groups')}
                  name="groups"
                  filter
                  onChange={(e: DropdownChangeEvent) =>
                    setFieldValue('groups', e.value)
                  }
                  onBlur={() => setFieldTouched('groups', true)}
                  optionLabel="name"
                  className="w-full"
                  component={FormikMultiSelect}
                  maxSelectedLabels={3}
                  placeholder={t('generic.select')}
                  options={groups}
                  disabled={disabled}
                />
              </div>
            )}

            {!isReadOnlyView && (
              <>
                <div className="field-checkbox">
                  <Field
                    inputId="active"
                    name="active"
                    label={t('user.setActive')}
                    component={FormikSwitch}
                    disabled={disabled}
                  />
                </div>

                {!!account?.subdomain && (
                  <div className="field-checkbox">
                    <Field
                      inputId="isManual"
                      name="isManual"
                      label={t('user.useManualLogin')}
                      component={FormikSwitch}
                      disabled={disabled || !isCreate}
                    />
                  </div>
                )}

                <AppButton
                  isSubmit
                  severity="secondary"
                  label={isCreate ? t('button.create') : t('button.save')}
                  className="w-4 mt-4 mb-5"
                  state={state}
                  isDisabled={!!Object.keys(errors).length || disabled}
                />
              </>
            )}
          </Form>
        </FormContainer>
      )}
    </Formik>
  );
};
