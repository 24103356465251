import { FiltersType, ListingRequest } from '@/api/types';
import { client } from '@/client';
import {
  Branch,
  BranchImportUsersRequest,
  DeleteBranchRequest,
  ListBranchesResponse,
  SaveBranchRequest,
  UpdateBranchRequest,
} from '@/client/branches';
import { MessageResponseModel } from '@/client/models';
import {
  DeleteBranchMutation,
  GenericForbiddenApiError,
  SaveBranchMutation,
} from '@/hooks/query';
import { partialRequests } from '@/utils/helpers';
import {
  useMutation,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query';

const queryKey = 'branches';
const singleQueryKey = 'branch';

export const useBranches = (
  params: UseQueryOptions & {
    accountId?: string;
    take?: number;
    skip?: number;
    sort?: string[];
    filters?: FiltersType;
  },
): UseQueryResult<ListBranchesResponse> & {
  branches: ListBranchesResponse | undefined;
} => {
  const {
    accountId,
    take = 5,
    skip = 0,
    sort,
    filters,
    retry = false,
    retryDelay = 500,
    staleTime = 0,
    cacheTime = 0,
    ...restOptions
  } = params;

  const { data, ...rest } = useQuery(
    [queryKey, take, skip, JSON.stringify(sort), JSON.stringify(filters)],
    () => client.branches.getBranches(params, accountId),
    {
      retry,
      retryDelay,
      staleTime,
      cacheTime,
      ...(restOptions as any),
    },
  );

  return {
    branches: data,
    ...(rest as UseQueryResult<ListBranchesResponse>),
  };
};

export const useBranchesPartialRequest = (
  params: UseQueryOptions & {
    accountId?: string;
    sort?: string[];
    filters?: FiltersType;
    take?: number;
    withDescendants?: boolean;
  },
): UseQueryResult<Branch[]> & {
  branches: Branch[] | undefined;
} => {
  const {
    accountId,
    sort,
    filters,
    retry = false,
    retryDelay = 500,
    staleTime = 0,
    cacheTime = 0,
    withDescendants = false,
    take,
    ...restOptions
  } = params;
  const branchesRequest = async (params: ListingRequest) =>
    await client.branches.getBranches(
      {
        ...params,
        sort,
        withDescendants,
      },
      accountId,
    );
  const { data, ...rest } = useQuery(
    [`${queryKey}Partial`],
    () => partialRequests(filters || [], branchesRequest, { take }),
    {
      retry,
      retryDelay,
      staleTime,
      cacheTime,
      ...(restOptions as any),
    },
  );

  return {
    branches: data,
    ...(rest as UseQueryResult<Branch[]>),
  };
};

export const useBranch = (
  params: UseQueryOptions & {
    branchId?: string;
    accountId?: string;
  },
):
  | UseQueryResult<Branch> & {
      branch: Branch | undefined;
    } => {
  const {
    branchId,
    accountId,
    retry = false,
    retryDelay = 500,
    staleTime = 0,
    cacheTime = 0,
    ...restOptions
  } = params;

  const { data, ...rest } = useQuery(
    [singleQueryKey, branchId],
    () => client.branches.getBranch(branchId, accountId),
    {
      retry,
      retryDelay,
      staleTime,
      cacheTime,
      ...(restOptions as UseQueryResult<Branch>),
    },
  );

  return {
    branch: data,
    ...(rest as UseQueryResult<Branch>),
  };
};

export const useSaveBranch = (): {
  create: (payload: SaveBranchRequest) => Promise<Branch>;
} & SaveBranchMutation => {
  const { mutateAsync, ...rest } = useMutation<
    Branch,
    GenericForbiddenApiError,
    SaveBranchRequest
  >('createBranch', (payload: SaveBranchRequest) =>
    client.branches.saveBranch(payload),
  );

  return { create: mutateAsync, ...(rest as any) };
};

export const useUpdateBranch = (): {
  update: (payload: UpdateBranchRequest) => Promise<Branch>;
} & SaveBranchMutation => {
  const { mutateAsync, ...rest } = useMutation<
    Branch,
    GenericForbiddenApiError,
    UpdateBranchRequest
  >('updateBranch', (payload: UpdateBranchRequest) =>
    client.branches.updateBranch(payload),
  );

  return { update: mutateAsync, ...(rest as any) };
};

export const useDeleteBranches = (): {
  delete: ({
    accountId,
    branchId,
  }: DeleteBranchRequest) => Promise<MessageResponseModel>;
} & DeleteBranchMutation => {
  const { mutateAsync, ...rest } = useMutation<
    MessageResponseModel,
    GenericForbiddenApiError,
    DeleteBranchRequest
  >('deleteBranch', ({ accountId, branchId }: DeleteBranchRequest) =>
    client.branches.deleteBranch({ accountId, branchId }),
  );

  return { delete: mutateAsync, ...(rest as any) };
};

export const useBranchUsersImport = (): {
  upload: (payload: BranchImportUsersRequest) => Promise<Branch>;
} & SaveBranchMutation => {
  const { mutateAsync, ...rest } = useMutation<
    MessageResponseModel,
    GenericForbiddenApiError,
    BranchImportUsersRequest
  >('importUsers', (payload: BranchImportUsersRequest) =>
    client.branches.importUsers(
      payload.accountId,
      payload.branchId,
      payload.fileId,
      payload.importKey,
    ),
  );

  return { upload: mutateAsync, ...(rest as any) };
};
