import { processListingParams } from '@/api/helpers';
import { PostUserRequest } from '@/client/accounts';
import {
  Group,
  GroupExportUsersRequest,
  ListGroupsRequest,
  ListGroupsResponse,
  SaveGroupRequest,
  UpdateGroupRequest,
} from '@/client/groups';
import { getExceptionFromAxiosError } from '@/client/helpers';
import { MessageResponseModel } from '@/client/models';
import { AxiosError, AxiosInstance, AxiosResponse } from 'axios';

export const groupsService = (client: AxiosInstance) => {
  const ACCOUNT_GROUPS_BASE_URL = (accountId?: string) =>
    `/accounts/${accountId}/groups`;
  const ACCOUNT_USERS_GROUPS_BASE_URL = (accountId: string, groupId?: string) =>
    `/accounts/${accountId}/groups/${groupId}/users`;

  const getGroups = async (
    params: ListGroupsRequest,
    accountId?: string,
  ): Promise<ListGroupsResponse> => {
    try {
      const result = await client.get<
        ListGroupsResponse,
        AxiosResponse<ListGroupsResponse>
      >(ACCOUNT_GROUPS_BASE_URL(accountId), {
        withCredentials: true,
        params: processListingParams(params),
      });

      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const getGroup = async (
    groupId?: string,
    accountId?: string,
  ): Promise<Group> => {
    try {
      const result = await client.get<Group, AxiosResponse<Group>>(
        `${ACCOUNT_GROUPS_BASE_URL(accountId)}/${groupId}`,
        {
          withCredentials: true,
        },
      );

      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const saveGroup = async (request: SaveGroupRequest): Promise<Group> => {
    try {
      const result = await client.post<Group, AxiosResponse<Group>>(
        ACCOUNT_GROUPS_BASE_URL(request.account),
        request,
        {
          withCredentials: true,
        },
      );

      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const saveUsers = async (
    accountId: string,
    groupId: string,
    request: PostUserRequest,
  ): Promise<MessageResponseModel> => {
    try {
      const result = await client.post<
        MessageResponseModel,
        AxiosResponse<MessageResponseModel>
      >(ACCOUNT_USERS_GROUPS_BASE_URL(accountId, groupId), request, {
        withCredentials: true,
      });

      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const updateGroup = async (request: UpdateGroupRequest): Promise<Group> => {
    try {
      const result = await client.patch<Group, AxiosResponse<Group>>(
        `${ACCOUNT_GROUPS_BASE_URL(request.accountId)}/${request.groupId}`,
        request.updates,
        {
          withCredentials: true,
        },
      );

      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const deleteGroup = async (
    accountId: string,
    groupId: string,
  ): Promise<MessageResponseModel> => {
    try {
      const result = await client.delete<
        MessageResponseModel,
        AxiosResponse<MessageResponseModel>
      >(`${ACCOUNT_GROUPS_BASE_URL(accountId)}/${groupId}`, {
        withCredentials: true,
      });

      return new MessageResponseModel(result.data);
    } catch (e) {
      throw e as AxiosError;
    }
  };

  const deleteUser = async (
    accountId: string,
    groupId: string,
    request: PostUserRequest,
  ): Promise<MessageResponseModel> => {
    try {
      const result = await client.delete<
        MessageResponseModel,
        AxiosResponse<MessageResponseModel>
      >(ACCOUNT_USERS_GROUPS_BASE_URL(accountId, groupId), {
        data: request,
        withCredentials: true,
      });

      return new MessageResponseModel(result.data);
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const importUsers = async (
    accountId: string,
    groupId: string,
    fileId: string,
    importKey: string,
  ): Promise<MessageResponseModel> => {
    try {
      const result = await client.post<
        MessageResponseModel,
        AxiosResponse<MessageResponseModel>
      >(
        `${ACCOUNT_GROUPS_BASE_URL(accountId)}/${groupId}/users/import`,
        { fileId, importKey },
        {
          withCredentials: true,
        },
      );

      return result.data;
    } catch (e) {
      throw e as AxiosError;
    }
  };

  const exportUsers = async (
    request: GroupExportUsersRequest,
  ): Promise<MessageResponseModel> => {
    try {
      const result = await client.post<
        MessageResponseModel,
        AxiosResponse<MessageResponseModel>
      >(
        `${ACCOUNT_GROUPS_BASE_URL(request.accountId)}/${
          request.groupId
        }/users/export`,
        { ...request },
        {
          withCredentials: true,
        },
      );

      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const groupsNotifyUrl = (groupId: string, accountId: string) =>
    `${client.defaults.baseURL}accounts/${accountId}/groups/${groupId}/notify`;

  return {
    getGroups,
    getGroup,
    saveGroup,
    saveUsers,
    updateGroup,
    deleteGroup,
    deleteUser,
    importUsers,
    exportUsers,
    groupsNotifyUrl,
  };
};
