import { FiltersType, ListingRequest, ListingResponse } from '@/api/types';
import { partialRequests } from '@/utils/helpers';
import { t } from 'i18next';
import {
  MultiSelect,
  MultiSelectAllEvent,
  MultiSelectChangeEvent,
  MultiSelectProps,
} from 'primereact/multiselect';
import React, { HTMLAttributes, useEffect, useState } from 'react';

type PartialRequestMultiselectProps = {
  handleOnChange: (value: any) => void;
  fetchService?: (params: ListingRequest) => Promise<ListingResponse<any>>;
  additionalFilters?: FiltersType;
  selectedOptions?: any[];
  customDataMap?: (item: any) => any;
  customDataFilter?: (item: any) => any;
  isDisabled?: boolean;
  preselectAll?: boolean;
} & MultiSelectProps &
  Omit<HTMLAttributes<HTMLSelectElement>, 'onChange'>;

export const PartialRequestMultiselect: React.FC<
  PartialRequestMultiselectProps
> = ({
  handleOnChange,
  fetchService,
  additionalFilters,
  selectedOptions,
  customDataMap,
  customDataFilter,
  isDisabled,
  preselectAll,
  ...rest
}) => {
  const [value, setValue] = useState<any[]>(selectedOptions ?? []);
  const [showSelected, setShowSelected] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [data, setData] = useState<any>([]);
  const [selectAll, setSelectAll] = useState(false);
  const [visibleData, setVisibleData] = useState<any>([]);
  const handleFetchItems = async () => {
    if (!fetchService) return;
    setIsLoading(true);

    let fetchedData = await partialRequests(
      [...(additionalFilters ?? [])],
      fetchService,
    );

    if (customDataFilter) {
      fetchedData = fetchedData?.filter(customDataFilter);
    }

    setData(fetchedData);
    setIsLoading(false);
  };

  useEffect(() => {
    if (!isLoading && preselectAll) {
      const dataMapped = data.map((c: any) => ({ ...c, value: c }));
      setSelectAll(true);
      setValue(dataMapped);
      handleOnChange(dataMapped);
    }
  }, [isLoading, preselectAll]);

  useEffect(() => {
    handleFetchItems();
  }, []);

  useEffect(() => {
    if (selectedOptions && data?.length) {
      setValue(selectedOptions);
    }

    if (selectedOptions?.length !== data.length) {
      setSelectAll(false);
    }

    if (selectedOptions?.length === data.length) {
      setSelectAll(true);
    }
  }, [selectedOptions, data]);

  useEffect(() => {
    let mappedData = showSelected
      ? selectedOptions?.map((c: any) => ({
          ...c,
          value: c,
        }))
      : data?.map((c: any) => ({
          ...c,
          value: c,
        }));

    if (customDataMap) {
      mappedData = mappedData?.map(customDataMap);
    }

    setVisibleData(mappedData);
  }, [showSelected, data]);

  const onPillRemove = (item: any) => {
    const newValue = value.filter((c) => c.id !== item.id);
    handleOnChange(newValue);
    setValue(newValue);
  };

  const selectedItemTemplate = (item: any) => {
    if (!item) {
      return null;
    }

    return (
      <div className="p-multiselect-token" key={item.id}>
        <div className="p-multiselect-token-label">{item.name}</div>
        <button
          aria-label={t('generic.remove')}
          className="p-multiselect-token-icon pi pi-times"
          onClick={() => onPillRemove(item)}
        />
      </div>
    );
  };

  const panelFooterTemplate = (
    <div>
      <button
        className="underline cursor-pointer show-selected-text"
        onClick={() => {
          setShowSelected((state) => !state);
        }}
      >
        {showSelected ? t('generic.showAll') : t('generic.showSelected')}
      </button>
    </div>
  );

  const onSelectAll = (e: MultiSelectAllEvent) => {
    const selectedValues = e.checked ? [] : data;

    setValue(selectedValues);
    handleOnChange(selectedValues);
    setSelectAll(!e.checked);
  };
  const onChange = (e: MultiSelectChangeEvent) => {
    const value = e.value;

    setValue(value);
    handleOnChange(value);
    setSelectAll(value.length === data.length);
  };

  return (
    <MultiSelect
      dropdownIcon={isLoading ? 'pi pi-spinner pi-spin' : 'pi pi-chevron-down'}
      value={value}
      dataKey="id"
      filterBy="name"
      selectedItemTemplate={selectedItemTemplate}
      panelFooterTemplate={panelFooterTemplate}
      options={!isDisabled ? visibleData : value}
      virtualScrollerOptions={
        data?.length > 10 ? { itemSize: 50, autoSize: true } : undefined
      }
      onChange={onChange}
      selectAll={selectAll}
      onSelectAll={onSelectAll}
      onFilter={() => setShowSelected(false)}
      {...(rest as MultiSelectProps)}
      placeholder={isLoading ? t('generic.loading') : rest?.placeholder}
      disabled={isDisabled || isLoading}
    />
  );
};
