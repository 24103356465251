import { Campaign } from '@/client/campaigns';
import { FlexContainer } from '@/ui/styled-ui';
import { Divider } from 'primereact/divider';
import React, { ReactNode } from 'react';
import { CampaignInfo } from './CampaignInfo';
import { EmailTemplatePreview } from './EmailTemplatePreview';

type CatalogueCampaignPropsType = {
  campaign: Campaign;
  actions: ReactNode;
};

export const CatalogueCampaign: React.FC<CatalogueCampaignPropsType> = ({
  campaign,
  actions,
}) => {
  return (
    <>
      <FlexContainer
        key={campaign.id}
        id={campaign.id}
        align="stretch"
        minWidth="680px"
      >
        <EmailTemplatePreview campaign={campaign} actions={actions} />
        <CampaignInfo campaign={campaign} className="catalogue" />
      </FlexContainer>
      <Divider className="my-4" />
    </>
  );
};
