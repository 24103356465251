import { Actions, Subjects, User } from '@/client/users';
import { RedirectPaths, RedirectPathsEnum } from '@/common/constants';
import { usePermission } from '@/hooks/usePermission';
import classNames from 'classnames';
import { MenuItem } from 'primereact/menuitem';
import { TabMenu } from 'primereact/tabmenu';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

type UserTabsProps = {
  user: User;
  hasDocuments?: boolean;
};

const getItemTemplate = (url: string, current: string) => (item: MenuItem) =>
  (
    <Link
      to={url}
      className={classNames({
        'p-menuitem-link': true,
        'p-menuitem-link-active': current.includes(url),
      })}
      role="menuitem"
    >
      <span className="p-menuitem-text">{item.label}</span>
    </Link>
  );

export const UserTabs: React.FC<UserTabsProps> = ({ user, hasDocuments }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const { can } = usePermission();

  const getMenuItems = (): MenuItem[] => {
    const items: MenuItem[] = [];

    if (user && can(Actions.UPDATE, Subjects.USERS)) {
      items.push({
        label: t('generic.info'),
        template: getItemTemplate(
          RedirectPaths[RedirectPathsEnum.USERS_EDIT](user.id),
          location.pathname,
        ),
        data: RedirectPaths[RedirectPathsEnum.USERS_EDIT](user.id),
      });
    }

    if (user && can(Actions.READ, Subjects.USERS)) {
      items.push({
        label: t('generic.properties'),
        template: getItemTemplate(
          RedirectPaths[RedirectPathsEnum.USERS_PROPERTIES](user.id),
          location.pathname,
        ),
        data: RedirectPaths[RedirectPathsEnum.USERS_PROPERTIES](user.id),
      });
    }

    if (user && can(Actions.READ, Subjects.USERS)) {
      items.push({
        label: t('courses'),
        template: getItemTemplate(
          RedirectPaths[RedirectPathsEnum.USERS_COURSES](user.id),
          location.pathname,
        ),
        data: RedirectPaths[RedirectPathsEnum.USERS_COURSES](user.id),
      });
    }

    if (user && can(Actions.READ, Subjects.USERS) && hasDocuments) {
      items.push({
        label: t('materials'),
        template: getItemTemplate(
          RedirectPaths[RedirectPathsEnum.USERS_DOCUMENTS](user.id),
          location.pathname,
        ),
        data: RedirectPaths[RedirectPathsEnum.USERS_DOCUMENTS](user.id),
      });
    }

    return items;
  };

  const items = getMenuItems();
  const paths = items.map((item) => item.data);
  const currentPath = location.pathname;
  const [activeIndex, setActiveIndex] = useState<number>(
    paths.indexOf(currentPath),
  );

  return (
    <TabMenu
      model={items}
      activeIndex={activeIndex}
      onTabChange={(e) => setActiveIndex(e.index)}
    />
  );
};
