import { RedirectPaths, RedirectPathsEnum } from '@/common/constants';
import classNames from 'classnames';
import { MenuItem } from 'primereact/menuitem';
import { TabMenu } from 'primereact/tabmenu';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

const getItemTemplate = (url: string, current: string) => (item: MenuItem) =>
  (
    <Link
      to={url}
      className={classNames({
        'p-menuitem-link': true,
        'p-menuitem-link-active': current.includes(url),
      })}
      role="menuitem"
    >
      <span className="p-menuitem-text">{item.label}</span>
    </Link>
  );

export const EnrollmentTabs: React.FC = () => {
  const { t } = useTranslation();
  const location = useLocation();

  const items: MenuItem[] = [
    {
      label: t('courses.trainingPlanner'),
      template: getItemTemplate(
        RedirectPaths[RedirectPathsEnum.ACCOUNT_COURSE_PLANNER](),
        location.pathname,
      ),
      data: RedirectPaths[RedirectPathsEnum.ACCOUNT_COURSE_PLANNER](),
    },
    {
      label: t('courses.eventLog'),
      template: getItemTemplate(
        RedirectPaths[RedirectPathsEnum.ACCOUNT_COURSE_SCHEDULE](),
        location.pathname,
      ),
      data: RedirectPaths[RedirectPathsEnum.ACCOUNT_COURSE_SCHEDULE](),
    },
  ];

  const paths = items.map((item) => item.data);
  const currentPath = location.pathname;
  const [activeIndex, setActiveIndex] = useState<number>(
    paths.indexOf(currentPath),
  );

  return (
    <TabMenu
      model={items}
      activeIndex={activeIndex}
      onTabChange={(e) => setActiveIndex(e.index)}
      className="flex-shrink-0"
    />
  );
};
