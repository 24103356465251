import { FiltersType, ListingRequest } from '@/api/types';
import { client } from '@/client';
import {
  DeleteGroupRequest,
  Group,
  GroupImportUsersRequest,
  ListGroupsResponse,
  SaveGroupRequest,
  UpdateGroupRequest,
} from '@/client/groups';
import { MessageResponseModel } from '@/client/models';
import {
  DeleteGroupMutation,
  GenericForbiddenApiError,
  SaveGroupMutation,
  UpdateGroupMutation,
} from '@/hooks/query';
import { partialRequests } from '@/utils/helpers';
import {
  useMutation,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query';

const queryKey = 'groups';
const singleQueryKey = 'group';

export const useGroups = (
  params: UseQueryOptions & {
    accountId?: string;
    token?: string;
    take?: number;
    skip?: number;
    sort?: string[];
    filters?: FiltersType;
  },
): UseQueryResult<ListGroupsResponse> & {
  groups: ListGroupsResponse | undefined;
} => {
  const {
    accountId,
    take = 5,
    skip = 0,
    sort,
    filters,
    retry = false,
    retryDelay = 500,
    staleTime = 0,
    cacheTime = 0,
    ...restOptions
  } = params;
  const { data, ...rest } = useQuery(
    [queryKey, take, skip, JSON.stringify(sort), JSON.stringify(filters)],
    () => client.groups.getGroups(params, accountId),
    {
      retry,
      retryDelay,
      staleTime,
      cacheTime,
      ...(restOptions as any),
    },
  );

  return {
    groups: data,
    ...(rest as UseQueryResult<ListGroupsResponse>),
  };
};

export const useGroupsPartialRequest = (
  params: UseQueryOptions & {
    accountId?: string;
    sort?: string[];
    filters?: FiltersType;
  },
): UseQueryResult<Group[]> & {
  groups: Group[] | undefined;
} => {
  const {
    accountId,
    sort,
    filters,
    retry = false,
    retryDelay = 500,
    staleTime = 0,
    cacheTime = 0,
    ...restOptions
  } = params;
  const groupsRequest = async (params: ListingRequest) =>
    await client.groups.getGroups({ ...params, sort }, accountId);
  const { data, ...rest } = useQuery(
    [`${queryKey}Partial`],
    () => partialRequests(filters || [], groupsRequest),
    {
      retry,
      retryDelay,
      staleTime,
      cacheTime,
      ...(restOptions as any),
    },
  );

  return {
    groups: data,
    ...(rest as UseQueryResult<Group[]>),
  };
};

export const useGroup = (
  params: UseQueryOptions & {
    groupId?: string;
    accountId?: string;
  },
):
  | UseQueryResult<Group> & {
      group: Group | undefined;
    } => {
  const {
    accountId,
    groupId,
    retry = false,
    retryDelay = 500,
    staleTime = 0,
    cacheTime = 0,
    ...restOptions
  } = params;

  const { data, ...rest } = useQuery(
    [singleQueryKey, groupId],
    () => client.groups.getGroup(groupId, accountId),
    {
      retry,
      retryDelay,
      staleTime,
      cacheTime,
      ...(restOptions as any),
    },
  );

  return {
    group: data,
    ...(rest as UseQueryResult<Group>),
  };
};

export const useAddGroup = (): {
  create: (payload: SaveGroupRequest) => Promise<Group>;
} & SaveGroupMutation => {
  const { mutateAsync, ...rest } = useMutation<
    Group,
    GenericForbiddenApiError,
    SaveGroupRequest
  >((payload: SaveGroupRequest) => client.groups.saveGroup(payload));

  return { create: mutateAsync, ...(rest as any) };
};

export const useUpdateGroup = (): {
  update: (payload: UpdateGroupRequest) => Promise<Group>;
} & UpdateGroupMutation => {
  const { mutateAsync, ...rest } = useMutation<
    Group,
    GenericForbiddenApiError,
    UpdateGroupRequest
  >((payload: UpdateGroupRequest) => client.groups.updateGroup(payload));

  return { update: mutateAsync, ...(rest as any) };
};

export const useDeleteGroup = (): {
  delete: ({
    accountId,
    groupId,
  }: DeleteGroupRequest) => Promise<MessageResponseModel>;
} & DeleteGroupMutation => {
  const { mutateAsync, ...rest } = useMutation<
    MessageResponseModel,
    GenericForbiddenApiError,
    DeleteGroupRequest
  >(({ accountId, groupId }: DeleteGroupRequest) =>
    client.groups.deleteGroup(accountId, groupId),
  );

  return { delete: mutateAsync, ...(rest as any) };
};

export const useGroupUsersImport = (): {
  upload: (payload: GroupImportUsersRequest) => Promise<Group>;
} & SaveGroupMutation => {
  const { mutateAsync, ...rest } = useMutation<
    MessageResponseModel,
    GenericForbiddenApiError,
    GroupImportUsersRequest
  >('importUsers', (payload: GroupImportUsersRequest) =>
    client.groups.importUsers(
      payload.accountId,
      payload.groupId,
      payload.fileId,
      payload.importKey,
    ),
  );

  return { upload: mutateAsync, ...(rest as any) };
};
