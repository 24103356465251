import { equal, handleAxiosError, notEqual } from '@/api/helpers';
import { AppBreadCrumbTemplate } from '@/app/AppBreadCrumbTemplate';
import { Account } from '@/client/accounts';
import { Branch, BranchFormState } from '@/client/branches';
import { Actions, Subjects, SystemRoles } from '@/client/users';
import { RedirectPaths, RedirectPathsEnum } from '@/common/constants';
import { BranchForm } from '@/components/branches/forms';
import { useBranch, useSaveBranch, useUpdateBranch } from '@/hooks/query';
import { useAppSelector } from '@/hooks/store';
import { usePermission } from '@/hooks/usePermission';
import { useToast } from '@/hooks/useToast';
import { BranchesTabs } from '@/pages/branches/BranchesTabs';
import { selectCurrentAccount } from '@/store/features/account';
import { selectCurrentUser } from '@/store/features/users';
import { AppBreadCrumb } from '@/ui/breadcrumb';
import { FlexContainer } from '@/ui/styled-ui';
import { queryStateConverter } from '@/utils/helpers';
import { AxiosError } from 'axios';
import { FormikValues } from 'formik';
import { omit } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { LoadingPage } from '../LoadingPage';

export const UpdateBranchPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const toast = useToast();
  const saveBranch = useSaveBranch();
  const updateBranch = useUpdateBranch();
  const account = useAppSelector(selectCurrentAccount);
  const currentUser = useAppSelector(selectCurrentUser);
  const { cannot } = usePermission();

  const { branch, isLoading } = useBranch({
    branchId: id,
    accountId: account?.id,
  });

  const filters = [
    equal('account', (account as Account)?.id),
    notEqual('id', id),
  ];

  const [initialValues, setInitialValues] = useState<BranchFormState>({
    name: '',
    active: false,
    account: undefined,
    parent: undefined,
    eFrontSync: false,
  });

  useEffect(() => {
    if (!branch) return;

    setInitialValues({
      name: branch.name,
      active: branch.active,
      account: branch.account,
      parent: branch.parent,
      eFrontSync: true,
    });
  }, [branch]);

  const handleBranchAction = async (data: FormikValues, id: string) => {
    await updateBranch.update({
      accountId: (account as Account)?.id,
      branchId: id,
      updates: omit(
        {
          ...data,
          parent: data.parent ? data.parent?.id : null,
        },
        'account',
      ),
    });

    navigate(RedirectPaths[RedirectPathsEnum.BRANCHES]());
  };

  const handleSubmit = async (data: FormikValues) => {
    try {
      await handleBranchAction(data, (branch as Branch).id);

      toast?.success(t('toast.success'), t('branch.edited'));
    } catch (e) {
      handleAxiosError(e as Error | AxiosError, toast);
    }
  };

  const pathItems = [
    {
      label: (account as Account)?.name,
      url: RedirectPaths[RedirectPathsEnum.EDIT_ACCOUNT](
        (account as Account)?.id,
      ),
      template: AppBreadCrumbTemplate,
    },
    {
      label: t('branches'),
      url: RedirectPaths[RedirectPathsEnum.BRANCHES](),
      template: AppBreadCrumbTemplate,
    },
    {
      label: branch?.name,
      url: RedirectPaths[RedirectPathsEnum.BRANCHES_EDIT](
        (branch as Branch)?.id,
      ),
      template: AppBreadCrumbTemplate,
    },
  ];

  const isReadOnlyView =
    cannot(Actions.UPDATE, Subjects.BRANCHES) ||
    (account?.isSystem && currentUser?.role.code !== SystemRoles.DEVELOPER);

  if (isLoading) {
    return <LoadingPage message={t('generic.loading')} />;
  }

  return (
    <>
      <AppBreadCrumb model={pathItems} />
      {branch && (
        <>
          <h1>{branch.name}</h1>
          <BranchesTabs branch={branch} />
        </>
      )}

      <FlexContainer justify="flex-start">
        <BranchForm
          initialValues={initialValues}
          onSubmit={handleSubmit}
          additionalFilters={filters}
          state={queryStateConverter(branch ? updateBranch : saveBranch)}
          disabled={isReadOnlyView}
        />
      </FlexContainer>
    </>
  );
};
