import { FiltersType, ListingRequest } from '@/api/types';
import { client } from '@/client';
import { Branch } from '@/client/branches';
import { useAppSelector } from '@/hooks/store';
import { selectCurrentAccount } from '@/store/features/account';
import React, { HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';
import { LazyLoadingSelect } from '../generic/LazyLoadingSelect';

type BranchesSelectInputProps = {
  onChange: (value: Branch) => void;
  additionalFilters?: FiltersType;
  selectedValue?: Branch;
  isDisabled?: boolean;
} & Omit<HTMLAttributes<HTMLSelectElement>, 'onChange' | 'defaultChecked'>;

export const BranchesSelectInput: React.FC<BranchesSelectInputProps> = ({
  onChange,
  className,
  additionalFilters,
  selectedValue,
  isDisabled,
}) => {
  const { t } = useTranslation();
  const currentAccount = useAppSelector(selectCurrentAccount);
  const fetchBranches = isDisabled
    ? undefined
    : async (params: ListingRequest) =>
        await client.branches.getBranches(params, currentAccount?.id);

  return (
    <LazyLoadingSelect
      fetchService={fetchBranches}
      filter
      optionLabel="name"
      placeholder={t('branch.select')}
      additionalFilters={additionalFilters}
      selectedValue={selectedValue}
      className={className}
      handleOnChange={onChange}
      disabled={isDisabled}
    />
  );
};
