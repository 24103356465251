import { LanguagesEnum } from '@/api/enums';
import { FiltersType, ListingRequest, ListingResponse } from '@/api/types';
import {
  Campaign,
  CampaignMeta,
  CampaignUserResult,
  SenderProfileType,
} from '@/client/campaigns';
import { SendingProfile } from '@/client/sender-profiles';
import { User } from '@/client/users';
import { TargetGroupStateType } from '@/utils/helpers';

export type ListCampaignsRequest = ListingRequest;
export type ListCampaignUserResultsRequest = ListingRequest;
export type ListCampaignsResponse = ListingResponse<Campaign>;
export type ListCampaignUserResultsResponse =
  ListingResponse<CampaignUserResult>;

export type CampaignFormValues = {
  emailTemplate?: string;
  landingPage?: string;
  targetGroup?: TargetGroupStateType;
  sendingProfile?: SendingProfile;
  periodType?: CampaignEmailingType;
  sendByDate?: Date;
  launchDate?: Date;
  name?: string;
  language?: LanguagesEnum;
  difficulty?: number;
  senderProfileType?: SenderProfileType;
  clickedLinksPercentage?: number;
  submittedDataPercentage?: number;
  description?: string;
  encodeEmailTemplateImages?: boolean;
  isTemplate?: boolean;
  meta?: CampaignMeta;
};
export const CampaignEmailingEnum = {
  DRIP: 'drip',
  BLAST: 'blast',
};

export const CampaignActionsEnum = {
  LAUNCH: 'launch',
  COMPLETE: 'complete',
};

export type CampaignEmailingType =
  | typeof CampaignEmailingEnum.DRIP
  | typeof CampaignEmailingEnum.BLAST;

export type CampaignActionsType =
  | typeof CampaignActionsEnum.LAUNCH
  | typeof CampaignActionsEnum.COMPLETE;

export type UpdateCampaignAction = {
  campaignId: string;
  action: CampaignActionsType;
};

export type SaveCampaignRequest = {
  encodeEmailTemplateImages?: boolean;
  name?: string;
  launchDate?: Date;
  sendByDate?: Date | null;
  emailTemplate?: string;
  landingPage?: string;
  sendingProfile?: string;
  url?: string;
  account?: string;
  users?: string[] | null;
  branches?: string[];
  groups?: string[];
  isTemplate?: boolean;
  language?: LanguagesEnum;
  difficulty?: number;
  senderProfileType?: SenderProfileType;
  clickedLinksPercentage?: number;
  submittedDataPercentage?: number;
  description?: string;
};

export type CloneCampaignRequest = { campaignId: string; account: string };

export type SendTestEmailFormValues = {
  emailType: 'admin' | 'custom';
  email: string;
  firstName?: string;
  lastName?: string;
  position?: string;
  admin?: User;
};

export type SendTestEmail = {
  campaignId: string;
  email: string;
  firstName?: string;
  lastName?: string;
  position?: string;
  emailTemplateId: string;
  sendingProfileId: string;
  landingPageId: string;
  encodeEmailTemplateImages?: boolean;
};

export type UpdateCampaign = {
  encodeEmailTemplateImages?: boolean;
  name?: string;
  launchDate?: Date;
  sendByDate?: Date | null;
  emailTemplate?: string;
  landingPage?: string;
  sendingProfile?: string;
  url?: string;
  account?: string;
  users?: string[] | null;
  branches?: string[];
  groups?: string[];
  isTemplate?: boolean;
  language?: LanguagesEnum;
  difficulty?: number;
  senderProfileType?: SenderProfileType;
  clickedLinksPercentage?: number;
  submittedDataPercentage?: number;
  description?: string;
};

export type UpdateCampaignRequest = {
  updates: UpdateCampaign;
  campaignId: string;
};

export type MigrationCampaignRequest = {
  accountId: string;
  campaignIds: string[];
};

export type ExportCampaignsReportRequest = {
  filters: FiltersType | undefined;
  exportKey: string;
};
