import { FlexContainer } from '@/ui/styled-ui';
import React, { ReactNode } from 'react';
import styled from 'styled-components';

const StyledCard = styled(FlexContainer)`
  color: var(--black-main);
  flex: 1 1 30%;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--default-padding);
  border: 1px solid var(--gray-dark);
  border-radius: var(--xsmall-border-radius);
  overflow: hidden;

  h4 {
    font-size: var(--body-font-size);
    line-height: var(--body-line-height);
    font-weight: 700;
  }

  h6 {
    margin: 0px;
    padding-bottom: var(--default-padding);
    font-weight: 500;
    font-size: var(--small-font-size);
    line-height: var(--small-line-height);
  }

  &.placeholder {
    background-color: var(--beige-main);
    border-color: var(--beige-main);
    min-height: 200px;
  }

  &.background {
    background-color: var(--beige-main);
    border-color: var(--beige-main);
  }
`;

const StyledText = styled.div`
  line-height: var(--small-line-height);
  font-size: var(--small-font-size);
`;

type AccountCardPropsType = {
  title?: string;
  secondTitle?: string;
  content?: string | ReactNode;
  footer?: ReactNode;
  isPlaceholder?: boolean;
  withBackground?: boolean;
} & React.HTMLAttributes<any>;

export const AccountCard: React.FC<AccountCardPropsType> = ({
  title,
  secondTitle,
  content,
  footer,
  isPlaceholder,
  withBackground,
}) => {
  return (
    <StyledCard
      className={
        isPlaceholder ? 'placeholder' : withBackground ? 'background' : ''
      }
    >
      {!isPlaceholder && (
        <>
          <h4 className={secondTitle ? 'm-0 p-0' : 'm-0 pb-3'}>{title}</h4>
          {secondTitle && <h6>{secondTitle}</h6>}
          <StyledText className="m-0 pb-4 text-sm">{content}</StyledText>
          {footer}
        </>
      )}
    </StyledCard>
  );
};
