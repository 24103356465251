import { FilterType, FilterTypeEnum } from '@/components/tables/crud/types';
import { AppButton } from '@/ui/buttons';
import { FlexContainer } from '@/ui/styled-ui';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { DataTableBetweenFilter } from './DataTableBetweenFilter';
import { DataTableInputFilter } from './DataTableInputFilter';

type FilterValue =
  | string
  | object
  | number
  | undefined
  | { from?: string | undefined; to?: string | undefined }
  | string[]
  | number[];

type FilterProps = {
  filter: FilterType;
  value: FilterValue;
  onChange: (value: FilterValue, field: string) => void;
  onRemove: (field: string) => void;
};

export const DataTableFilter: React.FC<FilterProps> = ({
  filter,
  value,
  onChange,
  onRemove,
}) => {
  const { t } = useTranslation();
  const optionLabels: { [k: string]: { yes: string; no: string } } = {
    [FilterTypeEnum.SELECT_YES_NO]: {
      yes: t('dialog.yes'),
      no: t('dialog.no'),
    },
    [FilterTypeEnum.SELECT_AUTO_MANUAL]: {
      yes: t('generic.auto'),
      no: t('generic.manual'),
    },
    [FilterTypeEnum.SELECT_ACTIVE_INACTIVE]: {
      yes: t('generic.active'),
      no: t('generic.inactive'),
    },
    [FilterTypeEnum.SELECT_UPCOMING_PAST]: {
      yes: t('filter.date.upcoming'),
      no: t('filter.date.past'),
    },
    [FilterTypeEnum.SELECT_AVAILABLE_UNAVAILABLE]: {
      yes: t('generic.available'),
      no: t('generic.unavailable'),
    },
  };
  return (
    <FlexContainer wrap="nowrap" width="auto">
      <div className="p-inputgroup">
        <span className="p-inputgroup-addon">{filter.label}</span>
        {filter.type === FilterTypeEnum.TEXT && (
          <DataTableInputFilter
            {...filter}
            onChange={onChange}
            value={value as string | undefined}
            placeholder={filter.placeholder as string}
          />
        )}
        {filter.type === FilterTypeEnum.SELECT && (
          <Dropdown
            options={filter.options}
            placeholder={filter.placeholder as string}
            value={value}
            onChange={(event) => onChange(event.value, filter.field)}
          />
        )}
        {[
          FilterTypeEnum.SELECT_YES_NO,
          FilterTypeEnum.SELECT_AUTO_MANUAL,
          FilterTypeEnum.SELECT_ACTIVE_INACTIVE,
          FilterTypeEnum.SELECT_UPCOMING_PAST,
          FilterTypeEnum.SELECT_AVAILABLE_UNAVAILABLE,
        ].includes(filter.type) && (
          <Dropdown
            options={[
              {
                label: optionLabels[filter.type].yes,
                value: 'yes',
              },
              {
                label: optionLabels[filter.type].no,
                value: 'no',
              },
            ]}
            placeholder={filter.placeholder as string}
            value={value}
            onChange={(event) => onChange(event.value, filter.field)}
          />
        )}
        {filter.type === FilterTypeEnum.BETWEEN && (
          <DataTableBetweenFilter
            {...filter}
            placeholder={filter.placeholder as string[]}
            value={
              value as { from?: string | undefined; to?: string | undefined }
            }
            onChange={(event) => onChange(event.value, filter.field)}
          />
        )}
        {filter.type === FilterTypeEnum.MULTI_SELECT && (
          <MultiSelect
            {...filter}
            display="chip"
            placeholder={filter.placeholder as string}
            defaultValue={value as string[]}
            onChange={(event) => onChange(event.value, filter.field)}
            value={value}
          />
        )}
        <AppButton
          className="group-button"
          icon="pi pi-times"
          severity="secondary"
          type="text"
          onClick={() => onRemove(filter.field)}
        />
      </div>
    </FlexContainer>
  );
};
