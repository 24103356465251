import { Menu } from 'primereact/menu';
import { MenuItem } from 'primereact/menuitem';
import React, { SyntheticEvent, useRef } from 'react';
import AppButton, { AppButtonProps } from './AppButton';

type AppDropdownButtonPropsType = {
  items: MenuItem[];
  menuId: string;
} & AppButtonProps;

const AppDropdownButton: React.FC<AppDropdownButtonPropsType> = ({
  items,
  menuId,
  ...rest
}) => {
  const menuRef = useRef<Menu>(null);
  return (
    <>
      <Menu
        id={menuId}
        model={items}
        popup
        ref={menuRef}
        style={{ width: 'auto', minWidth: '196px' }}
      />

      <AppButton
        icon="pi pi-angle-down"
        iconPos="right"
        aria-controls={menuId}
        onClick={(event: SyntheticEvent<HTMLButtonElement>) =>
          menuRef.current?.toggle(event)
        }
        {...rest}
      />
    </>
  );
};

export default AppDropdownButton;
