import { LanguagesEnum } from '@/api/enums';
import { handleAxiosError } from '@/api/helpers';
import { Account } from '@/client/accounts';
import {
  CourseFormState,
  EfrontCourse,
  MaterialType,
} from '@/client/courses/types';
import { File } from '@/client/files';
import { MaterialsForm } from '@/components/resources/forms';
import { useCourse, useUpdateCourse } from '@/hooks/query';
import { useAppSelector } from '@/hooks/store';
import { useToast } from '@/hooks/useToast';
import { selectCurrentAccount } from '@/store/features/account';
import { FlexContainer } from '@/ui/styled-ui';
import {
  generateCourseTranslationsInitialValues,
  modifyCourseFiles,
  modifyCourseTranslations,
  parseModifiedCourseTranslations,
  queryStateConverter,
} from '@/utils/helpers';
import { AxiosError } from 'axios';
import { FormikValues } from 'formik';
import { ProgressSpinner } from 'primereact/progressspinner';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { CoursesActionHeader, CoursesTabs } from '../courses';

export const MaterialEditPage: React.FC = () => {
  const { t } = useTranslation();
  const toast = useToast();
  const account = useAppSelector(selectCurrentAccount);
  const { id: courseId } = useParams();
  const { course, isLoading, refetch } = useCourse({
    courseId: courseId,
    staleTime: Infinity,
  });

  const [formInitialValues, setFormInitialValues] = useState<CourseFormState>({
    courseTranslations: generateCourseTranslationsInitialValues(),
    courseLanguages: [],
    courseThumbnail: '',
    courseFiles: modifyCourseFiles([
      {
        language: LanguagesEnum.EN,
        file: null,
      },
    ]),
    courseCreatedAt: undefined,
    courseLastUpdatedAt: undefined,
    type: MaterialType.RESOURCE,
    trial: false,
    isStandardCatalogue: false,
    releaseDate: null,
    retired: false,
    customContent: false,
    customAccount: '',
    selfHosted: true,
  });

  const [courseThumbnailsData, setCourseThumbnailData] = useState<File>(
    {} as File,
  );
  const [customAccountData, setCustomAccountData] = useState<Account>(
    {} as Account,
  );

  useEffect(() => {
    if (!course) return;

    setFormInitialValues({
      id: course.id,
      courseTranslations: modifyCourseTranslations(course.courseTranslations),
      courseLanguages: course?.courseLanguages || [],
      courseThumbnail: course.courseThumbnail ? course.courseThumbnail.id : '',
      courseFiles: modifyCourseFiles(course.courseFiles),
      courseCreatedAt: course.courseCreatedAt
        ? new Date(course.courseCreatedAt)
        : undefined,
      courseLastUpdatedAt: course.courseLastUpdatedAt
        ? new Date(course.courseLastUpdatedAt)
        : undefined,
      type: course.type,
      trial: !!course.trial,
      isStandardCatalogue: !!course.isStandardCatalogue,
      releaseDate: course.releaseDate,
      retired: course.retired,
      customAccount: course.account ? course.account.id : '',
      customContent: !!course.account,
      selfHosted: course?.selfHosted,
    });

    if (course.courseThumbnail) {
      setCourseThumbnailData(course.courseThumbnail);
    }

    if (course.account) {
      setCustomAccountData(course.account);
    }
  }, [course]);

  const updateCourse = useUpdateCourse();

  const handleSubmit = async (data: FormikValues) => {
    try {
      if (!account || !courseId) return;

      await updateCourse.update({
        updates: {
          courseTranslations: parseModifiedCourseTranslations(
            data.courseTranslations,
          ),
          courseLanguages: data.courseLanguages,
          courseThumbnail: data.courseThumbnail ? data.courseThumbnail : null,
          courseFiles: Object.keys(data.courseFiles)
            .filter((key) =>
              data.selfHosted
                ? data.courseLanguages.includes(key) &&
                  !!data.courseFiles[key].file
                : !!data.courseFiles[key].file,
            )
            .map((key: string) => ({
              language: key,
              file: data.courseFiles[key]?.file?.id,
            })),
          courseCreatedAt: data.courseCreatedAt ? data.courseCreatedAt : null,
          courseLastUpdatedAt: data.courseLastUpdatedAt
            ? data.courseLastUpdatedAt
            : null,
          type: data.type,
          trial: data.trial,
          isStandardCatalogue: data.isStandardCatalogue,
          account:
            data.customContent && !!data.customAccount
              ? data.customAccount
              : null,
          selfHosted: data.selfHosted,
          eFrontCourseIds: data.eFrontCourses?.map(
            (course: EfrontCourse) => course.id,
          ),
          hubspotProperty: data.hubspotProperty,
        },
        courseId: courseId,
      });

      toast?.success(t('toast.success'), t('material.updated'));
      refetch();
    } catch (e) {
      handleAxiosError(e as Error | AxiosError, toast);
    }
  };

  return (
    <>
      {isLoading && !course && (
        <FlexContainer direction="column" className="mt-5">
          <ProgressSpinner />
          <h3>{t('material.content.loading')}</h3>
        </FlexContainer>
      )}
      {!isLoading && course && (
        <>
          <CoursesActionHeader course={course} />
          {courseId && account?.isSystem && (
            <CoursesTabs
              courseId={courseId}
              isMaterial={
                formInitialValues.type === MaterialType.RESOURCE ||
                formInitialValues.type === MaterialType.CUSTOM_MATERIAL
              }
            />
          )}
          <FlexContainer
            justify="flex-start"
            className={!account?.isSystem ? 'mt-6' : ''}
          >
            <MaterialsForm
              initialValues={formInitialValues}
              state={queryStateConverter(updateCourse)}
              onSubmit={handleSubmit}
              courseThumbnailData={courseThumbnailsData}
              customAccountData={customAccountData}
            />
          </FlexContainer>
        </>
      )}
    </>
  );
};
