import { equal } from '@/api/helpers';
import { MaterialType } from '@/client/courses';
import { ScormStatusAttemptEnum } from '@/client/scorm';
import { useScormCourses } from '@/hooks/query';
import { useAppSelector } from '@/hooks/store';
import { LoadingPage } from '@/pages/LoadingPage';
import { selectCurrentAccount } from '@/store/features/account';
import React, { createContext, useContext } from 'react';
import { useTranslation } from 'react-i18next';

type LearnerContextProps = {
  hasMaterials: boolean;
  hasResources: boolean;
  hasCustomMaterials: boolean;
  coursesToDo: number;
  customMaterialsToDo: number;
};

const LearnerContext = createContext<LearnerContextProps>({
  hasMaterials: false,
  hasResources: false,
  hasCustomMaterials: false,
  coursesToDo: 0,
  customMaterialsToDo: 0,
});

type LearnerProviderProps = {
  children: React.ReactNode;
  isLoading?: boolean;
};

export const LearnerProvider: React.FC<LearnerProviderProps> = ({
  children,
  isLoading,
}) => {
  const { t } = useTranslation();
  const account = useAppSelector(selectCurrentAccount);
  const { isLoading: areMaterialsToDoLoading, scormCourses: materialsToDo } =
    useScormCourses({
      take: 0,
      skip: 0,
      type: ScormStatusAttemptEnum.IN_PROGRESS,
      filters: [equal('type', MaterialType.CUSTOM_MATERIAL)],
    });

  const {
    isLoading: areMaterialsCompletedLoading,
    scormCourses: materialsCompleted,
  } = useScormCourses({
    take: 0,
    skip: 0,
    type: ScormStatusAttemptEnum.COMPLETED,
    filters: [equal('type', MaterialType.CUSTOM_MATERIAL)],
  });

  const { isLoading: areCoursesToDoLoading, scormCourses: coursesToDo } =
    useScormCourses({
      take: 0,
      skip: 0,
      type: ScormStatusAttemptEnum.IN_PROGRESS,
      filters: [equal('type', MaterialType.COURSE)],
    });

  const { isLoading: areResourcesLoading, scormCourses: resources } =
    useScormCourses({
      take: 0,
      skip: 0,
      type: ScormStatusAttemptEnum.IN_PROGRESS,
      filters: [equal('type', MaterialType.RESOURCE)],
    });

  return (
    <>
      {isLoading ||
      areMaterialsToDoLoading ||
      areMaterialsCompletedLoading ||
      areCoursesToDoLoading ||
      areResourcesLoading ? (
        <LoadingPage message={t('generic.loading')} />
      ) : (
        <LearnerContext.Provider
          value={{
            hasMaterials:
              !!resources?.count ||
              ((!!materialsToDo?.count || !!materialsCompleted?.count) &&
                !!account?.meta?.enableMaterialsUpload),
            hasResources: !!resources?.count,
            hasCustomMaterials:
              (!!materialsToDo?.count || !!materialsCompleted?.count) &&
              !!account?.meta?.enableMaterialsUpload,
            coursesToDo: coursesToDo?.count ?? 0,
            customMaterialsToDo: materialsToDo?.count ?? 0,
          }}
        >
          {children}
        </LearnerContext.Provider>
      )}
    </>
  );
};

// Custom hook to use the SCORM context
export const useLearner = () => useContext(LearnerContext);
