import { Branch } from '@/client/branches';
import { Actions, Subjects } from '@/client/users';
import { RedirectPaths, RedirectPathsEnum } from '@/common/constants';
import { usePermission } from '@/hooks/usePermission';
import classNames from 'classnames';
import { MenuItem } from 'primereact/menuitem';
import { TabMenu } from 'primereact/tabmenu';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

type BranchesTabsPros = {
  branch: Branch;
};

const getItemTemplate = (url: string, current: string) => (item: MenuItem) =>
  (
    <Link
      to={url}
      className={classNames({
        'p-menuitem-link': true,
        'p-menuitem-link-active': current.includes(url),
      })}
      role="menuitem"
    >
      <span className="p-menuitem-text">{item.label}</span>
    </Link>
  );

export const BranchesTabs: React.FC<BranchesTabsPros> = ({ branch }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const { can } = usePermission();

  const menuItems = () => {
    const actions: MenuItem[] = [];
    if (can(Actions.UPDATE, Subjects.BRANCHES)) {
      actions.push({
        label: t('generic.info'),
        template: getItemTemplate(
          RedirectPaths[RedirectPathsEnum.BRANCHES_EDIT](branch?.id),
          location.pathname,
        ),
        data: RedirectPaths[RedirectPathsEnum.BRANCHES_EDIT](branch?.id),
      });
    }
    actions.push({
      label: t('users'),
      template: getItemTemplate(
        RedirectPaths[RedirectPathsEnum.BRANCHES_USERS](branch?.id),
        location.pathname,
      ),
      data: RedirectPaths[RedirectPathsEnum.BRANCHES_USERS](branch?.id),
    });

    return actions;
  };

  const items = menuItems();
  const paths = items.map((item) => item.data);
  const currentPath = location.pathname;
  const [activeIndex, setActiveIndex] = useState<number>(
    paths.indexOf(currentPath),
  );

  return (
    <TabMenu
      model={items}
      activeIndex={activeIndex}
      onTabChange={(e) => setActiveIndex(e.index)}
    />
  );
};
