import { AppBreadCrumbTemplate } from '@/app/AppBreadCrumbTemplate';
import { Account } from '@/client/accounts';
import { User } from '@/client/users';
import { RedirectPaths, RedirectPathsEnum } from '@/common/constants';
import { TranslationFunctionType } from '@/common/types';
import { UserDocumentsDatatable } from '@/components/users/datatables/UserDocumentsDatatable';
import { useUser } from '@/hooks/query';
import { useAppSelector } from '@/hooks/store';
import { selectCurrentAccount } from '@/store/features/account';
import { AppBreadCrumb } from '@/ui/breadcrumb';
import { MenuItem } from 'primereact/menuitem';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { LoadingPage } from '../LoadingPage';
import { UserTabs } from './UserTabs';

const getBreadcrumbs = (
  account: Account,
  user: User,
  t: TranslationFunctionType,
): MenuItem[] => {
  const items = [
    {
      label: account?.name,
      url: account?.isSystem
        ? RedirectPaths[RedirectPathsEnum.EDIT_ACCOUNT](account?.id)
        : RedirectPaths[RedirectPathsEnum.ACCOUNT](account?.id),
      template: AppBreadCrumbTemplate,
    },
    {
      label: t('users'),
      url: RedirectPaths[RedirectPathsEnum.USERS](),
      template: AppBreadCrumbTemplate,
    },
    {
      label: user?.name,
      className: 'active',
      url: RedirectPaths[RedirectPathsEnum.USERS_EDIT](user?.id),
      template: AppBreadCrumbTemplate,
    },
    {
      label: t('materials'),
      url: RedirectPaths[RedirectPathsEnum.USERS_DOCUMENTS](user?.id),
      template: AppBreadCrumbTemplate,
    },
  ];

  return items;
};

export const UserDocumentsPage: React.FC = () => {
  const { t } = useTranslation();
  const currentAccount = useAppSelector(selectCurrentAccount);
  const { id } = useParams();
  const { user, isLoading } = useUser({
    userId: id,
    accountId: currentAccount?.id,
  });

  if (!user || isLoading) {
    return <LoadingPage message={t('user.loading')} />;
  }

  return (
    <>
      {currentAccount && user && (
        <AppBreadCrumb model={getBreadcrumbs(currentAccount, user, t)} />
      )}
      {user && (
        <>
          <h1>{user.name ? user.name : user.email}</h1>
          <UserTabs user={user} hasDocuments />
          <UserDocumentsDatatable user={user} />
        </>
      )}
    </>
  );
};
