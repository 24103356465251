import { isKeycloakEnabled } from '@/auth';
import { Account } from '@/client/accounts';
import { selectCurrentAccount } from '@/store/features/account';
import { EventSourcePolyfill } from 'event-source-polyfill';
import { useEffect, useRef, useState } from 'react';
import { useAuth } from 'react-oidc-context';
import { useAppSelector } from './store';

type ServiceUrlType =
  | ((param: string, accountId: string) => string)
  | ((param: string) => string)
  | (() => string);

export const useNotifications = (serviceUrl: ServiceUrlType) => {
  const isKeycloakAuth = isKeycloakEnabled();
  const { user } = useAuth();
  const currentAccount = useAppSelector(selectCurrentAccount);

  const [notificationParam, setNotificationParam] = useState<
    string | undefined
  >(undefined);
  const [lastMessage, setLastMessage] = useState<{
    event: string;
    data: any;
    lastEventId: string;
  } | null>(null);
  const isConnected = useRef(false);

  useEffect(() => {
    if (!notificationParam) return;

    const sourcePolyfill = new EventSourcePolyfill(
      serviceUrl(notificationParam, (currentAccount as Account)?.id),
      {
        withCredentials: true,
        headers: isKeycloakAuth
          ? { Authorization: `Bearer ${user?.access_token}` }
          : {},
      },
    );

    // Check that a connection is established
    sourcePolyfill.onopen = () => {
      isConnected.current = true;
    };

    sourcePolyfill.onmessage = (message) => {
      isConnected.current = true;
      const data = JSON.parse(message.data);
      setLastMessage({ ...message, data } as any);
    };

    // If an error is encountered during connection the Eventsource will set the connection state to false, but will continue to try to connect
    sourcePolyfill.onerror = () => {
      isConnected.current = false;
    };

    return () => {
      sourcePolyfill.close();
      isConnected.current = false;
      setLastMessage(null);
    };
  }, [notificationParam]);

  return { lastMessage, notificationParam, setNotificationParam, isConnected };
};
