import { equal } from '@/api/helpers';
import { FiltersType } from '@/api/types';
import { FilterNamesEnum } from '@/client/helpers';
import { Actions, Subjects, User } from '@/client/users';
import { roleTranslations, systemRoleOptions } from '@/common/constants/roles';
import { TranslationFunctionType } from '@/common/types';
import {
  DataTable,
  DataTableColumnType,
  DataTableFilters,
  DataTableToolbar,
  FilterType,
  FilterTypeEnum,
} from '@/components/tables/crud';
import {
  useAccountUsersPartialRequest,
  useBranchesPartialRequest,
  useGroupsPartialRequest,
} from '@/hooks/query';
import { useAppSelector } from '@/hooks/store';
import { useTable } from '@/hooks/table.hook';
import { usePermission } from '@/hooks/usePermission';
import { selectCurrentAccount } from '@/store/features/account';
import { selectCurrentUser } from '@/store/features/users';
import { FlexContainer } from '@/ui/styled-ui';
import { branchAdminCheck } from '@/utils/helpers';
import { Checkbox } from 'primereact/checkbox';
import React, { ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

type BulkUserDatatableProps = {
  values: { users?: User[] | undefined };
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined,
  ) => void;
  defaultFilters?: FiltersType;
  defaultTableFilters?: Record<string, any>;
  additionalColumns?: DataTableColumnType[];
  toolbarAdditional?: ReactNode;
  shouldRefetch?: boolean;
  branchId?: string;
  showSelected?: boolean;
};

export const BulkUserDatatable: React.FC<BulkUserDatatableProps> = ({
  values,
  setFieldValue,
  defaultFilters = [],
  defaultTableFilters = {},
  additionalColumns,
  toolbarAdditional,
  shouldRefetch,
  showSelected,
}) => {
  const { t } = useTranslation();
  const { can } = usePermission();
  const currentAccount = useAppSelector(selectCurrentAccount);
  const currentUser = useAppSelector(selectCurrentUser);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [isOneSelected, setIsOneSelected] = useState(false);
  const isBranchAdmin = branchAdminCheck(currentUser, currentAccount);

  const { sort, apiFilters, onFilter, onSort } = useTable();

  const { isLoading, users, refetch } = useAccountUsersPartialRequest({
    accountId: currentAccount?.id,
    filters: [...apiFilters, ...defaultFilters],
    sort: sort && sort.length > 0 ? [sort.join(',')] : [],
    withBranches: true,
    withGroups: 1,
  });

  useEffect(() => {
    refetch();
  }, [apiFilters]);

  useEffect(() => {
    if (!shouldRefetch) {
      return;
    }

    refetch();
  }, [shouldRefetch]);

  const { branches } = useBranchesPartialRequest({
    accountId: currentAccount?.id,
    sort: ['name,asc'],
  });
  const { groups } = useGroupsPartialRequest({
    accountId: currentAccount?.id,
    sort: ['name,asc'],
    enabled: !isBranchAdmin,
  });

  const getFilters = (t: TranslationFunctionType): FilterType[] => [
    {
      label: t('generic.id'),
      field: FilterNamesEnum.ID,
      type: FilterTypeEnum.TEXT,
      placeholder: t('generic.id'),
    },
    {
      label: t('generic.name'),
      field: FilterNamesEnum.NAME,
      type: FilterTypeEnum.TEXT,
      placeholder: t('user.search.name'),
    },
    {
      label: t('generic.username'),
      field: FilterNamesEnum.USER_BY_USERNAME,
      type: FilterTypeEnum.TEXT,
      placeholder: t('user.search.username'),
    },
    {
      label: t('generic.email'),
      field: FilterNamesEnum.USER_BY_EMAIL,
      type: FilterTypeEnum.TEXT,
      placeholder: t('user.searchEmail'),
    },
    {
      label: t('filter.status'),
      field: FilterNamesEnum.ACTIVE,
      type: FilterTypeEnum.SELECT_ACTIVE_INACTIVE,
      placeholder: t('generic.select'),
    },
    {
      label: t('generic.role'),
      field: FilterNamesEnum.USER_BY_ROLE,
      type: FilterTypeEnum.SELECT,
      options: systemRoleOptions(t),
      placeholder: t('generic.select'),
    },
    {
      label: t('generic.companyName'),
      field: FilterNamesEnum.COMPANY_NAME,
      type: FilterTypeEnum.TEXT,
      placeholder: t('filter.companyName'),
    },
    {
      label: t('generic.department'),
      field: FilterNamesEnum.DEPARTMENT,
      type: FilterTypeEnum.TEXT,
      placeholder: t('filter.department'),
    },
    {
      label: t('generic.manager'),
      field: FilterNamesEnum.MANAGER,
      type: FilterTypeEnum.TEXT,
      placeholder: t('filter.manager'),
    },
    {
      label: t('generic.managerEmail'),
      field: FilterNamesEnum.MANAGER_EMAIL,
      type: FilterTypeEnum.TEXT,
      placeholder: t('filter.managerEmail'),
    },
    {
      label: t('generic.country'),
      field: FilterNamesEnum.COUNTRY,
      type: FilterTypeEnum.TEXT,
      placeholder: t('filter.country'),
    },
    {
      label: t('generic.jobTitle'),
      field: FilterNamesEnum.JOB,
      type: FilterTypeEnum.TEXT,
      placeholder: t('filter.jobTitle'),
    },
    {
      label: t('generic.mobilePhone'),
      field: FilterNamesEnum.MOBILE_PHONE,
      type: FilterTypeEnum.TEXT,
      placeholder: t('filter.mobilePhone'),
    },
    {
      label: t('generic.officeLocation'),
      field: FilterNamesEnum.OFFICE_LOCATION,
      type: FilterTypeEnum.TEXT,
      placeholder: t('filter.officeLocation'),
    },
    {
      label: t('branch'),
      field: FilterNamesEnum.AWARENESS_REPORTS_BRANCH,
      type: FilterTypeEnum.MULTI_SELECT,
      placeholder: t('filter.branch'),
      options: branches?.map(({ id, name }) => ({
        label: name,
        value: id,
      })),
    },
    ...(can(Actions.READ, Subjects.GROUPS) && !isBranchAdmin
      ? [
          {
            label: t('group'),
            field: FilterNamesEnum.GROUPS,
            type: FilterTypeEnum.MULTI_SELECT,
            placeholder: t('filter.group'),
            options: groups?.map(({ id, name }) => ({
              label: name,
              value: id,
            })),
          },
        ]
      : []),
  ];

  let columns: DataTableColumnType[] = [
    {
      field: 'select',
      style: { width: '54px' },
      className: 'vertical-top',
      header: (
        <Checkbox
          name="selectAll"
          onChange={(e) => {
            if (users?.length != 0) {
              if (e.checked && !showSelected) {
                setFieldValue('users', [
                  ...(values.users
                    ? values.users.filter(
                        (user) => !users?.find((x) => x.id === user.id),
                      )
                    : []),
                  ...(users ? users : []),
                ]);
              } else {
                setFieldValue('users', []);
              }
            }
          }}
          checked={isAllSelected && isOneSelected}
        />
      ),
      sortable: false,
      filterable: false,
      render: (row: User) => (
        <Checkbox
          name={row.email}
          onChange={(e) =>
            e.checked
              ? setFieldValue('users', [
                  ...(values.users ? values.users : []),
                  row,
                ])
              : setFieldValue(
                  'users',
                  values.users?.filter((x) => x.id !== row.id),
                )
          }
          checked={!!values.users?.find((user) => user.id === row.id)}
        />
      ),
    },
    {
      field: 'name',
      header: t('generic.name'),
      sortable: true,
      filterable: false,
      render: (row: User) => (
        <FlexContainer direction="column" align="flex-start">
          <span>{row?.name}</span>
          <span className="secondary-text mt-1">{row?.email}</span>
        </FlexContainer>
      ),
    },
    {
      field: 'username',
      header: t('generic.username'),
      sortable: true,
      filterable: true,
      filters: {
        type: FilterTypeEnum.TEXT,
        placeholder: t('user.search.username'),
      },
      render: (row: User) => row.username,
    },
    {
      field: 'role',
      header: t('generic.role'),
      sortable: false,
      filterable: true,
      filters: {
        type: FilterTypeEnum.SELECT,
        options: systemRoleOptions(t),
      },
      render: (row: User) => roleTranslations(row.role, t),
    },
  ];

  useEffect(() => {
    if (!values.users || !users) return;
    setIsOneSelected(values.users.length !== 0 && users.length !== 0);
    setIsAllSelected(
      showSelected
        ? true
        : !users.some((user) => !values.users?.find((x) => user.id === x.id)),
    );
  }, [values.users, users, showSelected]);

  if (additionalColumns && additionalColumns.length > 0) {
    columns = [...columns, ...additionalColumns];
  }

  const toolbar = (
    <DataTableToolbar>
      <FlexContainer
        justify="space-between"
        gap={8}
        align="flex-start"
        wrap="wrap"
      >
        <DataTableFilters
          filters={getFilters(t)}
          onFilter={onFilter}
          defaultValues={defaultTableFilters}
        />
        <FlexContainer width="auto" gap={8}>
          {toolbarAdditional}
        </FlexContainer>
      </FlexContainer>
    </DataTableToolbar>
  );

  return (
    <DataTable
      classNames="simple-datatable"
      data={showSelected ? values.users : users}
      count={showSelected ? values.users?.length : users?.length}
      isLoading={isLoading}
      toolbar={toolbar}
      columns={columns}
      onSort={onSort}
      sort={sort}
    />
  );
};
