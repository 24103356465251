import { GroupCondition } from '@/api/enums';
import { equal, group, like, nested } from '@/api/helpers';
import { FiltersType } from '@/api/types';
import { MaterialType } from '@/client/courses';
import { FilterNamesEnum, getFiltersFromColumns } from '@/client/helpers';
import { UserAwareness } from '@/client/reports';
import { Actions, Subjects } from '@/client/users';
import { TableNamesEnum } from '@/common/constants';
import {
  DataTable,
  DataTableColumnsMultiselect,
  DataTableColumnType,
  DataTableFilters,
  DataTableToolbar,
  FilterTypeEnum,
} from '@/components/tables/crud';
import {
  useBranchesPartialRequest,
  useGroups,
  useUsersAwarenessReport,
} from '@/hooks/query';
import { useAppSelector } from '@/hooks/store';
import { useTable } from '@/hooks/table.hook';
import { usePermission } from '@/hooks/usePermission';
import { selectCurrentAccount } from '@/store/features/account';
import { selectCurrentUser } from '@/store/features/users';
import { DateFormats } from '@/system-settings/enums';
import { FormatDate } from '@/ui/date';
import { FlexContainer } from '@/ui/styled-ui';
import {
  branchAdminCheck,
  getAwarenessReportStatusOptions,
} from '@/utils/helpers';
import { getBranchName } from '@/utils/helpers/branches.helper';
import {
  determineAttemptStatusColor,
  determineScoreColor,
} from '@/utils/helpers/ui.helper';
import { debounce, uniqueId } from 'lodash';
import { InputText } from 'primereact/inputtext';
import { Tooltip } from 'primereact/tooltip';
import React, { FormEvent, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

type AwarenessReportDatatableProps = {
  setExportFilters: (filters?: FiltersType) => void;
  setExportSorting: React.Dispatch<
    React.SetStateAction<[string, 'asc' | 'desc'] | null | undefined>
  >;
};

export const AwarenessReportDatatable: React.FC<
  AwarenessReportDatatableProps
> = ({ setExportFilters, setExportSorting }) => {
  const account = useAppSelector(selectCurrentAccount);
  const currentUser = useAppSelector(selectCurrentUser);
  const { can } = usePermission();
  const { t } = useTranslation();
  const isBranchAdmin = branchAdminCheck(currentUser, account);

  const { skip, take, sort, apiFilters, onFilter, onSort, onPage, setSkip } =
    useTable();

  const [multiSearchValue, setMultiSearchValue] = useState('');
  const debouncedSetMultiSearchValue = useMemo(
    () =>
      debounce((event: FormEvent) => {
        setSkip(0);
        setMultiSearchValue((event.target as HTMLInputElement).value);
      }, 300),
    [],
  );

  const { branches, isLoading: isBranchesLoading } = useBranchesPartialRequest({
    accountId: account?.id,
    sort: ['name,asc'],
  });

  const { groups, isLoading: isGroupsLoading } = useGroups({
    accountId: account?.id,
    sort: ['name,asc'],
    enabled: !isBranchAdmin,
  });

  const columns: DataTableColumnType[] = [
    {
      field: 'name',
      header: t('user'),
      sortable: true,
      filterable: false,
      className: 'vertical-top',
      render: (row: UserAwareness) => (
        <FlexContainer direction="column" align="flex-start">
          <span>{row?.name}</span>
          <span className="secondary-text mt-1">{row?.email}</span>
        </FlexContainer>
      ),
    },
    {
      field: 'branch',
      header: t('branch'),
      sortable: false,
      filterable: true,
      filters: {
        placeholder: t('filter.branch'),
        type: FilterTypeEnum.MULTI_SELECT,
        options: branches?.map(({ id, name }) => ({
          label: name,
          value: id,
        })),
      },
      className: 'no-padding',
      render: (row: UserAwareness) => {
        const id = uniqueId('branch');
        return (
          <>
            {row?.branch.id ? (
              <>
                {row?.branch.parent.id && (
                  <Tooltip
                    position="mouse"
                    target={`.${id}`}
                    content={getBranchName(row.branch).reverse().join(' / ')}
                  />
                )}
                <div className={id} style={{ minWidth: 'max-content' }}>
                  {branches?.find((x) => x.id === row.branch?.id)?.name}
                </div>
              </>
            ) : (
              <div className="group-row">&#8212;</div>
            )}
          </>
        );
      },
    },
    ...(can(Actions.READ, Subjects.GROUPS) && !isBranchAdmin
      ? [
          {
            field: 'group',
            header: t('group'),
            sortable: false,
            filterable: true,
            filters: {
              placeholder: t('filter.group'),
              field: FilterNamesEnum.GROUPS,
              type: FilterTypeEnum.MULTI_SELECT,
              options: groups?.result?.map(({ id, name }) => ({
                label: name,
                value: id,
              })),
            },
            className: 'no-padding',
            render: (row: UserAwareness) => {
              return (
                <>
                  {row?.groups.length ? (
                    row.groups.map((group, index) => (
                      <div key={index} style={{ minWidth: 'max-content' }}>
                        {group}
                      </div>
                    ))
                  ) : (
                    <div className="group-row">&#8212;</div>
                  )}
                </>
              );
            },
          },
        ]
      : []),
    {
      field: 'companyName',
      header: t('generic.companyName'),
      sortable: false,
      filterable: true,
      filters: {
        type: FilterTypeEnum.TEXT,
        placeholder: t('filter.companyName'),
      },
      className: 'no-padding',
      render: (row: UserAwareness) => (
        <FlexContainer direction="column" align="flex-start">
          {row?.meta?.companyName ? (
            <span>{row?.meta?.companyName}</span>
          ) : (
            <div className="group-row">&#8212;</div>
          )}
        </FlexContainer>
      ),
    },
    {
      field: 'country',
      header: t('generic.country'),
      sortable: false,
      filterable: true,
      filters: {
        type: FilterTypeEnum.TEXT,
        placeholder: t('filter.country'),
      },
      className: 'no-padding',
      render: (row: UserAwareness) => (
        <FlexContainer direction="column" align="flex-start">
          {row?.meta?.country ? (
            <span>{row?.meta?.country}</span>
          ) : (
            <div className="group-row">&#8212;</div>
          )}
        </FlexContainer>
      ),
    },
    {
      field: 'officeLocation',
      header: t('generic.officeLocation'),
      sortable: false,
      filterable: true,
      filters: {
        type: FilterTypeEnum.TEXT,
        placeholder: t('filter.officeLocation'),
      },
      className: 'no-padding',
      render: (row: UserAwareness) => (
        <FlexContainer direction="column" align="flex-start">
          {row?.meta?.officeLocation ? (
            <span>{row?.meta?.officeLocation}</span>
          ) : (
            <div className="group-row">&#8212;</div>
          )}
        </FlexContainer>
      ),
    },
    {
      field: 'department',
      header: t('generic.department'),
      sortable: false,
      filterable: true,
      filters: {
        type: FilterTypeEnum.TEXT,
        placeholder: t('filter.department'),
      },
      className: 'no-padding',
      render: (row: UserAwareness) => (
        <FlexContainer direction="column" align="flex-start">
          {row?.meta?.department ? (
            <span>{row?.meta?.department}</span>
          ) : (
            <div className="group-row">&#8212;</div>
          )}
        </FlexContainer>
      ),
    },
    {
      field: 'manager',
      header: t('generic.manager'),
      sortable: false,
      filterable: true,
      filters: {
        type: FilterTypeEnum.TEXT,
        placeholder: t('filter.manager'),
      },
      className: 'no-padding',
      render: (row: UserAwareness) => (
        <FlexContainer direction="column" align="flex-start">
          {row?.meta?.manager ? (
            <span>{row?.meta?.manager}</span>
          ) : (
            <div className="group-row">&#8212;</div>
          )}
        </FlexContainer>
      ),
    },
    {
      field: 'jobTitle',
      header: t('generic.jobTitle'),
      sortable: false,
      filterable: true,
      filters: {
        type: FilterTypeEnum.TEXT,
        placeholder: t('filter.jobTitle'),
      },
      className: 'no-padding',
      render: (row: UserAwareness) => (
        <FlexContainer direction="column" align="flex-start">
          {row?.meta?.jobTitle ? (
            <span>{row?.meta?.jobTitle}</span>
          ) : (
            <div className="group-row">&#8212;</div>
          )}
        </FlexContainer>
      ),
    },
    {
      field: 'mobilePhone',
      header: t('generic.mobilePhone'),
      sortable: false,
      filterable: true,
      filters: {
        type: FilterTypeEnum.TEXT,
        placeholder: t('filter.mobilePhone'),
      },
      className: 'no-padding',
      render: (row: UserAwareness) => (
        <FlexContainer direction="column" align="flex-start">
          {row?.meta?.mobilePhone ? (
            <span>{row?.meta?.mobilePhone}</span>
          ) : (
            <div className="group-row">&#8212;</div>
          )}
        </FlexContainer>
      ),
    },
    {
      field: 'course.name',
      header: account?.meta?.enableMaterialsUpload
        ? t('reports.courses.or.materials.enrolled')
        : t('reports.courses.enrolled'),
      sortable: true,
      filterable: true,
      filters: {
        field: FilterNamesEnum.AWARENESS_REPORTS_COURSE_NAME,
        type: FilterTypeEnum.TEXT,
        placeholder: t('generic.search'),
      },
      className: 'no-padding',
      render: (row: UserAwareness) => row?.course?.name,
    },
    {
      field: 'course.enrollmentDate',
      header: t('generic.enrollDate'),
      sortable: true,
      filterable: false,
      className: 'no-padding',
      render: (row: UserAwareness) => (
        <>
          {row?.course?.enrollmentDate ? (
            <FormatDate
              format={DateFormats.TIMEDATE}
              date={row.course.enrollmentDate}
            />
          ) : (
            <span>&#8212;</span>
          )}
        </>
      ),
    },
    {
      field: 'course.completed',
      header: t('reports.courses.completionDate'),
      sortable: true,
      filterable: false,
      className: 'no-padding',
      render: (row: UserAwareness) => (
        <>
          {row?.course?.completed ? (
            <FormatDate date={row.course.completed} />
          ) : (
            <span>&#8212;</span>
          )}
        </>
      ),
    },
    // TODO: Uncomment when we find a way to capture the time spent in SCORM files
    /*{
      field: 'timeSpent',
      header: t('reports.timeSpent'),
      sortable: false,
      filterable: false,
      className: 'no-padding',
      render: (row: UserAwareness) => (
        <>
          {row.course?.timeSpent
            ? convertMinutesIntoHours(row.course?.timeSpent, t)
            : `0 ${t('generic.minutes')}`}
        </>
      ),
    },
    */
    {
      field: 'status',
      header: t('generic.status'),
      sortable: false,
      filterable: true,
      filters: {
        field: FilterNamesEnum.AWARENESS_REPORTS_STATUS,
        type: FilterTypeEnum.MULTI_SELECT,
        options: getAwarenessReportStatusOptions(t),
        placeholder: t('generic.status.search'),
      },
      className: 'no-padding',
      render: (row: UserAwareness) => (
        <>
          {row?.course?.status
            ? determineAttemptStatusColor(row.course.status, t)
            : determineAttemptStatusColor('enrolled', t)}
        </>
      ),
    },
    {
      field: 'course.score',
      header: t('reports.score'),
      sortable: true,
      filterable: false,
      className: 'no-padding',
      render: (row: UserAwareness) => (
        <>
          {row?.course?.type !== MaterialType.COURSE ? (
            <span>&#8212;</span>
          ) : row?.course?.score ? (
            determineScoreColor(row.course?.status, row.course?.score)
          ) : (
            determineScoreColor('enrolled', 0)
          )}
        </>
      ),
    },
  ];

  // Set the preselected columns
  const [visibleColumns, setVisibleColumns] = useState<string[]>([]);
  const defaultVisibleColumns = columns
    .filter(
      (column) =>
        ![
          'companyName',
          'country',
          'officeLocation',
          'department',
          'manager',
          'jobTitle',
          'mobilePhone',
        ].includes(column.field),
    )
    .map((column) => column.field);
  const alwaysVisibleColumns = ['user'];
  //

  const toolbar = (
    <DataTableToolbar>
      <FlexContainer
        justify="space-between"
        gap={8}
        align="flex-start"
        wrap="wrap"
      >
        <DataTableFilters
          filters={getFiltersFromColumns(columns)}
          onFilter={onFilter}
          className="flex-initial"
          tableName={TableNamesEnum.AWARENESS_REPORTS}
        />
        <div className="p-input-icon-left flex-auto min-w-300">
          <InputText
            className="w-full"
            onInput={debouncedSetMultiSearchValue}
            placeholder={t('reports.awareness.search')}
            autoComplete="off"
          />
          <i className="pi pi-search" />
        </div>
      </FlexContainer>

      <DataTableColumnsMultiselect
        columns={columns}
        tableName={TableNamesEnum.AWARENESS_REPORTS}
        visibleColumns={visibleColumns}
        setVisibleColumns={setVisibleColumns}
        defaultVisibleColumns={defaultVisibleColumns}
        alwaysVisibleColumns={alwaysVisibleColumns}
      />
    </DataTableToolbar>
  );
  const multiSearchFilters = multiSearchValue.length
    ? [
        group(GroupCondition.OR, [
          like('email', multiSearchValue),
          like('name', multiSearchValue),
        ]),
      ]
    : [];

  const onSuccess = () => {
    setExportFilters([...apiFilters, ...multiSearchFilters]);
    setExportSorting(sort);
  };

  const { isLoading, usersAwarenessReport } = useUsersAwarenessReport(
    account?.id || '',
    {
      take,
      skip,
      filters: [
        ...apiFilters,
        ...multiSearchFilters,
        ...(account?.meta?.enableMaterialsUpload
          ? [
              group(GroupCondition.OR, [
                nested('course', [equal('type', MaterialType.COURSE)]),
                nested('course', [equal('type', MaterialType.CUSTOM_MATERIAL)]),
              ]),
            ]
          : [nested('course', [equal('type', MaterialType.COURSE)])]),
      ],
      sort: sort && sort?.length > 0 ? [sort.join(',')] : [],
    },
    onSuccess,
  );

  return (
    <DataTable
      dataKey="dataId"
      rowGroupMode="rowspan"
      groupRowsBy="name"
      data={usersAwarenessReport?.result.map((x, i) => ({
        ...x,
        dataId: `${x.id}-${x.course?.id}-${i}`,
      }))}
      count={usersAwarenessReport?.count as number}
      isLoading={isLoading || isBranchesLoading || isGroupsLoading}
      toolbar={toolbar}
      columns={columns}
      visibleColumns={visibleColumns}
      onPage={onPage}
      rows={take}
      skip={skip}
      onSort={onSort}
      sort={sort}
      classNames="rowspan-datatable"
    />
  );
};
