import { equal, handleAxiosError, like, nested } from '@/api/helpers';
import { Account } from '@/client/accounts';
import {
  ChangeEnrollDateFormValues,
  CourseBranch,
  CourseEnrollmentTypeEnum,
  CourseEntityEnrollEnum,
  CourseEntityScheduleEnum,
  CourseScheduleType,
  EnrollFormValues,
  MaterialType,
} from '@/client/courses';
import { Group } from '@/client/groups';
import { FilterNamesEnum, getFiltersFromColumns } from '@/client/helpers';
import { TableNamesEnum } from '@/common/constants';
import { branchStatusOptions } from '@/common/constants/enroll-types';
import { DialogContext } from '@/common/context';
import { ChangeEnrollDateModal } from '@/components/courses/modals/ChangeEnrollDateModal';
import { EnrollModal } from '@/components/courses/modals/EnrollModal';
import {
  DataTable,
  DataTableActions,
  DataTableColumnsMultiselect,
  DataTableColumnType,
  DataTableFilters,
  DataTableToolbar,
  FilterTypeEnum,
} from '@/components/tables/crud';
import {
  useChangeScheduleDate,
  useChangeScheduleEnrollType,
  useCourse,
  useCourseGroups,
  useDeleteEnrollment,
  useGroupsSchedules,
} from '@/hooks/query';
import { useAppSelector } from '@/hooks/store';
import { useTable } from '@/hooks/table.hook';
import { useMixpanel } from '@/hooks/useMixpanel';
import { pendoEvent, usePendo } from '@/hooks/usePendo';
import { useToast } from '@/hooks/useToast';
import { selectCurrentAccount } from '@/store/features/account';
import { selectCurrentUserState } from '@/store/features/users';
import { DateFormats } from '@/system-settings/enums';
import { AppChip } from '@/ui/chip';
import { FormatDate } from '@/ui/date';
import { FlexContainer } from '@/ui/styled-ui';
import { calculateDayDifferenceFromToday } from '@/utils/helpers';
import { determineBranchStatusColor } from '@/utils/helpers/ui.helper';
import {
  HubspotProperty,
  hubspotTrack,
  HubspotValue,
} from '@/utils/hubspot/hubspot.helper';
import { AxiosError } from 'axios';
import { debounce } from 'lodash';
import moment from 'moment';
import { InputText } from 'primereact/inputtext';
import { MenuItem } from 'primereact/menuitem';
import { ProgressSpinner } from 'primereact/progressspinner';
import React, { FormEvent, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { CoursesActionHeader } from './CoursesActionHeader';
import { CoursesTabs } from './CoursesTabs';

export const CourseGroupsPage: React.FC<{ isMaterial?: boolean }> = ({
  isMaterial,
}) => {
  const { t } = useTranslation();
  const { id: courseId } = useParams();
  const { track } = useMixpanel();
  const { setDialogData } = useContext(DialogContext);
  const toast = useToast();
  const { user } = useAppSelector(selectCurrentUserState);
  const account = useAppSelector(selectCurrentAccount);
  const { pendoTrackEnrollment } = usePendo();

  const { course, isLoading: isCourseLoading } = useCourse({
    courseId: courseId,
  });

  const { skip, take, sort, apiFilters, onFilter, onSort, onPage, setSkip } =
    useTable();

  const [showChangeEnrollDateModal, setShowChangeEnrollDateModal] =
    useState(false);
  const [showEnrollModal, setShowEnrollModal] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState<CourseBranch | undefined>(
    undefined,
  );

  const [multiSearchValue, setMultiSearchValue] = useState('');
  const debouncedSetMultiSearchValue = useMemo(
    () =>
      debounce((event: FormEvent) => {
        setSkip(0);
        setMultiSearchValue((event.target as HTMLInputElement).value);
      }, 300),
    [],
  );

  const {
    isLoading: isCourseGroupsLoading,
    courseGroups,
    refetch,
  } = useCourseGroups(
    {
      take,
      skip,
      filters: [
        ...apiFilters,
        ...(multiSearchValue.length ? [like('name', multiSearchValue)] : []),
        nested('account', [equal('id', account?.id)]),
      ],
      sort: sort && sort.length > 0 ? [sort.join(',')] : [],
      enabled: true,
    },
    courseId,
    account?.id,
  );

  const handlePendoTrackEnrollment = (
    type: 'create' | 'update' | 'delete',
    group: CourseBranch,
    date?: Date | null,
    autoEnroll?: boolean,
  ) => {
    if (course && account && group) {
      pendoTrackEnrollment({
        eventType: pendoEvent.enrollGroup,
        type,
        courses: [course],
        accounts: [account],
        groups: [group],
        date,
        oldEnrollDate: type === 'update' ? selectedGroup?.schedule?.date : null,
        autoEnroll,
      });
    }
  };

  const changeScheduleDate = useChangeScheduleDate();
  const handleChangeScheduleDate = async (data: ChangeEnrollDateFormValues) => {
    if (selectedGroup?.schedule && data.date) {
      try {
        await changeScheduleDate.edit({
          scheduleId: selectedGroup?.schedule.id,
          scheduleType: CourseEntityScheduleEnum.GROUP,
          date: data.date,
          type: CourseScheduleType.STATIC,
        });

        if (user?.account.isTrackingEnabled && !isMaterial) {
          // Track Mixpanel course enrollment with type update
          track('Update course enrollment', {
            Entity: 'Group',
            'Course id': course?.id,
            'Course name': course?.name,
            'Old enroll date (UTC)': moment
              .utc(selectedGroup?.schedule?.date)
              .format(DateFormats.DATEONLY_FULL_MONTH),
            'New enroll date (UTC)': moment
              .utc(data?.date)
              .format(DateFormats.DATEONLY_FULL_MONTH),
            'Enrolled to': selectedGroup?.name,
            'Account name': account?.name,
            'Total number of days': calculateDayDifferenceFromToday(data?.date),
            'Current date (UTC)': moment
              .utc()
              .format(DateFormats.DATEONLY_FULL_MONTH),
            'Enroll type': selectedGroup?.schedule?.autoEnroll
              ? 'auto'
              : 'manual',
          });

          handlePendoTrackEnrollment(
            'update',
            selectedGroup,
            data.date,
            selectedGroup.schedule.autoEnroll,
          );
        }

        await refetch();
        setSelectedGroup(undefined);
        setShowChangeEnrollDateModal(false);
        toast?.success(
          t('toast.success'),
          t('courses.schedule.date.change.success'),
        );
      } catch (e) {
        handleAxiosError(e as Error | AxiosError, toast);
      }
    }
  };

  const changeScheduleEnrollType = useChangeScheduleEnrollType();
  const handleChangeScheduleEnrollType = async (courseGroup: CourseBranch) => {
    if (courseGroup?.schedule?.date) {
      try {
        await changeScheduleEnrollType.edit({
          scheduleId: courseGroup.schedule.id,
          scheduleType: CourseEntityScheduleEnum.GROUP,
          autoEnroll: !courseGroup.schedule.autoEnroll,
          type: CourseScheduleType.STATIC,
        });
        await refetch();
        toast?.success(
          t('toast.success'),
          t('courses.enrollType.change.success'),
        );
      } catch (e) {
        handleAxiosError(e as Error | AxiosError, toast);
      }
    }
  };

  const groupsSchedules = useGroupsSchedules();
  const handleEnrollGroup = async (data: EnrollFormValues) => {
    if (course && selectedGroup) {
      try {
        await groupsSchedules.add({
          courses: [course.id],
          groups: [selectedGroup.id],
          date: data.date,
          autoEnroll: data.autoEnroll,
          type: data.type,
        });

        if (user?.account.isTrackingEnabled && !isMaterial) {
          handlePendoTrackEnrollment(
            'create',
            selectedGroup,
            data.date,
            data.autoEnroll,
          );
        }

        await refetch();
        toast?.success(t('toast.success'), t('courses.group.enroll.success'));

        if (user?.account?.freeTrialEndsAt) {
          hubspotTrack({
            [HubspotProperty.NEW_USER_ENROLLED_TO_A_COURSE]: HubspotValue.YES,
          });
        }
        setSelectedGroup(undefined);
        setShowEnrollModal(false);
      } catch (e) {
        handleAxiosError(e as Error | AxiosError, toast);
      }
    }
  };

  const deleteEnrollment = useDeleteEnrollment();
  const handleDeleteEnrollment = async (
    courseId: string,
    courseGroup: CourseBranch,
  ) => {
    try {
      await deleteEnrollment.post({
        courseId,
        request: { [CourseEntityEnrollEnum.GROUPS]: [courseGroup.id] },
      });

      if (user?.account.isTrackingEnabled && !isMaterial) {
        // Track Mixpanel course enrollment with type delete
        track('Delete course enrollment', {
          Entity: 'Group',
          'Course name': course?.name,
          'Course id': course?.id,
          'Enroll date (UTC)': moment
            .utc(courseGroup?.schedule?.date)
            .format(DateFormats.DATEONLY_FULL_MONTH),
          'Enrolled to': courseGroup?.name,
          'Account name': account?.name,
          'Current date (UTC)': moment
            .utc()
            .format(DateFormats.DATEONLY_FULL_MONTH),
          'Enroll type': courseGroup?.schedule?.autoEnroll ? 'auto' : 'manual',
        });

        handlePendoTrackEnrollment(
          'delete',
          courseGroup,
          courseGroup?.schedule?.date,
          courseGroup?.schedule?.autoEnroll,
        );
      }

      await refetch();
      toast?.success(t('toast.success'), t('courses.group.unenroll.success'));

      setSelectedGroup(undefined);
      setShowEnrollModal(false);
    } catch (e) {
      handleAxiosError(e as Error | AxiosError, toast);
    }
  };

  const menuItems = (courseGroup: CourseBranch) => {
    const menu: MenuItem[] = [
      {
        label: t('generic.enroll'),
        icon: 'pi pi-calendar-plus',
        command: () => {
          setSelectedGroup(courseGroup);
          setShowEnrollModal(true);
        },
      },
    ];

    if (courseGroup.schedule) {
      menu.push({
        label: courseGroup?.schedule?.autoEnroll
          ? t('courses.enrollType.change.manual')
          : t('courses.enrollType.change.auto'),
        icon: 'pi pi-sync',
        command: () =>
          setDialogData({
            type: 'confirmation',
            show: true,
            header: t('dialog.enrollType.change'),
            message: t('dialog.enrollType.warning'),
            onAccept: async () => {
              await handleChangeScheduleEnrollType(courseGroup);
            },
          }),
      });
    }

    if (
      courseGroup.schedule &&
      !courseGroup.schedule.executedAt &&
      !courseGroup.enrollment
    ) {
      menu.push({
        label: t('courses.change.enrollDate'),
        icon: 'pi pi-calendar',
        command: () => {
          setSelectedGroup(courseGroup);
          setShowChangeEnrollDateModal(true);
        },
      });
    }

    if (courseGroup?.enrollment) {
      menu.push({
        label: t('generic.unenroll'),
        icon: 'pi pi-times',
        command: () => {
          setDialogData({
            type: 'confirmation',
            show: true,
            header: t('dialog.deleteEnrollment'),
            message: t('dialog.deleteEnrollment.group', {
              name: courseGroup?.name,
            }),
            onAccept: async () => {
              if (!courseGroup?.enrollment || !courseId) return;
              setSelectedGroup(courseGroup);
              await handleDeleteEnrollment(courseId, courseGroup);
            },
          });
        },
      });
    }

    return menu;
  };

  const columns: DataTableColumnType[] = [
    {
      field: 'name',
      header: t('group'),
      sortable: true,
      filterable: false,
      style: {
        minWidth: '200px',
      },
      render: (row: CourseBranch) => <div>{row?.name}</div>,
    },
    {
      field: 'enrollDate',
      header: t('generic.enrollDate'),
      sortable: true,
      filterable: true,
      filters: {
        label: t('filter.byDate'),
        field: FilterNamesEnum.COURSE_ENTITY_UPCOMING_DATE,
        type: FilterTypeEnum.SELECT_UPCOMING_PAST,
        placeholder: t('filter.date'),
      },
      render: (row: CourseBranch) => (
        <>
          {row?.enrollDate ? (
            <FormatDate format={DateFormats.TIMEDATE} date={row?.enrollDate} />
          ) : (
            <div>&#8212;</div>
          )}
        </>
      ),
    },
    {
      field: 'inheritedSchedule',
      header: t('courses.inheritedFrom'),
      sortable: false,
      filterable: false,
      render: (row: CourseBranch) => (
        <>
          {row?.inheritedSchedule ? (
            <>
              {row?.schedule ? (
                <span>&#8212;</span>
              ) : row?.inheritedSchedule?.source ===
                CourseEntityScheduleEnum.GROUP ? (
                <span>{t('branch')}</span>
              ) : (
                <span>{t('account')}</span>
              )}
            </>
          ) : (
            <div>&#8212;</div>
          )}
        </>
      ),
    },
    {
      field: 'users',
      header: t('users'),
      sortable: false,
      filterable: false,
      render: (row: CourseBranch) => <div>{row.usersCount || 0}</div>,
    },
    {
      field: 'schedule.autoEnroll',
      header: t('courses.newUsersEnroll'),
      sortable: true,
      filterable: true,
      filters: {
        field: FilterNamesEnum.COURSE_ENTITY_AUTO_ENROLL,
        type: FilterTypeEnum.SELECT_AUTO_MANUAL,
        placeholder: t('filter.enrollType'),
      },
      render: (row: CourseBranch) => {
        const enrollType = row?.schedule?.autoEnroll ? (
          <AppChip label={t('generic.auto')} type="primary" />
        ) : (
          <AppChip label={t('generic.manual')} type="secondary" />
        );

        return (
          <>
            {row?.schedule?.date ? <div>{enrollType}</div> : <div>&#8212;</div>}
          </>
        );
      },
    },
    {
      field: 'status',
      header: t('generic.status'),
      sortable: true,
      filterable: true,
      filters: {
        label: t('filter.byStatus'),
        field: FilterNamesEnum.ENTITY_BY_STATUS,
        type: FilterTypeEnum.MULTI_SELECT,
        options: branchStatusOptions(t),
      },
      render: (row: CourseBranch) => (
        <div>{determineBranchStatusColor(row?.status, t)}</div>
      ),
    },
    {
      field: 'actions',
      header: t('generic.actions'),
      sortable: false,
      filterable: false,
      style: {
        width: '80px',
        textAlign: 'center',
      },
      render: (row: CourseBranch) => (
        <DataTableActions
          menuItems={menuItems(row)}
          disabled={menuItems(row).length < 1}
        />
      ),
    },
  ];

  // Set the preselected columns
  const [visibleColumns, setVisibleColumns] = useState<string[]>([]);
  const defaultVisibleColumns = columns.map((column) => column.field);
  const alwaysVisibleColumns = ['name', 'actions'];
  //

  const toolbar = (
    <DataTableToolbar justify="space-between">
      <DataTableFilters
        filters={getFiltersFromColumns(columns)}
        onFilter={onFilter}
        className="flex-initial"
        tableName={
          isMaterial
            ? TableNamesEnum.MATERIAL_GROUPS
            : TableNamesEnum.COURSE_GROUPS
        }
      />
      <div className="p-input-icon-left flex-auto min-w-300">
        <InputText
          className="w-full"
          onInput={debouncedSetMultiSearchValue}
          placeholder={t('courses.groups.search')}
          autoComplete="off"
        />
        <i className="pi pi-search" />
      </div>
      <DataTableColumnsMultiselect
        columns={columns}
        tableName={
          isMaterial
            ? TableNamesEnum.MATERIAL_GROUPS
            : TableNamesEnum.COURSE_GROUPS
        }
        visibleColumns={visibleColumns}
        setVisibleColumns={setVisibleColumns}
        defaultVisibleColumns={defaultVisibleColumns}
        alwaysVisibleColumns={alwaysVisibleColumns}
      />
    </DataTableToolbar>
  );

  return (
    <>
      {isCourseLoading && !course && (
        <FlexContainer direction="column" className="mt-5">
          <ProgressSpinner />
          <h3>
            {isMaterial
              ? t('material.groups.loading')
              : t('course.groups.loading')}
          </h3>
        </FlexContainer>
      )}
      {!isCourseLoading && course && (
        <>
          <CoursesActionHeader course={course} onTriggerRefetch={refetch} />
          {courseId && (
            <CoursesTabs
              courseId={courseId}
              isMaterial={
                course.type === MaterialType.RESOURCE ||
                course.type === MaterialType.CUSTOM_MATERIAL
              }
            />
          )}
          <DataTable
            dataKey="dataId"
            data={courseGroups?.result.map((x) => ({
              ...x,
              dataId: `${x.id}-${x?.schedule?.id}-${x?.inheritedSchedule?.date}`,
            }))}
            count={courseGroups?.count as number}
            isLoading={isCourseGroupsLoading}
            toolbar={toolbar}
            columns={columns}
            visibleColumns={visibleColumns}
            onPage={onPage}
            rows={take}
            skip={skip}
            onSort={onSort}
            sort={sort}
          />

          <EnrollModal
            type={CourseEnrollmentTypeEnum.ENROLL}
            account={account as Account}
            group={selectedGroup as unknown as Group}
            enrollType={CourseEntityScheduleEnum.GROUP}
            visible={showEnrollModal && !!selectedGroup}
            onSubmit={handleEnrollGroup}
            onHide={() => setShowEnrollModal(false)}
            course={course}
          />

          <ChangeEnrollDateModal
            date={selectedGroup?.schedule?.date ?? undefined}
            visible={showChangeEnrollDateModal && !!selectedGroup}
            onSubmit={handleChangeScheduleDate}
            onHide={() => setSelectedGroup(undefined)}
          />
        </>
      )}
    </>
  );
};
