import { handleAxiosError } from '@/api/helpers';
import { AppBreadCrumbTemplate } from '@/app/AppBreadCrumbTemplate';
import { Account } from '@/client/accounts';
import { File } from '@/client/files';
import { SystemResellerFormState } from '@/client/resellers';
import { SystemRoles } from '@/client/users';
import { RedirectPaths, RedirectPathsEnum } from '@/common/constants';
import { DialogContext } from '@/common/context';
import { TranslationFunctionType } from '@/common/types';
import { SystemResellerForm } from '@/components/resellers';
import { useSystemReseller, useUpdateSystemReseller } from '@/hooks/query';
import { useAppSelector } from '@/hooks/store';
import { useToast } from '@/hooks/useToast';
import { selectCurrentAccount } from '@/store/features/account';
import { selectCurrentUser } from '@/store/features/users';
import { AppBreadCrumb } from '@/ui/breadcrumb';
import { queryStateConverter } from '@/utils/helpers';
import { AxiosError } from 'axios';
import { FormikValues } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { LoadingPage } from '../LoadingPage';
import { ResellerTabs } from './ResellerTabs';

const getpathItems = (currentAccount: Account, t: TranslationFunctionType) => [
  {
    label: currentAccount?.name,
    url: RedirectPaths[RedirectPathsEnum.EDIT_ACCOUNT](
      (currentAccount as Account)?.id,
    ),
    template: AppBreadCrumbTemplate,
  },
  {
    label: t('resellers'),
    url: RedirectPaths[RedirectPathsEnum.RESELLERS](),
    template: AppBreadCrumbTemplate,
  },
];

export const SystemResellerPage = () => {
  const { t } = useTranslation();
  const toast = useToast();
  const navigate = useNavigate();
  const currentAccount = useAppSelector(selectCurrentAccount);
  const currentUser = useAppSelector(selectCurrentUser);
  const { setDialogData } = useContext(DialogContext);

  const { systemReseller, isLoading } = useSystemReseller({});

  const [initialValues, setInitialValues] = useState<SystemResellerFormState>({
    name: '',
    email: '',
    phone: '',
    baseUrl: '',
    theme: undefined,
    logo: undefined,
    emailLogo: undefined,
    favIcon: undefined,
  });

  const [preselectedLogo, setPreselectedLogo] = useState<File>({} as File);
  const [preselectedEmailLogo, setPreselectedEmailLogo] = useState<File>(
    {} as File,
  );
  const [preselectedFavIcon, setPreselectedFavIcon] = useState<File>(
    {} as File,
  );

  useEffect(() => {
    if (!isLoading && systemReseller) {
      setInitialValues({
        name: systemReseller?.name,
        email: systemReseller?.email,
        phone: systemReseller?.phone,
        baseUrl: systemReseller?.baseUrl,
        theme: systemReseller?.theme ? systemReseller.theme : undefined,
        logo: systemReseller?.logo ? systemReseller.logo.id : undefined,
        emailLogo: systemReseller?.emailLogo
          ? systemReseller.emailLogo.id
          : undefined,
        favIcon: systemReseller?.favIcon
          ? systemReseller.favIcon.id
          : undefined,
      });

      if (systemReseller?.logo) {
        setPreselectedLogo(systemReseller.logo);
      }

      if (systemReseller?.emailLogo) {
        setPreselectedEmailLogo(systemReseller.emailLogo);
      }

      if (systemReseller?.favIcon) {
        setPreselectedFavIcon(systemReseller.favIcon);
      }
    }
  }, [systemReseller]);

  const updateSystemReseller = useUpdateSystemReseller();

  const handleSubmit = (data: FormikValues) => {
    if (!data) return;

    setDialogData({
      show: true,
      type: 'confirmation',
      header: t('dialog.updateSystemReseller'),
      message: t('dialog.updateSystemReseller.confirm'),
      onAccept: async () => {
        try {
          await updateSystemReseller.update({
            updates: {
              name: data.name,
              email: data.email,
              phone: data.phone,
              baseUrl: data.baseUrl,
              theme: data?.theme ? data.theme : null,
              logo: data?.logo ? data.logo : null,
              emailLogo: data?.emailLogo ? data.emailLogo : null,
              favIcon: data?.favIcon ? data.favIcon : null,
            },
          });

          toast?.success(t('toast.success'), t('reseller.updated'));
          navigate(RedirectPaths[RedirectPathsEnum.RESELLERS]());
        } catch (e) {
          handleAxiosError(e as Error | AxiosError, toast);
        }
      },
    });
  };

  if (isLoading || !systemReseller) {
    return <LoadingPage message={t('generic.loading')} />;
  }

  return (
    <>
      <AppBreadCrumb model={getpathItems(currentAccount as Account, t)} />
      <ResellerTabs />
      <h1>{t('resellers.platformSettings')}</h1>
      <SystemResellerForm
        initialValues={initialValues}
        onSubmit={handleSubmit}
        preselectedLogo={preselectedLogo}
        preselectedEmailLogo={preselectedEmailLogo}
        preselectedFavIcon={preselectedFavIcon}
        state={queryStateConverter(updateSystemReseller)}
        disabled={
          !currentAccount?.isSystem ||
          (currentAccount.isSystem &&
            currentUser?.role.code !== SystemRoles.DEVELOPER)
        }
      />
    </>
  );
};
