import { Course } from '@/client/courses';
import {
  CourseSchedulePeriodUnit,
  PlannerCRUDCourse,
} from '@/client/planner/types';
import { useAppSelector } from '@/hooks/store';
import PagePlaceholderImage from '@/images/placeholders/page-template.svg';
import { selectCurrentAccount } from '@/store/features/account';
import { DateFormats } from '@/system-settings/enums';
import { AppButton } from '@/ui/buttons';
import { FlexContainer } from '@/ui/styled-ui';
import { t } from 'i18next';
import { escapeRegExp } from 'lodash';
import moment from 'moment';
import { Dropdown } from 'primereact/dropdown';
import React, { useRef, useState } from 'react';
import styled from 'styled-components';

const StyledAppButton = styled(AppButton)`
  &.p-button.p-button-icon-only {
    margin: auto 16px;
    border: 0 !important;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.15);
  }

  &.p-button.p-button-secondary:enabled:hover {
    border: 0 !important;
  }
`;

const StyledDropdown = styled(Dropdown)`
  &.p-dropdown {
    visibility: hidden;
    width: 0px;
    height: 0px;
    top: -75px;
    left: 25px;
    position: relative;
  }
`;

const StyledFlexContainer = styled(FlexContainer)`
  .course-thumbnail {
    border-radius: var(--xxxsmall-border-radius);
    width: 56px;
    height: 39px;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-color: var(--gray-pale);
  }
`;

const StyledSpan = styled.span`
  margin-top: var(--small-padding);
  color: var(--black-main);
`;

const StyledDateText = styled.div`
  color: var(--gray-darker);
  margin-left: auto;
`;

type PlannerCourseAddButtonProps = {
  courses?: Course[];
  timelineItems?: PlannerCRUDCourse[];
  currentTimelineItem?: PlannerCRUDCourse;
  onItemAdd: (item: PlannerCRUDCourse) => void;
  isOnboarding?: boolean;
  isCoursesLoading?: boolean;
};

export const PlannerCourseAddButton: React.FC<PlannerCourseAddButtonProps> = ({
  courses,
  timelineItems,
  currentTimelineItem,
  onItemAdd,
  isOnboarding,
  isCoursesLoading,
}) => {
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const currentAccount = useAppSelector(selectCurrentAccount);
  const dropdownRef = useRef<Dropdown>(null);

  const customCourses = courses?.filter(
    (course) =>
      !!course.account?.id &&
      (!course.releaseDate ||
        moment(course.releaseDate).isSameOrBefore(moment(), 'days')),
  );

  const upcomingCourses = !isOnboarding
    ? courses?.filter(
        (course) =>
          !!course.releaseDate &&
          moment(course.releaseDate).isAfter(moment(), 'days'),
      )
    : [];

  const options = [
    ...(customCourses?.length
      ? [
          {
            label: t('courses.for.accountName', {
              accountName: currentAccount?.name,
            }),
            items: customCourses,
          },
        ]
      : []),
    {
      label: t('courses.for.accountName', {
        accountName: currentAccount?.platformSettings?.name,
      }),
      items:
        courses?.filter(
          (course) =>
            !course.account?.id &&
            (!course.releaseDate ||
              moment(course.releaseDate).isSameOrBefore(moment(), 'days')),
        ) ?? [],
    },
    ...(upcomingCourses?.length
      ? [
          {
            label: t('courses.upcoming.platformName', {
              platformName: currentAccount?.platformSettings?.name,
            }),
            items: upcomingCourses,
          },
        ]
      : []),
  ];

  const toggleDropdown = () => {
    if (!dropdownVisible) {
      dropdownRef?.current?.show();
    } else {
      dropdownRef?.current?.hide();
    }
    setDropdownVisible(!dropdownVisible);
  };

  const optionItemTemplate = (course: Course) => {
    const isUpcomingCourse =
      course.releaseDate &&
      moment(course.releaseDate).isAfter(moment(), 'days');
    return (
      <StyledFlexContainer
        justify="flex-start"
        gap={8}
        className={isUpcomingCourse ? 'upcoming' : ''}
      >
        <div
          className="course-thumbnail"
          style={{
            backgroundImage: `url(${escapeRegExp(
              course?.courseThumbnail?.signedUrl ?? PagePlaceholderImage,
            )}`,
          }}
        />
        <div>{course.name}</div>
        {isUpcomingCourse && (
          <StyledDateText>
            {moment(course.releaseDate).format(DateFormats.DATEONLY_SHORT)}
          </StyledDateText>
        )}
      </StyledFlexContainer>
    );
  };

  return (
    <>
      <StyledAppButton
        icon={isCoursesLoading ? 'pi pi-spinner pi-spin' : ' pi pi-plus'}
        severity="secondary"
        type="outlined"
        isDisabled={isCoursesLoading}
        onClick={() => toggleDropdown()}
      />
      {!timelineItems?.length && (
        <StyledSpan>{t('planner.addCourses')}</StyledSpan>
      )}
      {courses && (
        <StyledDropdown
          panelClassName="without-border"
          ref={dropdownRef}
          filter
          filterBy="name"
          optionLabel="name"
          optionGroupLabel="label"
          optionGroupChildren="items"
          options={options}
          panelStyle={{ width: '380px' }}
          itemTemplate={optionItemTemplate}
          onChange={(e) => {
            onItemAdd({
              ...e.value,
              enrollDate:
                e.value.releaseDate &&
                moment(e.value.releaseDate).isAfter(moment(), 'day')
                  ? moment.utc(e.value.releaseDate).set({ h: 9 }).toDate()
                  : currentTimelineItem?.enrollDate || moment.utc(new Date()),
              periodValue: currentTimelineItem?.periodValue || 0,
              periodUnit:
                currentTimelineItem?.periodUnit ||
                CourseSchedulePeriodUnit.DAYS,
            });
          }}
          optionDisabled={(option) =>
            !!isOnboarding &&
            !!timelineItems?.some((item: any) => item?.id === option?.id)
          }
          onHide={() => setDropdownVisible(false)}
        />
      )}
    </>
  );
};
