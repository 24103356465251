import { processListingParams } from '@/api/helpers';
import { PostUserRequest } from '@/client/accounts';
import {
  Branch,
  BranchExportUsersRequest,
  DeleteBranchRequest,
  ListBranchesRequest,
  ListBranchesResponse,
  SaveBranchRequest,
  UpdateBranchRequest,
} from '@/client/branches';
import { getExceptionFromAxiosError } from '@/client/helpers';
import { MessageResponseModel } from '@/client/models';
import { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import { omit } from 'lodash';

export const branchesService = (client: AxiosInstance) => {
  const ACCOUNT_BRANCHES_BASE_URL = (accountId?: string) =>
    `/accounts/${accountId}/branches`;
  const ACCOUNT_USERS_BRANCHES_BASE_URL = (
    accountId: string,
    branchId: string,
  ) => `/accounts/${accountId}/branches/${branchId}/users`;

  const getBranches = async (
    params: ListBranchesRequest & { withDescendants?: boolean },
    accountId?: string,
  ): Promise<ListBranchesResponse> => {
    try {
      const result = await client.get<
        ListBranchesResponse,
        AxiosResponse<ListBranchesResponse>
      >(ACCOUNT_BRANCHES_BASE_URL(accountId), {
        withCredentials: true,
        params: {
          ...processListingParams(omit(params, 'withDescendants')),
          withDescendants: params.withDescendants ? 1 : 0,
        },
      });

      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const getBranch = async (
    branchId?: string,
    accountId?: string,
  ): Promise<Branch> => {
    try {
      const result = await client.get(
        `${ACCOUNT_BRANCHES_BASE_URL(accountId)}/${branchId}`,
        {
          withCredentials: true,
        },
      );

      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const saveBranch = async (request: SaveBranchRequest): Promise<Branch> => {
    try {
      const result = await client.post(
        ACCOUNT_BRANCHES_BASE_URL(request.account),
        request,
        {
          withCredentials: true,
        },
      );

      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const saveUsers = async (
    accountId: string,
    branchId: string,
    request: PostUserRequest,
  ): Promise<MessageResponseModel> => {
    try {
      const result = await client.post<
        MessageResponseModel,
        AxiosResponse<MessageResponseModel>
      >(ACCOUNT_USERS_BRANCHES_BASE_URL(accountId, branchId), request, {
        withCredentials: true,
      });

      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const updateBranch = async (
    request: UpdateBranchRequest,
  ): Promise<Branch> => {
    try {
      const result = await client.patch(
        `${ACCOUNT_BRANCHES_BASE_URL(request.accountId)}/${request.branchId}`,
        request.updates,
        {
          withCredentials: true,
        },
      );

      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const deleteBranch = async ({
    accountId,
    branchId,
  }: DeleteBranchRequest): Promise<MessageResponseModel> => {
    try {
      const result = await client.delete<
        MessageResponseModel,
        AxiosResponse<MessageResponseModel>
      >(`${ACCOUNT_BRANCHES_BASE_URL(accountId)}/${branchId}`, {
        withCredentials: true,
      });

      return new MessageResponseModel(result.data);
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const deleteUser = async (
    accountId: string,
    branchId: string,
    request: PostUserRequest,
  ): Promise<MessageResponseModel> => {
    try {
      const result = await client.delete<
        MessageResponseModel,
        AxiosResponse<MessageResponseModel>
      >(ACCOUNT_USERS_BRANCHES_BASE_URL(accountId, branchId), {
        data: request,
        withCredentials: true,
      });

      return result.data;
    } catch (e) {
      throw e as AxiosError;
    }
  };

  const importUsers = async (
    accountId: string,
    branchId: string,
    fileId: string,
    importKey: string,
  ): Promise<MessageResponseModel> => {
    try {
      const result = await client.post<
        MessageResponseModel,
        AxiosResponse<MessageResponseModel>
      >(
        `${ACCOUNT_USERS_BRANCHES_BASE_URL(accountId, branchId)}/import`,
        { fileId, importKey },
        {
          withCredentials: true,
        },
      );

      return result.data;
    } catch (e) {
      throw e as AxiosError;
    }
  };

  const exportUsers = async (
    request: BranchExportUsersRequest,
  ): Promise<MessageResponseModel> => {
    try {
      const result = await client.post<
        MessageResponseModel,
        AxiosResponse<MessageResponseModel>
      >(
        `${ACCOUNT_USERS_BRANCHES_BASE_URL(
          request.accountId,
          request.branchId,
        )}/export`,
        { ...request },
        {
          withCredentials: true,
        },
      );

      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const branchesNotifyUrl = (branchId: string, accountId: string) =>
    `${client.defaults.baseURL}accounts/${accountId}/branches/${branchId}/notify`;

  return {
    getBranches,
    getBranch,
    saveBranch,
    saveUsers,
    updateBranch,
    deleteBranch,
    deleteUser,
    importUsers,
    exportUsers,
    branchesNotifyUrl,
  };
};
