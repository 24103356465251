import React, {
  MouseEvent,
  ReactNode,
  useRef,
  useState,
  WheelEvent,
} from 'react';
import styled from 'styled-components';

const ScrollContainer = styled.div`
  width: 100%;
  overflow: hidden;
  position: relative;
  cursor: grab;
  user-select: none;
`;

const ScrollContent = styled.div`
  width: max-content;
  height: 100%;
`;

type SliderPropsType = {
  children: ReactNode;
};

export const Slider: React.FC<SliderPropsType> = ({ children }) => {
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const [isDown, setIsDown] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const scrollContainer = scrollContainerRef.current;

  const handleMouseDown = (e: MouseEvent<HTMLDivElement>) => {
    setIsDown(true);
    if (scrollContainer) {
      setStartX(e.pageX - scrollContainer.offsetLeft);
      setScrollLeft(scrollContainer.scrollLeft);
      scrollContainer.style.cursor = 'grabbing';
    }
  };

  const handleMouseEnter = (e: MouseEvent<HTMLDivElement>) => {
    const scrollContainer = scrollContainerRef.current;

    if (scrollContainer) {
      scrollContainer.focus();
    }
  };

  const handleMouseLeaveOrUp = () => {
    setIsDown(false);
    if (scrollContainer) {
      scrollContainer.style.cursor = 'grab';
    }
  };

  const handleMouseMove = (e: MouseEvent<HTMLDivElement>) => {
    if (!isDown) return;
    e.preventDefault();
    if (scrollContainer) {
      const x = e.pageX - scrollContainer.offsetLeft;
      const walkX = (x - startX) * 3; // Adjust for faster/slower scrolling
      scrollContainer.scrollLeft = scrollLeft - walkX;
    }
  };

  const handleWheel = (event: WheelEvent<HTMLDivElement>) => {
    event.stopPropagation();
    if (scrollContainer) {
      scrollContainer.scrollBy({
        left: event.deltaY < 0 ? -100 : 100,
        behavior: 'auto',
      });
    }
  };

  return (
    <ScrollContainer
      ref={scrollContainerRef}
      onMouseDown={handleMouseDown}
      onMouseLeave={handleMouseLeaveOrUp}
      onMouseEnter={handleMouseEnter}
      onMouseUp={handleMouseLeaveOrUp}
      onMouseMove={handleMouseMove}
      onWheel={handleWheel}
    >
      <ScrollContent>{children}</ScrollContent>
    </ScrollContainer>
  );
};
