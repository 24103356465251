import { GroupCondition } from '@/api/enums';
import { group, like } from '@/api/helpers';
import { FiltersType } from '@/api/types';
import { client } from '@/client';
import { User } from '@/client/users';
import { useAppSelector } from '@/hooks/store';
import { selectCurrentAccount } from '@/store/features/account';
import { FlexContainer } from '@/ui/styled-ui';
import { ITEMS_PER_REQUEST } from '@/utils/helpers';
import {
  AutoComplete,
  AutoCompleteCompleteEvent,
  AutoCompleteProps,
} from 'primereact/autocomplete';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const StyledItem = styled(FlexContainer)`
  p {
    color: var(--black-main);
    margin: 0;
    font-weight: 600;
  }
`;

type UsersAutocompleteInputProps = {
  onChange: (value: User | User[]) => void;
  defaultValue?: User[];
  additionalFilters?: FiltersType;
  selectedOptions?: User[];
  multiple?: boolean;
} & Omit<AutoCompleteProps, 'defaultValue' | 'onChange'>;

export const UsersAutocompleteInput: React.FC<UsersAutocompleteInputProps> = ({
  onChange,
  defaultValue,
  additionalFilters,
  selectedOptions,
  multiple,
  ...rest
}) => {
  const { t } = useTranslation();
  const account = useAppSelector(selectCurrentAccount);
  const ref = useRef<any>();
  const [searchResults, setSearchResults] = useState<User[]>([]);
  const [fieldValue, setFieldValue] = useState<User[]>([]);

  const onCompleteMethod = async (e: AutoCompleteCompleteEvent) => {
    const users = await client.users.getAccountUsers(
      {
        take: ITEMS_PER_REQUEST,
        filters: [
          group(GroupCondition.OR, [
            like('email', e.query),
            like('name', e.query),
          ]),
          ...(additionalFilters ?? []),
        ],
      },
      account?.id,
    );

    setSearchResults(
      users.result.map((a) => ({
        ...a,
        name: a.name,
      })),
    );
  };

  const itemTemplate = (item: User) => {
    return (
      <StyledItem direction="column" align="flex-start" gap={2}>
        <p>{item.email}</p>
        <span>
          {item.name} ({item.username})
        </span>
      </StyledItem>
    );
  };

  useEffect(() => {
    setFieldValue(selectedOptions ?? []);
  }, [selectedOptions]);

  useEffect(() => {
    setFieldValue(defaultValue ?? []);
  }, [JSON.stringify(defaultValue)]);

  return (
    <AutoComplete
      {...rest}
      ref={ref}
      multiple={multiple}
      value={fieldValue ? fieldValue : defaultValue}
      field="email"
      placeholder={!fieldValue.length ? t('user.search.name.email') : ''}
      suggestions={searchResults}
      completeMethod={onCompleteMethod}
      onSelect={undefined}
      itemTemplate={itemTemplate}
      onChange={(e) => {
        setFieldValue(e.value);
        onChange(e.value);
      }}
      autoCorrect="off"
    />
  );
};
