import { Campaign } from '@/client/campaigns';
import PagePlaceholderImage from '@/images/placeholders/page-template.svg';
import { Slider } from '@/ui/slider';
import { FlexContainer } from '@/ui/styled-ui';
import { Divider } from 'primereact/divider';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { CampaignInfo } from './CampaignInfo';
import { EmailTemplatePreview } from './EmailTemplatePreview';

const StyledContainer = styled(FlexContainer)`
  height: 100%;
  width: 100%;
  align-items: stretch;
  justify-content: flex-start;
`;

const StyledEmailPreview = styled(FlexContainer)`
  justify-content: flex-start;
  align-items: stretch;
  overflow-x: scroll;
  height: 100%;
  gap: var(--default-padding);

  .thumbnail-preview {
    background-color: var(--beige-main);
    padding: var(--default-padding);
    display: flex;
    flex-direction: column;
    flex: 1 1 30%;
    font-size: var(--xsmall-font-size);
    height: 100%;
    width: 100%;
    overflow: hidden;

    .p-divider.p-divider-horizontal {
      margin: 0;
      &:before {
        border-color: var(--gray-dark);
      }
    }

    .thumbnail-type {
      margin-top: var(--xxsmall-padding);
      margin-bottom: var(--default-padding);
      font-weight: 500;
      text-align: center;
    }

    .p-divider-content {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: var(--white-main);
      border-radius: 50%;
      border: 1px solid var(--gray-dark);
      width: 32px;
      height: 32px;
      font-weight: 700;
    }

    .img-container {
      display: flex;
      justify-content: center;
      max-width: 100%;
      background: var(--white-main);
      height: calc(100% - var(--small-padding) - 3 * var(--default-padding));
      min-width: 640px;
    }

    img {
      display: block;
      pointer-events: none;
      height: 100%;
      max-width: 100%;
    }
  }
  &::-webkit-scrollbar {
    display: none;
  }
`;

export type PreviewCampaignTemplatePropsType = {
  campaign?: Campaign;
};

export const PreviewCampaignTemplate: React.FC<
  PreviewCampaignTemplatePropsType
> = ({ campaign }) => {
  const { t } = useTranslation();
  const [imgLoading, setImgLoading] = useState(true);

  return (
    <>
      {campaign && (
        <StyledContainer>
          <CampaignInfo campaign={campaign} className="preview" />
          <Slider>
            <StyledEmailPreview>
              <div className="thumbnail-preview">
                <Divider align="center">1</Divider>
                <h4 className="thumbnail-type">{t('templates.email')}</h4>
                <EmailTemplatePreview campaign={campaign} />
              </div>
              <div className="thumbnail-preview">
                <Divider align="center">2</Divider>
                <h4 className="thumbnail-type">
                  {t('templates.pageTemplate')}
                </h4>
                <div className="img-container">
                  <img
                    src={
                      campaign.landingPage?.preview?.signedUrl ||
                      campaign.landingPage?.thumbnail?.signedUrl ||
                      PagePlaceholderImage
                    }
                    alt={campaign.landingPage?.thumbnail?.fileName}
                  />
                </div>
              </div>
              {campaign.landingPage?.redirectPage && (
                <div className="thumbnail-preview">
                  <Divider align="center">3</Divider>
                  <h4 className="thumbnail-type">
                    {t('templates.redirectTemplate')}
                  </h4>
                  <div className="img-container">
                    <img
                      src={
                        campaign.landingPage?.redirectPage.preview?.signedUrl ||
                        campaign.landingPage?.redirectPage?.thumbnail
                          ?.signedUrl ||
                        PagePlaceholderImage
                      }
                      alt={
                        campaign.landingPage?.redirectPage?.thumbnail?.fileName
                      }
                    />
                  </div>
                </div>
              )}
            </StyledEmailPreview>
          </Slider>
        </StyledContainer>
      )}
    </>
  );
};
