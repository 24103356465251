import { handleAxiosError } from '@/api/helpers';
import { AppBreadCrumbTemplate } from '@/app/AppBreadCrumbTemplate';
import { Account } from '@/client/accounts';
import { File } from '@/client/files';
import { Reseller, ResellerFormState } from '@/client/resellers';
import { Actions, Subjects, SystemRoles } from '@/client/users';
import { RedirectPaths, RedirectPathsEnum } from '@/common/constants';
import { TranslationFunctionType } from '@/common/types';
import { ResellerForm } from '@/components/resellers';
import { useReseller, useUpdateReseller } from '@/hooks/query';
import { useAppSelector } from '@/hooks/store';
import { usePermission } from '@/hooks/usePermission';
import { useToast } from '@/hooks/useToast';
import { selectCurrentAccount } from '@/store/features/account';
import { selectCurrentUser } from '@/store/features/users';
import { AppBreadCrumb } from '@/ui/breadcrumb';
import { FlexContainer } from '@/ui/styled-ui';
import { queryStateConverter } from '@/utils/helpers';
import { AxiosError } from 'axios';
import { FormikValues } from 'formik';
import { MenuItem } from 'primereact/menuitem';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { LoadingPage } from '../LoadingPage';

const getPathItems = (
  reseller: Reseller,
  currentAccount: Account,
  t: TranslationFunctionType,
): MenuItem[] => [
  {
    label: currentAccount?.name,
    url: RedirectPaths[RedirectPathsEnum.EDIT_ACCOUNT](currentAccount?.id),
    template: AppBreadCrumbTemplate,
  },
  {
    label: t('resellers'),
    url: RedirectPaths[RedirectPathsEnum.RESELLERS](),
    template: AppBreadCrumbTemplate,
  },
  {
    label: reseller?.name,
    url: RedirectPaths[RedirectPathsEnum.EDIT_RESELLERS](reseller?.id),
    template: AppBreadCrumbTemplate,
  },
];

export const UpdateResellerPage = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const toast = useToast();
  const navigate = useNavigate();
  const { cannot } = usePermission();

  const { reseller, isLoading } = useReseller({ resellerId: id });

  const currentAccount = useAppSelector(selectCurrentAccount);
  const currentUser = useAppSelector(selectCurrentUser);
  const updateReseller = useUpdateReseller();

  const [initialValues, setInitialValues] = useState<ResellerFormState>({
    name: '',
    email: '',
    phone: '',
    baseUrl: '',
    mainAccount: undefined,
    accounts: [],
    theme: undefined,
    logo: undefined,
    emailLogo: undefined,
    favIcon: undefined,
  });

  const [preselectedLogo, setPreselectedLogo] = useState<File>({} as File);
  const [preselectedEmailLogo, setPreselectedEmailLogo] = useState<File>(
    {} as File,
  );
  const [preselectedFavIcon, setPreselectedFavIcon] = useState<File>(
    {} as File,
  );

  useEffect(() => {
    if (isLoading || !reseller) return;
    setInitialValues({
      name: reseller?.name,
      email: reseller?.email,
      phone: reseller?.phone,
      baseUrl: reseller?.baseUrl,
      mainAccount: reseller?.mainAccount,
      accounts: reseller?.accounts,
      theme: reseller?.theme ? reseller.theme : undefined,
      logo: reseller?.logo ? reseller.logo.id : undefined,
      emailLogo: reseller?.emailLogo ? reseller.emailLogo.id : undefined,
      favIcon: reseller?.favIcon ? reseller.favIcon.id : undefined,
    });

    if (reseller?.logo) {
      setPreselectedLogo(reseller.logo);
    }

    if (reseller?.emailLogo) {
      setPreselectedEmailLogo(reseller.emailLogo);
    }

    if (reseller?.favIcon) {
      setPreselectedFavIcon(reseller.favIcon);
    }
  }, [reseller]);

  const handleSubmit = async (data: FormikValues) => {
    if (!reseller || !data) return;

    let selectedAccountsIds: string[] = [];
    if (data?.accounts?.length) {
      selectedAccountsIds = data.accounts.map((account: Account) => account.id);
    }

    try {
      await updateReseller.update({
        resellerId: reseller?.id,
        updates: {
          name: data.name,
          email: data.email,
          phone: data.phone,
          baseUrl: data.baseUrl,
          mainAccount: data.mainAccount.id,
          accounts: selectedAccountsIds,
          theme: data?.theme ? data.theme : null,
          logo: data?.logo ? data.logo : null,
          emailLogo: data?.emailLogo ? data.emailLogo : null,
          favIcon: data?.favIcon ? data.favIcon : null,
        },
      });

      toast?.success(t('toast.success'), t('reseller.updated'));
      navigate(RedirectPaths[RedirectPathsEnum.RESELLERS]());
    } catch (e) {
      handleAxiosError(e as Error | AxiosError, toast);
    }
  };

  if (isLoading || !reseller) {
    return <LoadingPage message={t('generic.loading')} />;
  }

  return (
    <>
      {currentAccount && (
        <AppBreadCrumb model={getPathItems(reseller, currentAccount, t)} />
      )}

      <FlexContainer justify="flex-start">
        <ResellerForm
          initialValues={initialValues}
          preselectedLogo={preselectedLogo}
          preselectedEmailLogo={preselectedEmailLogo}
          preselectedFavIcon={preselectedFavIcon}
          onSubmit={handleSubmit}
          state={queryStateConverter(updateReseller)}
          disabled={
            cannot(Actions.UPDATE, Subjects.RESELLERS) ||
            !currentAccount?.isSystem
          }
          locked={
            currentAccount?.isSystem &&
            currentUser?.role.code !== SystemRoles.DEVELOPER
          }
          resellerId={id}
        />
      </FlexContainer>
    </>
  );
};
