import {
  BaseEnrollFormValues,
  Course,
  CourseScheduleType,
} from '@/client/courses';
import { LoadingStatuses } from '@/common/constants';
import { coursePlannersEnrollSchema } from '@/components/courses/validations/enroll';
import { AppButton } from '@/ui/buttons';
import { AppCalendar } from '@/ui/calendar';
import { FlexContainer } from '@/ui/styled-ui';
import { Form, Formik } from 'formik';
import moment from 'moment';
import { Dialog, DialogProps } from 'primereact/dialog';
import { Message } from 'primereact/message';
import { FormEvent } from 'primereact/ts-helpers';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const StyledDialog = styled(Dialog)`
  width: 500px;
`;

type PlannersEnrollModalProps = {
  onSubmit: (data: BaseEnrollFormValues) => void;
  course: Course;
  isLoading: boolean;
} & DialogProps;

export const PlannersEnrollModal: React.FC<PlannersEnrollModalProps> = ({
  onSubmit,
  onHide,
  course,
  visible,
  isLoading,
}) => {
  const { t } = useTranslation();
  const [initialValues] = useState<BaseEnrollFormValues>({
    courses: [course],
    date: null,
    type: CourseScheduleType.STATIC,
  });

  return (
    <StyledDialog
      blockScroll
      visible={visible}
      header={<h1>{t('planner.trainingPlans')}</h1>}
      onHide={onHide}
      draggable={false}
      data-testid="enroll-planners-accounts-modal"
    >
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={coursePlannersEnrollSchema(t)}
        validateOnMount
      >
        {({ setFieldValue, values, errors }) => (
          <>
            <Form>
              <>
                <div className="field w-full mb-4">
                  <label>{t('generic.enrollDate')}</label>
                  <AppCalendar
                    id="enroll-date"
                    name="enrollDate"
                    className="w-full"
                    placeholder={t('courses.select.enrollDate')}
                    minDate={
                      moment(course.releaseDate).isAfter(moment(), 'days')
                        ? moment(course.releaseDate).toDate()
                        : new Date()
                    }
                    value={values.date}
                    onChange={(event: FormEvent) => {
                      setFieldValue(
                        'date',
                        moment(event.value?.toString()).utc(true).toDate(),
                      );
                    }}
                    readOnlyInput
                    icon="pi pi-calendar"
                  />
                </div>

                <Message severity="warn" text={t('planner.addCourses.warn')} />
              </>
              <FlexContainer justify="flex-end" className="mt-5">
                <AppButton
                  label={t('button.cancel')}
                  severity="secondary"
                  type="outlined"
                  onClick={onHide}
                  isDisabled={isLoading}
                  className="mr-3"
                  data-testid="enroll-planners-cancel-form"
                />
                <AppButton
                  label={t('generic.enroll')}
                  isDisabled={!!Object.keys(errors).length || isLoading}
                  state={
                    isLoading ? LoadingStatuses.LOADING : LoadingStatuses.IDLE
                  }
                  isSubmit
                  data-testid="enroll-planners-submit-form"
                />
              </FlexContainer>
            </Form>
          </>
        )}
      </Formik>
    </StyledDialog>
  );
};
