import { handleAxiosError } from '@/api/helpers';
import { client } from '@/client';
import { ScormCourseAttempt, ScormStatusAttemptEnum } from '@/client/scorm';
import { RedirectPaths, RedirectPathsEnum } from '@/common/constants';
import { useToast } from '@/hooks/useToast';
import PagePlaceholderImage from '@/images/placeholders/page-template.svg';
import { AppButton } from '@/ui/buttons';
import { FlexContainer } from '@/ui/styled-ui';
// import { getCourseAttemptProgress } from '@/utils/helpers';
import { AxiosError } from 'axios';
import classNames from 'classnames';
import { escapeRegExp } from 'lodash';
import { Card } from 'primereact/card';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
// import AppProgressBar from '../progressbar/AppProgressBar';

// const StyledStatusContainer = styled.span`
//   position: absolute;
//   padding: 0 var(--xsmall-padding);
//   border-radius: 2px;
//   color: var(--orange-main);
//   background-color: var(--white-main);
//   z-index: 1;
//   font-size: var(--small-font-size);
//   line-height: var(--small-line-height);
//   top: var(--small-padding);
//   left: var(--small-padding);
//   font-weight: 600;
//   text-transform: capitalize;
// `;

const StyledImageCard = styled(Card)`
  &.p-card {
    max-width: 400px;
    display: flex;
    flex-direction: column;
    border: 1px solid var(--gray-dark);
    border-radius: var(--medium-border-radius);
    box-shadow: none;
    overflow: hidden;
    cursor: pointer;

    &:hover,
    &:focus-within {
      .p-card-header .image-card-overlay {
        opacity: 1;
        cursor: pointer;
      }
    }

    &.disabled {
      opacity: 0.6;
      pointer-events: none !important;
    }

    .p-card-body {
      display: flex;
      flex-direction: column;
      flex: 1;
      padding: var(--small-padding);
      .p-card-title {
        margin-bottom: 0;
        font-size: var(--medium-font-size);
        line-height: var(--medium-line-height);
        color: var(--black-main);
      }
      .p-card-subtitle {
        margin-bottom: 0;
        font-size: var(--xsmall-font-size);
        line-height: var(--xsmall-line-height);
        color: var(--black-main);
      }
    }

    .p-card-header {
      height: 194px;
      position: relative;
      display: flex;
      align-items: stretch;

      .image-card-thumbnail {
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        background-color: var(--gray-main);
      }

      .image-card-overlay {
        position: absolute;
        height: 100%;
        padding: var(--small-padding);
        background: var(--orange-faint);
        opacity: 0;
        transition: all 0.4s;
      }
    }

    .p-card-footer {
      margin-top: auto;
    }

    label {
      cursor: pointer;
    }
  }
`;

const StyledAppButton = styled(AppButton)`
  &.p-button.p-button-icon-only {
    width: var(--small-button-height);
    height: var(--small-button-height);
    padding: 0 0 0 3px;
    margin-left: var(--small-padding);

    .p-button-icon {
      font-size: var(--small-font-size);
    }

    @media screen and (max-width: 812px) {
      width: calc(1.2 * var(--small-button-height));
      height: calc(1.2 * var(--small-button-height));
    }
  }
`;

export type ScormCardProps = {
  id: string;
  title: string;
  language?: string;
  attempts?: ScormCourseAttempt[];
  thumbnail?: string;
  isDisabled?: boolean;
  additionalClass?: string;
  onClick?: () => void;
};

export const ScormCard: React.FC<ScormCardProps> = ({
  id,
  title,
  thumbnail,
  attempts,
  isDisabled,
  additionalClass,
  onClick,
  ...rest
}) => {
  const { t } = useTranslation();
  const toast = useToast();
  const navigate = useNavigate();
  const location = useLocation();
  const navigateToCourseAttempt = async (e: Event) => {
    e.stopPropagation();

    try {
      const lastAttempt = await client.scormCourses.getScormCourseLastAttempt(
        id,
      );

      if (lastAttempt.status !== ScormStatusAttemptEnum.IN_PROGRESS) {
        createScormCourseNewAttempt();
        return;
      }

      const attemptId = lastAttempt?.id;
      navigate(RedirectPaths[RedirectPathsEnum.COURSE_PLAY](id, attemptId));
    } catch (e: any) {
      if (e?.statusCode === 404) {
        createScormCourseNewAttempt();
      } else {
        handleAxiosError(e as AxiosError, toast);
      }
    }
  };

  const createScormCourseNewAttempt = async () => {
    try {
      const newAttempt = await client.scormCourses.createScormCourseNewAttempt(
        id,
      );
      navigate(
        RedirectPaths[RedirectPathsEnum.COURSE_PLAY](id, newAttempt.id),
        { state: { from: location.pathname } },
      );
    } catch (e: any) {
      handleAxiosError(e as AxiosError, toast);
    }
  };

  const className = classNames(
    {
      disabled: isDisabled,
    },
    additionalClass,
  );

  const header = (
    <label
      className="image-card-thumbnail flex-1"
      style={{
        backgroundImage: `url(${escapeRegExp(
          thumbnail ?? PagePlaceholderImage,
        )}`,
      }}
    />
  );

  const isCompleted =
    !!attempts?.length &&
    attempts[0]?.status === ScormStatusAttemptEnum.COMPLETED;

  // const progress = attempts?.length ? getCourseAttemptProgress(attempts[0]) : 0;

  const titleField = (
    <FlexContainer justify="space-between">
      <div>{title}</div>
      <StyledAppButton
        icon="pi pi-caret-right"
        size="sm"
        ariaLabel={!attempts?.length ? t('course.start') : t('course.resume')}
        severity={isCompleted ? 'secondary' : undefined}
        onClick={navigateToCourseAttempt}
      />
    </FlexContainer>
  );

  // TODO: Uncomment if we have progress bar
  /*
  const footer = (
    <FlexContainer justify="flex-start">
      <AppProgressBar
        value={progress}
        isPurple={isCompleted}
        className="flex-1"
      />
      <StyledAppButton
        icon="pi pi-caret-right"
        size="sm"
        ariaLabel={!attempts?.length ? t('course.start') : t('course.resume')}
        severity={isCompleted ? 'secondary' : undefined}
        onClick={navigateToCourseAttempt}
      />
    </FlexContainer>
  );
  */

  return (
    <StyledImageCard
      id={id}
      title={titleField}
      className={className}
      header={header}
      // footer={footer}
      onClick={onClick}
      {...rest}
    />
  );
};
