import { FiltersType, ListingRequest } from '@/api/types';
import { client } from '@/client';
import { User } from '@/client/users';
import { PartialRequestMultiselect } from '@/components/form/generic/PartialRequestMultiselect';
import { useAppSelector } from '@/hooks/store';
import { selectCurrentAccount } from '@/store/features/account';
import React, { HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';

type UsersMultiselectInputProps = {
  onChange: (value: User[]) => void;
  onBlur?: () => void;
  additionalFilters?: FiltersType;
  selectedOptions?: User[];
  isDisabled?: boolean;
  courseId?: string;
  accountId?: string;
} & Omit<HTMLAttributes<HTMLSelectElement>, 'onChange'>;

export const UsersMultiselectInput: React.FC<UsersMultiselectInputProps> = ({
  onChange,
  onBlur,
  className,
  additionalFilters,
  selectedOptions,
  isDisabled,
  courseId,
  accountId,
}) => {
  const { t } = useTranslation();
  const account = useAppSelector(selectCurrentAccount);
  const itemTemplate = (option: User) => (
    <span>
      {option?.username} - {option?.firstName} {option?.lastName} (
      {option?.email})
    </span>
  );
  const fetchUsers = isDisabled
    ? undefined
    : async (params: ListingRequest) =>
        courseId
          ? await client.courses.getCourseUsers(
              params,
              courseId,
              accountId ?? account?.id,
            )
          : await client.users.getAccountUsers(params, account?.id);

  return (
    <PartialRequestMultiselect
      fetchService={fetchUsers}
      filter
      filterBy="username,name,email"
      display="chip"
      optionLabel="username"
      optionValue="value"
      placeholder={t('user.select')}
      filterPlaceholder={t('user.search.name.username')}
      maxSelectedLabels={10}
      itemTemplate={itemTemplate}
      additionalFilters={additionalFilters}
      selectedOptions={selectedOptions}
      customDataMap={(user: User) => ({
        ...user,
        name: `${user?.firstName} ${user?.lastName}`,
      })}
      className={className}
      disabled={isDisabled}
      handleOnChange={onChange}
      onBlur={onBlur}
      selectedItemTemplate={null}
    />
  );
};
