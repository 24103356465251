import { ListingRequest, ListingResponse } from '@/api/types';
import { Branch, BranchDescendant } from '@/client/branches';
import { Course } from '@/client/courses';
import { File } from '@/client/files';

export enum PlannerType {
  TRAINING_PLAN = 'training',
  ONBOARDING_PLAN = 'onboarding',
}

export enum PlannerTemplateType {
  RECOMMENDED = 'recommended',
  BLANK = 'blank',
}

export enum CourseSchedulePeriodUnit {
  DAYS = 'days',
  WEEKS = 'weeks',
  MONTHS = 'months',
}

export type EnrollOffset = {
  value: number;
  unit: CourseSchedulePeriodUnit;
};

export type PlannerCRUDCourse = {
  id: string;
  name?: string;
  thumbnailUrl?: string;
  courseThumbnail?: File;
  enrollDate?: Date;
  releaseDate?: Date | null;
  executedAt?: Date;
  periodValue?: number;
  periodUnit?: CourseSchedulePeriodUnit;
  dateKey?: string;
  isDateHeader?: boolean;
  isPastDate?: boolean;
  isLastOfDateGroup?: boolean;
  firstNonPastDate?: boolean;
};

export type CourseScheduleRequestTargets = {
  branches: string[];
  groups: string[];
  users?: string[];
};

export type SavePlannerRequest = {
  id?: string;
  name: string;
  targets: CourseScheduleRequestTargets;
  courses: PlannerCRUDCourse[];
  excludes: CourseScheduleRequestTargets;
  accountId: string;
  type: PlannerType;
  autoEnroll?: boolean;
};

export type DeletePlannerRequest = {
  accountId: string;
  planId: string;
};

export type AddCoursePlannersRequest = {
  courses: string[];
  date: Date;
};

export type PlannerCourseSchedule = {
  course: Course;
  enrollDate?: Date;
  executedAt?: Date;
  periodValue?: number;
  periodUnit?: CourseSchedulePeriodUnit;
};

export type CourseScheduleTarget = {
  id: string;
  name: string;
  descendants?: BranchDescendant[];
  parent?: Branch;
};

export type CourseScheduleTargets = {
  branches: CourseScheduleTarget[];
  groups: CourseScheduleTarget[];
  users: CourseScheduleTarget[];
};

export type CoursePlanner = {
  id: string;
  name: string;
  dynamicCourseSchedules: PlannerCourseSchedule[];
  excludes: CourseScheduleTargets;
  targets: CourseScheduleTargets;
  staticCourseSchedules: PlannerCourseSchedule[];
  editable?: boolean;
  type: PlannerType;
  autoEnroll?: boolean;
};

export type ListCoursePlannerRequest = ListingRequest;
export type ListCoursePlannerResponse = ListingResponse<CoursePlanner>;
