import { Role } from '@/client/roles';
import { SystemRoles, User } from '@/client/users';
import { TranslationFunctionType } from '@/common/types';

export const systemRoleOptions = (t: TranslationFunctionType) => [
  {
    label: t('roles.developer'),
    value: SystemRoles.DEVELOPER,
  },
  {
    label: t('roles.superAdmin'),
    value: SystemRoles.SUPER_ADMIN,
  },
  {
    label: t('roles.admin'),
    value: SystemRoles.ADMIN,
  },
  {
    label: t('roles.learner'),
    value: SystemRoles.LEARNER,
  },
];

export const notSystemRoleOptions = (
  t: TranslationFunctionType,
  user?: User,
) => [
  {
    label: t('roles.multiAccountAdmin'),
    value: SystemRoles.MULTIPLE_ACCOUNTS_ADMIN,
  },

  {
    label: t('roles.admin'),
    value: SystemRoles.ADMIN,
  },
  {
    label: t('roles.learner'),
    value: SystemRoles.LEARNER,
  },
];

export const roleTranslations = (role: Role, t: TranslationFunctionType) => {
  // Using switch case for translation
  switch (role.code) {
    case SystemRoles.MULTIPLE_ACCOUNTS_ADMIN:
      return t('roles.multiAccountAdmin');
    case SystemRoles.DEVELOPER:
      return t('roles.developer');
    case SystemRoles.SUPER_ADMIN:
      return t('roles.superAdmin');
    case SystemRoles.ADMIN:
      return t('roles.admin');
    case SystemRoles.LEARNER:
      return t('roles.learner');
    default:
      return role?.name;
  }
};
