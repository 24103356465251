import { FiltersType } from '@/api/types';
import { UsersReportType } from '@/client/reports';
import { AwarenessReportDatatable } from '@/components/reports/datatables';
import { useAppSelector } from '@/hooks/store';
import { useMixpanel } from '@/hooks/useMixpanel';
import { pendoEvent, pendoProperty, usePendo } from '@/hooks/usePendo';
import { selectCurrentAccount } from '@/store/features/account';
import { selectCurrentUser } from '@/store/features/users';
import mixpanel from 'mixpanel-browser';
import React, { useEffect, useState } from 'react';
import { ReportsActionHeader } from './ReportsActionHeader';

export const AwarenessReportsPage: React.FC = () => {
  const [exportFilters, setExportFilters] = useState<FiltersType | undefined>();
  const [exportSorting, setExportSorting] = useState<
    [string, 'asc' | 'desc'] | null
  >();
  const currentAccount = useAppSelector(selectCurrentAccount);
  const currentUser = useAppSelector(selectCurrentUser);
  const { pendoTrack } = usePendo();

  const { track } = useMixpanel();

  useEffect(() => {
    if (currentUser?.account.isTrackingEnabled && (mixpanel as any)?.__loaded) {
      pendoTrack(pendoEvent.awarenessReportVisited, {
        [pendoProperty.forAccountId]: currentAccount?.id ?? '',
        [pendoProperty.forAccountName]: currentAccount?.name ?? '',
      });

      track('View Awareness reports');
    }
  }, [(mixpanel as any)?.__loaded]);

  return (
    <>
      <ReportsActionHeader
        type={UsersReportType.AWARENESS}
        filters={exportFilters}
        sort={exportSorting}
      />
      <AwarenessReportDatatable
        setExportFilters={setExportFilters}
        setExportSorting={setExportSorting}
      />
    </>
  );
};
