import { LoadingStateType, LoadingStatuses } from '@/common/constants';
import classNames from 'classnames';
import { Button } from 'primereact/button';
import { TooltipOptions } from 'primereact/tooltip/tooltipoptions';
import React, { HTMLAttributes, ReactNode } from 'react';
import styled from 'styled-components';

const StyledButton = styled(Button)`
  &.p-button {
    flex-shrink: 0;
    font-size: var(--small-font-size);
    line-height: var(--small-line-height);
    background: var(--red-main);
    color: var(--white-main);
    border: 1px solid var(--red-main);
    border-radius: var(--default-border-radius);
    padding: 10px var(--default-padding);
    height: var(--default-input-height);
    overflow: hidden;

    &.p-button-text {
      border-color: transparent !important;
      background: transparent !important;
      color: var(--red-main) !important;
    }

    &.p-button-icon-only {
      font-size: var(--medium-font-size);
      line-height: var(--small-line-height);
      border-radius: var(--default-border-radius);
      padding: 10px var(--default-padding);
      height: var(--default-input-height);

      &.p-button-md {
        height: var(--medium-button-height);
        width: var(--medium-button-height);
        padding: 10px var(--small-padding);
      }
    }

    &.p-button-success,
    &.p-button-warning,
    &.p-button-help,
    &.p-button-plain,
    &.p-button-danger {
      background: var(--red-main);
      color: var(--white-main);
      border: 1px solid var(--red-main);
    }

    &.p-button-info {
      background-color: var(--gray-opacity);
      color: var(--black-main);
      border: 1px solid transparent;

      &.p-button-text {
        border-color: transparent !important;
        background: transparent !important;
        color: var(--gray-opacity) !important;
      }
    }

    &.p-button-secondary {
      background: var(--purple-middle);
      color: var(--white-main);
      border: 1px solid var(--purple-middle);

      &.p-button-text {
        border-color: transparent !important;
        background: transparent !important;
        color: var(--purple-middle) !important;
        &.group-button {
          height: auto;
          border-color: var(--beige-main) !important;
          background: var(--beige-main) !important;
          border-top-left-radius: 0px;
          border-bottom-left-radius: 0px;
          padding-left: var(--xsmall-padding);
          &:enabled:hover {
            background: var(--beige-main) !important;
            border-color: var(--beige-main) !important;
          }
        }
      }
      &.beige-background {
        border-color: transparent !important;
        background: var(--beige-main) !important;
        color: var(--purple-middle) !important;
        &:enabled:hover {
          border-color: transparent !important;
          background: var(--red-dark) !important;
          color: var(--white-main) !important;
        }
      }
      &.white-background {
        border-color: transparent !important;
        background: var(--white-main) !important;
        color: var(--purple-middle) !important;
        &:enabled:hover {
          border-color: transparent !important;
          background: var(--white-main) !important;
          color: var(--red-main) !important;
        }
      }
    }

    &.p-button-outlined {
      background: var(--white-main) !important;
      color: var(--purple-middle) !important;
      border: 1px solid var(--purple-middle) !important;
    }

    &.p-button-rounded {
      border-radius: 50%;
      padding: var(--small-padding);
      height: var(--default-input-height);
      width: var(--default-input-height);
    }

    &:hover,
    &:enabled:hover,
    &.p-button-secondary:enabled:hover,
    &.p-button-outlined:enabled:hover &.p-button-success:enabled:hover,
    &.p-button-warning:enabled:hover,
    &.p-button-help:enabled:hover,
    &.p-button-plain:enabled:hover,
    &.p-button-danger:enabled:hover {
      background: var(--red-dark) !important;
      color: var(--white-main) !important;
      border: 1px solid var(--red-dark) !important;
    }

    &.p-button-info:enabled:hover {
      background: var(--red-pale) !important;
      color: var(--red-middle) !important;
      border: 1px solid var(--red-pale) !important;
    }

    &.p-button-text:enabled:hover {
      color: var(--red-main) !important;
      background: transparent !important;
      border-color: transparent !important;
    }

    .p-button-label {
      font-weight: 500;
      transition: none;
    }

    &.p-button-info:enabled:active {
      box-shadow: var(--box-shadow-red) !important;
      background: var(--red-main) !important;
      color: var(--white-main) !important;
      border: 1px solid var(--red-main) !important;
    }

    &:focus,
    &:enabled:focus,
    &.p-button-secondary:enabled:focus {
      box-shadow: var(--box-shadow-red) !important;
    }

    &.p-button-xs {
      padding: 9px;
      height: 32px;
      width: 32px;
    }
  }
`;

export type AppButtonProps = {
  label?: string;
  type?: 'text' | 'outlined';
  isSubmit?: boolean;
  severity?:
    | 'secondary'
    | 'success'
    | 'info'
    | 'warning'
    | 'help'
    | 'danger'
    | 'plain';
  icon?: string | ReactNode;
  iconPos?: 'right' | 'left';
  onClick?: any;
  state?: LoadingStateType;
  isDisabled?: boolean;
  size?: 'xs' | 'sm' | 'md' | 'lg';
  rounded?: boolean;
  tooltip?: string;
  tooltipOptions?: TooltipOptions;
  ariaLabel?: string;
} & HTMLAttributes<HTMLButtonElement>;

const AppButton: React.FC<AppButtonProps> = ({
  label,
  type,
  isSubmit,
  severity,
  icon,
  iconPos,
  className,
  onClick,
  state = LoadingStatuses.IDLE,
  isDisabled = false,
  size,
  rounded = false,
  tooltip,
  tooltipOptions,
  ariaLabel,
}) => {
  const styles = classNames(
    'outline-none',
    {
      [`p-button-${size}`]: size,
      'p-button-danger': state === 'error',
      [`p-button-${severity}`]: severity,
      [`p-button-${type}`]: type,
    },
    className,
  );

  const stateIcon = classNames({
    'pi pi-spin pi-spinner': state === LoadingStatuses.LOADING,
    'pi pi-times': state === 'error',
  });

  return (
    <StyledButton
      label={label}
      type={isSubmit ? 'submit' : 'button'}
      icon={state !== LoadingStatuses.IDLE ? stateIcon : icon}
      iconPos={iconPos || 'left'}
      className={styles}
      disabled={
        isDisabled ||
        [LoadingStatuses.LOADING, LoadingStatuses.ERROR].includes(state)
      }
      aria-label={ariaLabel}
      onClick={onClick}
      rounded={rounded}
      tooltip={tooltip}
      tooltipOptions={tooltipOptions}
    />
  );
};

export default AppButton;
