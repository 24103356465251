import { Conditions } from '@/api/enums';
import { handleAxiosError } from '@/api/helpers';
import { AppBreadCrumbTemplate } from '@/app/AppBreadCrumbTemplate';
import { Account } from '@/client/accounts';
import { Branch } from '@/client/branches';
import { FilterNamesEnum, getFiltersFromColumns } from '@/client/helpers';
import { Actions, Subjects, SystemRoles } from '@/client/users';
import {
  RedirectPaths,
  RedirectPathsEnum,
  TableNamesEnum,
} from '@/common/constants';
import { DialogContext } from '@/common/context';
import { TranslationFunctionType } from '@/common/types';
import {
  DataTable,
  DataTableActions,
  DataTableColumnsMultiselect,
  DataTableColumnType,
  DataTableFilters,
  DataTableToolbar,
  FilterTypeEnum,
} from '@/components/tables/crud';
import { useBranches, useDeleteBranches, useUpdateBranch } from '@/hooks/query';
import { useAppSelector } from '@/hooks/store';
import { useTable } from '@/hooks/table.hook';
import { usePermission } from '@/hooks/usePermission';
import { useToast } from '@/hooks/useToast';
import { selectCurrentAccount } from '@/store/features/account';
import { selectCurrentUser } from '@/store/features/users';
import { AppBreadCrumb } from '@/ui/breadcrumb';
import { AppButton } from '@/ui/buttons';
import { FormatDate } from '@/ui/date';
import { FlexContainer } from '@/ui/styled-ui';
import { AxiosError } from 'axios';
import { DataTableRowClickEvent } from 'primereact/datatable';
import { MenuItem } from 'primereact/menuitem';
import { ProgressSpinner } from 'primereact/progressspinner';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const getBreadcrumbs = (
  account: Account,
  t: TranslationFunctionType,
): MenuItem[] => {
  return [
    {
      label: account?.name,
      url: RedirectPaths[RedirectPathsEnum.EDIT_ACCOUNT](account?.id),
      template: AppBreadCrumbTemplate,
    },
    {
      label: t('branches'),
      url: RedirectPaths[RedirectPathsEnum.BRANCHES](),
      template: AppBreadCrumbTemplate,
    },
  ];
};

export const BranchesPage: React.FC = () => {
  const { t } = useTranslation();
  const toast = useToast();
  const { can } = usePermission();
  const { skip, take, sort, apiFilters, onSort, onFilter, onPage } = useTable();

  const account = useAppSelector(selectCurrentAccount);
  const currentUser = useAppSelector(selectCurrentUser);

  const { setDialogData } = useContext(DialogContext);

  const { isLoading, branches, refetch } = useBranches({
    accountId: account?.id,
    take,
    skip,
    filters: apiFilters,
    sort:
      sort && sort.length > 0 && sort[0] !== 'created'
        ? [sort.join(',')]
        : ['left,asc'],
  });

  const updateBranch = useUpdateBranch();
  const deleteBranches = useDeleteBranches();

  const navigate = useNavigate();

  const columns: DataTableColumnType[] = [
    {
      field: 'name',
      header: t('generic.name'),
      sortable: true,
      filterable: true,
      filters: { field: FilterNamesEnum.TREE, type: FilterTypeEnum.TEXT },
      render: (row: Branch) => {
        const branchNamesArray: string[] = row?.tree
          ? row.tree.split(' / ')
          : [];
        branchNamesArray.pop();

        return (
          <>
            {!!branchNamesArray.length && (
              <div className="secondary-text">
                {`${branchNamesArray.join(' / ')} /`}
              </div>
            )}
            <span>{row.name}</span>
          </>
        );
      },
    },
    {
      field: 'updated',
      header: t('generic.updated'),
      sortable: true,
      filterable: false,
      render: (row: Branch) =>
        row?.updated ? <FormatDate date={row?.updated} /> : t('generic.never'),
    },
    {
      field: 'active',
      header: t('generic.active'),
      sortable: true,
      filterable: true,
      filters: { type: FilterTypeEnum.SELECT_ACTIVE_INACTIVE },
      render: (row: Branch) =>
        row.active ? t('generic.active') : t('generic.inactive'),
    },
    {
      field: 'actions',
      header: t('generic.actions'),
      sortable: false,
      filterable: false,
      style: {
        width: '80px',
        textAlign: 'center' as const,
      },
      render: (row: Branch) => {
        if (deletingBranchId === row.id) {
          return (
            <FlexContainer>
              <ProgressSpinner style={{ width: '24px', height: '24px' }} />
            </FlexContainer>
          );
        }
        return (
          <DataTableActions
            disabled={menuItems(row).length < 1}
            menuItems={menuItems(row)}
          />
        );
      },
    },
  ];

  // Set the preselected columns
  const [visibleColumns, setVisibleColumns] = useState<string[]>([]);
  const defaultVisibleColumns = columns.map((column) => column.field);
  const alwaysVisibleColumns = ['name', 'actions'];
  //

  const menuItems = (branch: Branch) => {
    const menu: MenuItem[] = [
      {
        label: t('users'),
        icon: 'pi pi-users',
        command: () =>
          navigate(RedirectPaths[RedirectPathsEnum.BRANCHES_USERS](branch.id)),
      },
    ];

    if (
      can(Actions.UPDATE, Subjects.BRANCHES) &&
      (!account?.isSystem || currentUser?.role.code === SystemRoles.DEVELOPER)
    ) {
      menu.push(
        ...[
          {
            items: [
              {
                label: t('generic.edit'),
                icon: 'pi pi-pencil',
                command: () => {
                  navigate(
                    RedirectPaths[RedirectPathsEnum.BRANCHES_EDIT](branch.id),
                  );
                },
              },
              {
                label: branch.active
                  ? t('generic.deactivate')
                  : t('generic.activate'),
                icon: branch.active ? 'pi pi-minus-circle' : 'pi pi-check',
                command: () =>
                  setDialogData({
                    type: 'confirmation',
                    show: true,
                    header: `${
                      branch.active
                        ? t('generic.deactivate')
                        : t('generic.activate')
                    } ${t('dialog.confirm')}`,
                    message: branch.active
                      ? t('branch.deactivate')
                      : t('branch.activate'),
                    onAccept: async () =>
                      handleToggleItemsStatus(branch.id, branch?.active),
                  }),
              },
              {
                label: t('generic.delete'),
                icon: 'pi pi-times',
                command: () =>
                  setDialogData({
                    type: 'confirmation',
                    show: true,
                    header: t('dialog.delete'),
                    message: t('dialog.delete.branch.name', {
                      name: branch?.name,
                    }),
                    onAccept: async () => handleDeleteBranch(branch.id),
                    closeImmediatelyAfterAccept: true,
                    confirmationMessage: t('dialog.delete.branch.confirmation'),
                  }),
              },
            ],
          },
        ],
      );
    }

    return menu;
  };

  const handleToggleItemsStatus = async (
    branchId: string,
    active?: boolean,
  ) => {
    try {
      await updateBranch.update({
        accountId: (account as Account)?.id,
        branchId: branchId,
        updates: { active: !active },
      });
      await refetch();
      toast?.success(
        t('toast.success'),
        active ? t('branch.deactivated') : t('branch.activated'),
      );
    } catch (e) {
      handleAxiosError(e as Error | AxiosError, toast);
    }
  };

  const [deletingBranchId, setDeletingBranchId] = useState<string | null>(null);
  const handleDeleteBranch = async (branchId: string) => {
    setDeletingBranchId(branchId);
    try {
      await deleteBranches.delete({
        accountId: (account as Account)?.id,
        branchId,
      });
      await refetch();
      toast?.success(t('toast.success'), t('branch.deleted'));
    } catch (e) {
      handleAxiosError(e as Error | AxiosError, toast);
    }
    setDeletingBranchId(null);
  };

  const toolbar = (
    <DataTableToolbar>
      <FlexContainer
        justify="space-between"
        gap={8}
        align="flex-start"
        wrap="wrap"
      >
        <DataTableFilters
          filters={getFiltersFromColumns(columns)}
          onFilter={onFilter}
          tableName={TableNamesEnum.BRANCHES_LIST}
        />
        {can(Actions.UPDATE, Subjects.BRANCHES) &&
          (!account?.isSystem ||
            currentUser?.role.code === SystemRoles.DEVELOPER) && (
            <AppButton
              label={t('button.createNew')}
              severity="secondary"
              onClick={() => {
                navigate(RedirectPaths[RedirectPathsEnum.BRANCHES_CREATE]());
              }}
            />
          )}
      </FlexContainer>
      <DataTableColumnsMultiselect
        columns={columns}
        tableName={TableNamesEnum.BRANCHES_LIST}
        visibleColumns={visibleColumns}
        setVisibleColumns={setVisibleColumns}
        defaultVisibleColumns={defaultVisibleColumns}
        alwaysVisibleColumns={alwaysVisibleColumns}
      />
    </DataTableToolbar>
  );

  const onRowClick = (e: DataTableRowClickEvent) =>
    navigate(RedirectPaths[RedirectPathsEnum.BRANCHES_EDIT](e.data.id));

  return (
    <>
      <AppBreadCrumb model={getBreadcrumbs(account as Account, t)} />
      <h1>{t('branches')}</h1>
      <DataTable
        data={branches?.result}
        count={branches?.count}
        isLoading={isLoading}
        toolbar={toolbar}
        columns={columns}
        visibleColumns={visibleColumns}
        rows={take}
        skip={skip}
        onPage={onPage}
        onSort={onSort}
        sort={sort}
        onRowClick={onRowClick}
      />
    </>
  );
};
