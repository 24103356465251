import { RedirectPaths, RedirectPathsEnum } from '@/common/constants';
import { useAppSelector } from '@/hooks/store';
import { selectCurrentAccount } from '@/store/features/account';
import classNames from 'classnames';
import { MenuItem } from 'primereact/menuitem';
import { TabMenu } from 'primereact/tabmenu';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';

const StyledTabMenu = styled(TabMenu)`
  margin-bottom: var(--default-padding);
`;

const getItemTemplate = (url: string, current: string) => (item: MenuItem) =>
  (
    <Link
      to={url}
      className={classNames({
        'p-menuitem-link': true,
        'p-menuitem-link-active': current.includes(url),
      })}
      role="menuitem"
    >
      <span className="p-menuitem-text">{item.label}</span>
    </Link>
  );

export const SenderProfilesTabs: React.FC = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const currentAccount = useAppSelector(selectCurrentAccount);

  const tabItems: MenuItem[] = [
    {
      label: t('generic.predefined'),
      template: getItemTemplate(
        RedirectPaths[RedirectPathsEnum.SENDER_PROFILES_PREDEFINED](),
        location.pathname,
      ),
      data: RedirectPaths[RedirectPathsEnum.SENDER_PROFILES_PREDEFINED](),
    },
  ];

  if (currentAccount && !currentAccount.isSystem) {
    tabItems.push({
      label: t('generic.custom'),
      template: getItemTemplate(
        RedirectPaths[RedirectPathsEnum.SENDER_PROFILES_CUSTOM](),
        location.pathname,
      ),
      data: RedirectPaths[RedirectPathsEnum.SENDER_PROFILES_CUSTOM](),
    });
  }

  const paths = tabItems.map((item) => item.data);
  const currentPath = location.pathname;
  const [activeIndex, setActiveIndex] = useState<number>(
    paths.indexOf(currentPath),
  );

  return (
    <StyledTabMenu
      model={tabItems}
      activeIndex={activeIndex}
      onTabChange={(e) => setActiveIndex(e.index)}
    />
  );
};
