import { equal, nested, notEqual } from '@/api/helpers';
import { MaterialType } from '@/client/courses';
import { UserAwareness } from '@/client/reports';
import {
  RedirectPaths,
  RedirectPathsEnum,
  TableNamesEnum,
} from '@/common/constants';
import {
  DataTable,
  DataTableColumnsMultiselect,
  DataTableColumnType,
  DataTableToolbar,
} from '@/components/tables/crud';
import { useUsersAwarenessReport } from '@/hooks/query';
import { useAppSelector } from '@/hooks/store';
import { useTable } from '@/hooks/table.hook';
import { selectCurrentAccount } from '@/store/features/account';
import { FormatDate } from '@/ui/date';
import { FlexContainer } from '@/ui/styled-ui';
import { determineAttemptStatusColor } from '@/utils/helpers/ui.helper';
import { User } from '@sentry/react';
import { t } from 'i18next';
import { DataTableRowClickEvent } from 'primereact/datatable';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

type UserDocumentsDatatableProps = {
  user: User;
};

export const UserDocumentsDatatable: React.FC<UserDocumentsDatatableProps> = ({
  user,
}) => {
  const account = useAppSelector(selectCurrentAccount);
  const navigate = useNavigate();

  const { skip, take, sort, apiFilters, onSort, onPage } = useTable();

  const { isLoading, usersAwarenessReport: userDocuments } =
    useUsersAwarenessReport(account?.id || '', {
      take,
      skip,
      filters: [
        equal('id', user?.id),
        nested('course', [notEqual('type', MaterialType.COURSE)]),
        ...apiFilters,
      ],
    });

  const columns: DataTableColumnType[] = [
    {
      field: 'course',
      header: t('materials.enrolled'),
      sortable: false,
      filterable: false,
      render: (row: UserAwareness) => row?.course.name,
    },
    {
      field: 'date',
      header: t('generic.enrollDate'),
      sortable: false,
      filterable: false,
      render: (row: UserAwareness) =>
        row?.course.enrollmentDate ? (
          <FormatDate date={row?.course.enrollmentDate} />
        ) : (
          <span>&#8212;</span>
        ),
    },
    {
      field: 'completeDate',
      header: t('reports.courses.completionDate'),
      sortable: false,
      filterable: false,
      render: (row: UserAwareness) =>
        row?.course.completed ? (
          <FormatDate date={row?.course.completed} />
        ) : (
          <span>&#8212;</span>
        ),
    },
    {
      field: 'status',
      header: t('generic.status'),
      sortable: false,
      filterable: false,
      // filters: {
      //   field: FilterNamesEnum.AWARENESS_REPORTS_STATUS,
      //   type: FilterTypeEnum.MULTI_SELECT,
      //   options: getAwarenessReportStatusOptions(t),
      //   placeholder: t('generic.status.search'),
      // },
      render: (row: UserAwareness) =>
        determineAttemptStatusColor(row?.course.status, t),
    },
  ];

  // Set the preselected columns
  const [visibleColumns, setVisibleColumns] = useState<string[]>([]);
  const defaultVisibleColumns = columns.map((column) => column.field);
  const alwaysVisibleColumns = ['course'];
  //

  const toolbar = (
    <DataTableToolbar>
      <FlexContainer
        justify="space-between"
        gap={8}
        align="flex-start"
        wrap="wrap"
      >
        {/* <DataTableFilters
          filters={getFiltersFromColumns(columns)}
          onFilter={onFilter}
          className="flex-initial"
        /> */}
        <></>
      </FlexContainer>
      <DataTableColumnsMultiselect
        columns={columns}
        tableName={TableNamesEnum.USER_MATERIALS_LIST}
        visibleColumns={visibleColumns}
        setVisibleColumns={setVisibleColumns}
        defaultVisibleColumns={defaultVisibleColumns}
        alwaysVisibleColumns={alwaysVisibleColumns}
      />
    </DataTableToolbar>
  );

  const onRowClick = (e: DataTableRowClickEvent) =>
    navigate(
      RedirectPaths[RedirectPathsEnum.MATERIALS_EDIT](e.data?.course?.id),
    );

  return (
    <DataTable
      dataKey="dataId"
      data={userDocuments?.result.map((x) => ({
        ...x,
        dataId: `${x.id}-${x.course?.id}`,
      }))}
      count={userDocuments?.count as number}
      isLoading={isLoading}
      toolbar={toolbar}
      columns={columns}
      visibleColumns={visibleColumns}
      onPage={onPage}
      rows={take}
      skip={skip}
      onSort={onSort}
      sort={sort}
      onRowClick={onRowClick}
    />
  );
};
