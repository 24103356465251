import { CourseEntityScheduleEnum } from '@/client/courses';
import { TranslationFunctionType } from '@/common/types';
import { array, boolean, date, object, string } from 'yup';

export const accountsEnrollSchema = (t: TranslationFunctionType) =>
  object().shape({
    accountsType: string(),
    accounts: array().required().min(1, t('error.requiredField')),
    date: date().required(t('error.requiredField')),
    autoEnroll: boolean(),
    type: string(),
  });

export const accountsUnenrollSchema = (t: TranslationFunctionType) =>
  object().shape({
    accounts: array().required().min(1, t('error.requiredField')),
  });

export const branchesEnrollSchema = (t: TranslationFunctionType) =>
  object().shape({
    branches: array().required().min(1, t('error.requiredField')),
    date: date().required(t('error.requiredField')),
    autoEnroll: boolean(),
    type: string(),
  });

export const groupsEnrollSchema = (t: TranslationFunctionType) =>
  object().shape({
    groups: array().required().min(1, t('error.requiredField')),
    date: date().required(t('error.requiredField')),
    autoEnroll: boolean(),
    type: string(),
  });

export const branchesUnenrollSchema = (t: TranslationFunctionType) =>
  object().shape({
    branches: array().required().min(1, t('error.requiredField')),
  });

export const groupsUnenrollSchema = (t: TranslationFunctionType) =>
  object().shape({
    groups: array().required().min(1, t('error.requiredField')),
  });

export const usersEnrollSchema = (t: TranslationFunctionType) =>
  object().shape({
    users: array().required().min(1, t('error.requiredField')),
    date: date().required(t('error.requiredField')),
    autoEnroll: boolean(),
    type: string(),
  });

export const usersUnenrollSchema = (t: TranslationFunctionType) =>
  object().shape({
    users: array().required().min(1, t('error.requiredField')),
  });

export const changeEnrollDateSchema = (t: TranslationFunctionType) =>
  object().shape({
    date: date().required(t('error.requiredField')),
    type: string(),
  });

export const coursePlannersEnrollSchema = (t: TranslationFunctionType) =>
  object().shape({
    courses: array().required().min(1, t('error.requiredField')),
    date: date().required(t('error.requiredField')),
    type: string(),
  });

export const manyToManyEnrollSchema = (t: TranslationFunctionType) =>
  object().shape({
    courses: array().min(1, t('error.requiredField')),
    enrollTo: string().oneOf([
      CourseEntityScheduleEnum.ACCOUNT,
      CourseEntityScheduleEnum.BRANCH,
      CourseEntityScheduleEnum.GROUP,
      CourseEntityScheduleEnum.USER,
    ]),
    users: array().when(['enrollTo'], ([enrollTo], schema) =>
      enrollTo === CourseEntityScheduleEnum.USER
        ? schema.min(1, t('error.requiredField'))
        : schema.notRequired(),
    ),
    branches: array().when(['enrollTo'], ([enrollTo], schema) =>
      enrollTo === CourseEntityScheduleEnum.BRANCH
        ? schema.min(1, t('error.requiredField'))
        : schema.notRequired(),
    ),
    groups: array().when(['enrollTo'], ([enrollTo], schema) =>
      enrollTo === CourseEntityScheduleEnum.GROUP
        ? schema.min(1, t('error.requiredField'))
        : schema.notRequired(),
    ),
    date: date().required(t('error.requiredField')),
    autoEnroll: boolean(),
    type: string(),
  });
