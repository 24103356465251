import { languageDisplayLabel } from '@/api/enums';
import { MaterialType } from '@/client/courses';
import { AttemptsReport } from '@/client/reports';
import { DataTable, DataTableColumnType } from '@/components/tables/crud';
import { useCourse, useCourseAttemptsReports } from '@/hooks/query';
import { FlexContainer } from '@/ui/styled-ui';
import { ProgressSpinner } from 'primereact/progressspinner';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { CoursesActionHeader } from './CoursesActionHeader';
import { CoursesTabs } from './CoursesTabs';

export const CoursesAnalytics: React.FC = () => {
  const { t } = useTranslation();
  const { id: courseId } = useParams();

  const { course, isLoading: isCourseLoading } = useCourse({
    courseId,
  });

  const { attemptStats, isLoading, refetch } = useCourseAttemptsReports({
    courseId,
  });
  const columns: DataTableColumnType[] = [
    {
      field: 'language',
      header: t('generic.lang'),
      sortable: false,
      filterable: false,
      render: (row: AttemptsReport) =>
        row.language
          ? languageDisplayLabel[row.language]
          : t('course.analytics.noLanguageInfo'),
    },
    {
      field: 'count',
      style: { textAlign: 'center' },
      header: t('course.analytics.attemptsCount'),
      sortable: false,
      filterable: false,
      render: (row: AttemptsReport) => row.count,
    },
  ];

  return (
    <>
      {isCourseLoading && !course && (
        <FlexContainer direction="column" className="mt-5">
          <ProgressSpinner />
          <h3>{t('course.analytics.loading')}</h3>
        </FlexContainer>
      )}
      {!isCourseLoading && course && (
        <>
          <CoursesActionHeader course={course} onTriggerRefetch={refetch} />
          {courseId && (
            <CoursesTabs
              courseId={courseId}
              isMaterial={
                course.type === MaterialType.RESOURCE ||
                course.type === MaterialType.CUSTOM_MATERIAL
              }
            />
          )}
          <DataTable
            data={attemptStats?.sort((a, b) => b.count - a.count)}
            count={attemptStats?.length}
            isLoading={isLoading}
            columns={columns}
            classNames="overflow-hidden"
          />
        </>
      )}
    </>
  );
};
