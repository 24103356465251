import { GroupCondition } from '@/api/enums';
import { equal, group, handleAxiosError, like, nested } from '@/api/helpers';
import {
  Campaign,
  CreateCampaignFromTemplateFormState,
} from '@/client/campaigns';
import { FilterNamesEnum } from '@/client/helpers';
import { Actions, Subjects } from '@/client/users';
import {
  LoadingStatuses,
  RedirectPaths,
  RedirectPathsEnum,
  TableNamesEnum,
} from '@/common/constants';
import { availableLanguagesDropdown } from '@/common/constants/languages';
import { TranslationFunctionType } from '@/common/types';
import { useCampaigns, useCloneCampaign } from '@/hooks/query';
import { useAppSelector } from '@/hooks/store';
import { useTable } from '@/hooks/table.hook';
import { usePermission } from '@/hooks/usePermission';
import { useToast } from '@/hooks/useToast';
import { selectCurrentAccount } from '@/store/features/account';
import { AppButton } from '@/ui/buttons';
import { AppChip } from '@/ui/chip';
import { Slider } from '@/ui/slider';
import { FlexContainer } from '@/ui/styled-ui';
import {
  difficultyFilterOptions,
  senderOptions,
} from '@/utils/helpers/ui.helper';
import { AxiosError } from 'axios';
import { Form, Formik, FormikValues } from 'formik';
import { debounce } from 'lodash';
import { Dialog, DialogProps } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { ProgressSpinner } from 'primereact/progressspinner';
import React, { SyntheticEvent, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { DataTableFilters, FilterType, FilterTypeEnum } from '../tables/crud';
import { CampaignTemplatesList } from './CampaignTemplatesList';
import { PreviewCampaignTemplate } from './PreviewCampaignTemplate';

const StyledDialog = styled(Dialog)`
  &:not(.fullscreen) {
    @media screen and (min-width: 1133px) {
      width: 1132px;
    }
    @media screen and (max-width: 1132px) {
      width: 800px;
    }
    @media screen and (max-width: 800px) {
      width: 600px;
    }
    max-width: 1132px;
    height: 848px;
  }

  &.fullscreen {
    height: 100%;
    width: 100%;

    .p-dialog-header {
      border-bottom: 1px solid var(--gray-dark);
      padding-bottom: var(--default-padding);
    }

    .p-dialog-content {
      padding: 0;
    }
  }

  .p-dialog-footer {
    border-top: 1px solid var(--gray-dark);
  }

  .p-dialog-header-icons {
    position: fixed;
    top: var(--default-padding);
    right: var(--default-padding);
  }
`;

const StyledName = styled.span`
  color: var(--red-main);
`;

const StyledAppChip = styled(AppChip)`
  cursor: pointer;
  border-bottom: 1px solid transparent;

  &:hover {
    border-color: var(--red-dark);
  }
`;

export const CampaignTemplatesModal: React.FC<
  DialogProps & { preselectedCampaign?: Campaign; isCatalogue?: boolean }
> = ({ onHide, visible, preselectedCampaign, isCatalogue }) => {
  const { t } = useTranslation();
  const { can } = usePermission();
  const [showList, setShowList] = useState<boolean>(!isCatalogue);
  const currentAccount = useAppSelector(selectCurrentAccount);
  const navigate = useNavigate();
  const toast = useToast();
  const [isLoading, setIsLoading] = useState<LoadingStatuses>(
    LoadingStatuses.IDLE,
  );
  const [initialValues, setInitialValues] =
    useState<CreateCampaignFromTemplateFormState>({
      selectedCampaign: preselectedCampaign,
    });

  const { apiFilters, onFilter } = useTable();

  const [search, setSearch] = useState('');
  const debouncedSearchValue = useMemo(
    () =>
      debounce((event: SyntheticEvent<HTMLInputElement>) => {
        setSearch((event.target as HTMLInputElement).value);
      }, 300),
    [],
  );
  const {
    isLoading: areCampaignsLoading,
    campaigns,
    refetch,
  } = useCampaigns({
    take: 200,
    skip: 0,
    isTemplate: 1,
    withTemplates: 1,
    filters: [
      ...(apiFilters ? apiFilters : []),
      ...(search
        ? [
            group(GroupCondition.OR, [
              like('name', search),
              nested('sendingProfile', [like('name', search)]),
            ]),
          ]
        : []),
      equal('isTemplate', true),
    ],
  });

  useEffect(() => {
    setInitialValues({ selectedCampaign: preselectedCampaign });
  }, [preselectedCampaign]);

  const cloneCampaign = useCloneCampaign();

  const handleCreateNewCampaignFromTemplate = async (data: FormikValues) => {
    try {
      if (!currentAccount || !data?.selectedCampaign) return;
      setIsLoading(LoadingStatuses.LOADING);

      const response = await cloneCampaign.clone({
        campaignId: data?.selectedCampaign?.id,
        account: currentAccount?.id,
      });

      setIsLoading(LoadingStatuses.IDLE);

      if (response && !response?.id) return;

      navigate(RedirectPaths[RedirectPathsEnum.CAMPAIGNS_EDIT](response?.id), {
        state: { isTemplate: false },
      });
      toast?.success(t('toast.success'), t('campaign.created'));
    } catch (e) {
      handleAxiosError(e as Error | AxiosError, toast);
      setIsLoading(LoadingStatuses.IDLE);
    }
  };

  const getFilters = (t: TranslationFunctionType): FilterType[] => [
    {
      label: t('generic.lang'),
      field: FilterNamesEnum.EMAIL_TEMPLATE_BY_LANGUAGE,
      placeholder: t('generic.lang.search'),
      type: FilterTypeEnum.MULTI_SELECT,
      options: availableLanguagesDropdown,
    },
    {
      label: t('generic.difficulty'),
      field: FilterNamesEnum.DIFFICULTY,
      placeholder: t('generic.select'),
      type: FilterTypeEnum.MULTI_SELECT,
      options: difficultyFilterOptions,
    },
    {
      label: t('generic.sender'),
      field: FilterNamesEnum.SENDER,
      type: FilterTypeEnum.SELECT,
      options: senderOptions(t),
      placeholder: t('generic.select'),
    },
  ];

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={handleCreateNewCampaignFromTemplate}
      >
        {({ values, setFieldValue, errors, submitForm }) => {
          useEffect(() => {
            if (
              (preselectedCampaign &&
                !campaigns?.result.find(
                  (campaign) => campaign.id === preselectedCampaign.id,
                )) ||
              values.selectedCampaign?.id !== preselectedCampaign?.id ||
              (!showList && !preselectedCampaign)
            ) {
              setFieldValue('selectedCampaign', campaigns?.result[0]);
            }
          }, [campaigns?.result, preselectedCampaign]);

          const header = (
            <>
              {values.selectedCampaign ? (
                <h1>
                  {t('campaigns.fromTemplate')}:{' '}
                  <StyledName>{values.selectedCampaign.name}</StyledName>
                </h1>
              ) : (
                <h1>{t('campaigns.fromTemplate')}</h1>
              )}
              <FlexContainer
                justify="space-between"
                align="flex-start"
                wrap="wrap"
                gap={8}
                className="mt-4 w-full"
              >
                <DataTableFilters
                  filters={getFilters(t)}
                  onFilter={onFilter}
                  tableName={
                    !isCatalogue
                      ? TableNamesEnum.CAMPAIGNS_TEMPLATES_LIST
                      : undefined
                  }
                  className="flex-initial"
                />
                <div className="p-input-icon-left flex-auto min-w-300">
                  <InputText
                    className="w-full"
                    onInput={debouncedSearchValue}
                    placeholder={t('templates.searchNameOrSender')}
                    autoComplete="off"
                  />
                  <i className="pi pi-search" />
                </div>
              </FlexContainer>
            </>
          );
          const footer = (
            <FlexContainer width="100%" justify="flex-start" gap={24}>
              {showList ? (
                <AppButton
                  label={t('generic.preview')}
                  severity="secondary"
                  onClick={() => setShowList(false)}
                  isDisabled={
                    !values.selectedCampaign || !!Object.keys(errors).length
                  }
                />
              ) : (
                <Slider>
                  <FlexContainer
                    gap={8}
                    justify="flex-start"
                    align="flex-start"
                    flex="1 1 80%"
                  >
                    {!!campaigns?.result.length &&
                      campaigns.result
                        .filter((x) => x.id !== preselectedCampaign?.id)
                        .map((campaign: Campaign) => (
                          <StyledAppChip
                            key={campaign.id}
                            label={campaign.name}
                            type="error"
                            onClick={() => {
                              setFieldValue('selectedCampaign', campaign);
                            }}
                          />
                        ))}
                  </FlexContainer>
                </Slider>
              )}
              <FlexContainer
                justify="flex-end"
                className="mt-auto pb-2 min-w-max"
                wrap="wrap"
                flex="1 1 15%"
              >
                <AppButton
                  label={t('generic.close')}
                  severity="secondary"
                  type="outlined"
                  onClick={() => {
                    if (showList || preselectedCampaign) {
                      onHide();
                      setFieldValue('selectedCampaign', undefined);
                      setSearch('');
                    } else {
                      setShowList(true);
                    }
                  }}
                  className="mr-3"
                />
                {can(Actions.CREATE, Subjects.CAMPAIGNS) && (
                  <AppButton
                    label={t('campaign.create')}
                    onClick={submitForm}
                    state={isLoading}
                    isDisabled={
                      !values.selectedCampaign || !!Object.keys(errors).length
                    }
                  />
                )}
              </FlexContainer>
            </FlexContainer>
          );

          return (
            <Form id="campaign-template-form-container">
              <StyledDialog
                blockScroll
                visible={visible}
                header={header}
                footer={footer}
                onHide={onHide}
                draggable={false}
                className={!showList ? 'fullscreen' : ''}
                maximized={!showList}
              >
                {areCampaignsLoading && !campaigns && (
                  <FlexContainer direction="column" className="mt-5">
                    <ProgressSpinner />
                    <h3>{t('campaign.template.email.loading')}</h3>
                  </FlexContainer>
                )}
                {!areCampaignsLoading && campaigns?.result.length === 0 && (
                  <FlexContainer direction="column" className="mt-5">
                    <h3>{t('campaign.template.email.empty')}</h3>
                  </FlexContainer>
                )}
                {!areCampaignsLoading && (
                  <>
                    {showList && !!campaigns?.result.length ? (
                      <CampaignTemplatesList
                        values={values}
                        setFieldValue={setFieldValue}
                        campaigns={campaigns}
                        refetch={refetch}
                      />
                    ) : (
                      <PreviewCampaignTemplate
                        campaign={values.selectedCampaign}
                      />
                    )}
                  </>
                )}
              </StyledDialog>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};
