import { PlannerTemplateType, PlannerType } from '@/client/planner/types';
import { TranslationFunctionType } from '@/common/types';
import { AccountCard } from '@/components/accounts';
import { useAppSelector } from '@/hooks/store';
import passwordsCourse from '@/images/placeholders/passwords.svg';
import personalDataCourse from '@/images/placeholders/personal-data.svg';
import workingFromHomeCourse from '@/images/placeholders/working-from-home.svg';
import { selectCurrentAccount } from '@/store/features/account';
import { AppButton } from '@/ui/buttons';
import { FlexContainer } from '@/ui/styled-ui';
import { t } from 'i18next';
import { Dialog, DialogProps } from 'primereact/dialog';
import { Steps } from 'primereact/steps';
import React, { useState } from 'react';
import styled from 'styled-components';

const StyledDialog = styled(Dialog)`
  width: 800px;

  .p-dialog-header {
    padding-block: var(--small-padding);
  }

  .p-dialog-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0;
  }

  .p-steps {
    background-color: transparent;
    padding-block: 20px;
    .p-steps-item {
      padding-inline: var(--xsmall-padding);
      &:first-child {
        justify-content: flex-start;
      }
      &:last-child {
        justify-content: flex-end;
      }

      .p-menuitem-link {
        background-color: transparent !important;
      }
    }
  }

  .cards {
    padding: var(--default-padding);
    padding-top: 0;
    gap: var(--default-padding);
    height: 100%;
    align-items: stretch;
  }

  .placeholders-container {
    display: flex;
    align-items: flex-start;
    align-content: flex-start;
    gap: var(--small-padding);
    align-self: stretch;
    flex-wrap: wrap;
  }
`;

const StyledCard = styled.div`
  display: flex;
  height: 80px;
  width: 70px;
  justify-content: center;
  align-items: center;
  flex: 1 1 0;
  border-radius: var(--xxxsmall-border-radius);
  border: 1px solid var(--gray-dark);
  overflow: hidden;

  i {
    color: var(--gray-darker);
  }

  img {
    height: 100%;
    width: auto;
  }
`;

type CreatePlannerPromptProps = {
  setPlannerType: React.Dispatch<React.SetStateAction<PlannerType | undefined>>;
  plannerType: undefined | PlannerType;
  setPlannerTemplateType: React.Dispatch<
    React.SetStateAction<PlannerTemplateType | undefined>
  >;
  setIsPlannerModalVisible: (isVisible: boolean) => void;
} & DialogProps;

const CourseCard: React.FC<{ img?: string }> = ({ img }) => {
  return (
    <StyledCard>
      {img ? <img src={img} /> : <i className="pi pi-plus" />}
    </StyledCard>
  );
};

export const CreatePlannerPrompt: React.FC<CreatePlannerPromptProps> = ({
  visible,
  onHide,
  setPlannerType,
  plannerType,
  setPlannerTemplateType,
  setIsPlannerModalVisible,
}) => {
  const account = useAppSelector(selectCurrentAccount);

  const [activeIndex, setActiveIndex] = useState<number>(0);

  const steps = (t: TranslationFunctionType) => [
    {
      label: t('planner.selectType'),
      disabled: false,
    },
    {
      label: t('planner.selectTemplate'),
      disabled: !plannerType,
    },
  ];

  return (
    <StyledDialog
      header={<h1>{t('planner.create.heading')}</h1>}
      blockScroll
      visible={visible}
      onHide={() => {
        onHide();
        setActiveIndex(0);
      }}
      draggable={false}
      dismissableMask
      closeOnEscape
    >
      <Steps
        model={steps(t)}
        activeIndex={activeIndex}
        onSelect={(e) => setActiveIndex(e.index)}
        readOnly={false}
      />

      {activeIndex === 0 && (
        <FlexContainer className="cards">
          <AccountCard
            title={t('account.trainingPlan')}
            content={t('planner.type.trainingPlan.descr', {
              platformName: account?.platformSettings?.name,
            })}
            footer={
              <AppButton
                label={t('generic.select')}
                className="w-full mt-auto"
                type="outlined"
                onClick={() => {
                  setPlannerType(PlannerType.TRAINING_PLAN);
                  setActiveIndex(1);
                }}
              />
            }
          />
          <AccountCard
            title={t('planner.type.onboardingPlan')}
            content={t('planner.type.onboardingPlan.descr')}
            footer={
              <AppButton
                label={t('generic.select')}
                className="w-full mt-auto"
                type="outlined"
                onClick={() => {
                  setPlannerType(PlannerType.ONBOARDING_PLAN);
                  setActiveIndex(1);
                }}
              />
            }
          />
        </FlexContainer>
      )}

      {activeIndex === 1 && (
        <FlexContainer className="cards">
          <AccountCard
            withBackground
            title={t('planner.template.recommended')}
            secondTitle={
              plannerType === PlannerType.TRAINING_PLAN
                ? t('planner.template.training.recommended.subtitle')
                : t('planner.template.onboarding.recommended.subtitle')
            }
            content={
              <FlexContainer className="placeholders-container">
                <CourseCard img={passwordsCourse} />
                <CourseCard img={personalDataCourse} />
                <CourseCard img={workingFromHomeCourse} />
                <CourseCard />
              </FlexContainer>
            }
            footer={
              <AppButton
                label={t('button.create')}
                className="w-full mt-auto"
                severity="secondary"
                onClick={() => {
                  setPlannerTemplateType(PlannerTemplateType.RECOMMENDED);
                  setIsPlannerModalVisible(true);
                  setActiveIndex(0);
                }}
              />
            }
          />
          <AccountCard
            withBackground
            title={t('planner.template.blanк')}
            secondTitle={t('planner.template.blank.subtitle')}
            content={
              <FlexContainer className="placeholders-container">
                <CourseCard />
                <CourseCard />
                <CourseCard />
                <CourseCard />
              </FlexContainer>
            }
            footer={
              <AppButton
                label={t('button.create')}
                className="w-full mt-auto"
                severity="secondary"
                onClick={() => {
                  setPlannerTemplateType(PlannerTemplateType.BLANK);
                  setIsPlannerModalVisible(true);
                  setActiveIndex(0);
                }}
              />
            }
          />
        </FlexContainer>
      )}
    </StyledDialog>
  );
};
