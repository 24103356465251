import { client } from '@/client';
import { MessageResponseModel } from '@/client/models';
import {
  AddUserMutation,
  AddUserRequest,
  GenericForbiddenApiError,
  RemoveUserMutation,
  RemoveUserRequest,
} from '@/hooks/query';
import { useMutation } from 'react-query';

export const useAddUser = (
  type: 'branches' | 'groups',
): {
  add: (payload: AddUserRequest) => Promise<MessageResponseModel>;
} & AddUserMutation => {
  const { mutateAsync, ...rest } = useMutation<
    MessageResponseModel,
    GenericForbiddenApiError,
    AddUserRequest
  >('addUser', (payload: AddUserRequest) =>
    client[type].saveUsers(payload.accountId, payload.typeId, {
      users: payload.userIds,
    }),
  );

  return { add: mutateAsync, ...(rest as any) };
};

export const useRemoveUser = (
  type: 'branches' | 'groups',
): {
  remove: (payload: RemoveUserRequest) => Promise<MessageResponseModel>;
} & RemoveUserMutation => {
  const { mutateAsync, ...rest } = useMutation<
    MessageResponseModel,
    GenericForbiddenApiError,
    RemoveUserRequest
  >('deleteUser', (payload: RemoveUserRequest) =>
    client[type].deleteUser(payload.accountId, payload.typeId, {
      users: payload.userIds,
    }),
  );

  return { remove: mutateAsync, ...(rest as any) };
};
