import { equal, getQueryTransformation } from '@/api/helpers';
import { FiltersType, FilterValue } from '@/api/types';
import {
  Campaign,
  CampaignUserResult,
  CampaignUserResultMessages,
  CampaignUserResultStatusesEnum,
} from '@/client/campaigns';
import {
  FilterNamesEnum,
  getFilters,
  getFiltersFromColumns,
} from '@/client/helpers';
import { UsersReportType } from '@/client/reports';
import { TableNamesEnum } from '@/common/constants';
import { CampaignUserEventsTimeline } from '@/components/campaigns/CampaignUserEventsTimeline';
import { ReportsExportModal } from '@/components/reports/ReportsExportModal';
import {
  DataTable,
  DataTableColumnsMultiselect,
  DataTableColumnType,
  DataTableFilters,
  DataTableToolbar,
  FilterTypeEnum,
} from '@/components/tables/crud';
import {
  useBranchesPartialRequest,
  useCampaignUserResults,
  useGroups,
} from '@/hooks/query';
import { useQueryParams } from '@/hooks/query.hook';
import { useAppSelector } from '@/hooks/store';
import { selectCurrentAccount } from '@/store/features/account';
import { selectCurrentUser } from '@/store/features/users';
import { AppButton } from '@/ui/buttons';
import { FlexContainer } from '@/ui/styled-ui';
import {
  branchAdminCheck,
  getCampaignUserResultStatuses,
} from '@/utils/helpers';
import { getBranchName } from '@/utils/helpers/branches.helper';
import { uniqueId } from 'lodash';
import { DataTablePageEvent } from 'primereact/datatable';
import { Tag } from 'primereact/tag';
import { Tooltip } from 'primereact/tooltip';
import React, { ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

const ErrorTag = styled(Tag)`
  &.p-tag {
    background-color: var(--gray-darker);
  }
`;

type CampaignReportsDatatableProps = {
  shouldRefetch?: boolean;
  onShouldRefetchChange: () => void;
  campaign: Campaign;
  defaultFilters?: FiltersType;
  defaultTableFilters?: Record<string, any>;
  withToolbar?: boolean;
  toolbarAdditional?: ReactNode;
};

const getStatusSeverity = (
  status: CampaignUserResultStatusesEnum,
  t: (key: string) => string,
) => {
  switch (status) {
    case CampaignUserResultStatusesEnum.CAMPAIGN_CREATED:
      return (
        <Tag
          value={t(
            CampaignUserResultMessages[
              CampaignUserResultStatusesEnum.CAMPAIGN_CREATED
            ],
          )}
          severity="success"
        ></Tag>
      );
    case CampaignUserResultStatusesEnum.EMAIL_SENT:
      return (
        <Tag
          value={t(
            CampaignUserResultMessages[
              CampaignUserResultStatusesEnum.EMAIL_SENT
            ],
          )}
          severity="success"
        ></Tag>
      );
    case CampaignUserResultStatusesEnum.EMAIL_OPENED:
      return (
        <Tag
          value={t(
            CampaignUserResultMessages[
              CampaignUserResultStatusesEnum.EMAIL_OPENED
            ],
          )}
          severity="warning"
        ></Tag>
      );
    case CampaignUserResultStatusesEnum.CLICKED_LINK:
      return (
        <Tag
          value={t(
            CampaignUserResultMessages[
              CampaignUserResultStatusesEnum.CLICKED_LINK
            ],
          )}
          className="orange"
        ></Tag>
      );
    case CampaignUserResultStatusesEnum.SUBMITTED_DATA:
      return (
        <Tag
          value={t(
            CampaignUserResultMessages[
              CampaignUserResultStatusesEnum.SUBMITTED_DATA
            ],
          )}
          severity="danger"
        ></Tag>
      );
    case CampaignUserResultStatusesEnum.EMAIL_REPORTED:
      return (
        <Tag
          value={t(
            CampaignUserResultMessages[
              CampaignUserResultStatusesEnum.EMAIL_REPORTED
            ],
          )}
          severity="success"
        ></Tag>
      );
    case CampaignUserResultStatusesEnum.ERROR:
      return (
        <ErrorTag
          value={t(
            CampaignUserResultMessages[CampaignUserResultStatusesEnum.ERROR],
          )}
          className="gray"
        ></ErrorTag>
      );
    case CampaignUserResultStatusesEnum.SCHEDULED:
      return (
        <Tag
          value={t(
            CampaignUserResultMessages[
              CampaignUserResultStatusesEnum.SCHEDULED
            ],
          )}
          severity="info"
        ></Tag>
      );
    case CampaignUserResultStatusesEnum.SENDING:
      return (
        <Tag
          value={t(
            CampaignUserResultMessages[CampaignUserResultStatusesEnum.SENDING],
          )}
          severity="info"
        ></Tag>
      );

    default:
      return null;
  }
};

export const CampaignReportsDatatable: React.FC<
  CampaignReportsDatatableProps
> = ({
  shouldRefetch,
  onShouldRefetchChange,
  campaign,
  defaultFilters = [],
  defaultTableFilters = {},
  withToolbar = false,
  toolbarAdditional,
}) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const currentAccount = useAppSelector(selectCurrentAccount);
  const currentUser = useAppSelector(selectCurrentUser);

  const isBranchAdmin = branchAdminCheck(currentUser, currentAccount);

  const [expandedRows, setExpandedRows] = useState(undefined);
  const [showExportModal, setShowExportModal] = useState(false);

  const [skip, setSkip] = useState<number>(0);
  const [take, setTake] = useState<number>(10);

  const onPage = (event: DataTablePageEvent) => {
    setSkip(event.first);
    setTake(event.rows);
  };

  const { params, setSearchParams } = useQueryParams(
    getQueryTransformation<{ name: string; email: string }>(),
  );
  const [apiFilters, setFilters] = useState<FiltersType>(
    params.filters ? getFilters(params.filters) : [],
  );
  const [filterValues, setFilterValues] = useState<Record<string, any>>(
    params.filters ? params.filters : {},
  );
  const [sort, setSort] = useState<[string, 'asc' | 'desc'] | null>(
    params['sort[]'] ? params['sort[]'] : ['created', 'desc'],
  );

  const { isLoading, campaignUserResults, refetch } = useCampaignUserResults(
    {
      take,
      skip,
      filters: [...apiFilters, ...defaultFilters],
      sort: sort && sort.length > 0 ? [sort.join(',')] : [],
    },
    id,
  );

  const { branches, isLoading: isBranchesLoading } = useBranchesPartialRequest({
    accountId: currentAccount?.id,
    sort: ['name,asc'],
  });

  const { groups, isLoading: isGroupsLoading } = useGroups({
    accountId: currentAccount?.id,
    sort: ['name,asc'],
    enabled: !isBranchAdmin,
  });

  useEffect(() => {
    if (!shouldRefetch) return;

    const doRefetch = async () => {
      await refetch();

      onShouldRefetchChange();
    };

    doRefetch();
  }, [shouldRefetch]);

  const onSort = (event: DataTablePageEvent) => {
    setSkip(0);
    setSort([event.sortField, event.sortOrder === -1 ? 'desc' : 'asc']);
  };

  useEffect(() => {
    setSearchParams({
      filters: filterValues,
      'sort[]': sort,
    });
  }, [JSON.stringify(filterValues), JSON.stringify(sort)]);

  const columns: DataTableColumnType[] = [
    {
      field: 'firstName',
      header: t('generic.name'),
      sortable: true,
      filterable: true,
      filters: {
        field: FilterNamesEnum.NAME,
        type: FilterTypeEnum.TEXT,
        placeholder: t('generic.name.search'),
      },
      render: (row: CampaignUserResult) =>
        row.firstName ? row.firstName : row.email,
    },
    {
      field: 'lastName',
      header: t('generic.lastName'),
      sortable: true,
      filterable: false,
      render: (row: CampaignUserResult) =>
        row.lastName ? row.lastName : <div>&#8212;</div>,
    },
    {
      field: 'email',
      header: t('generic.email'),
      sortable: true,
      filterable: true,
      filters: {
        type: FilterTypeEnum.TEXT,
        placeholder: t('generic.email.search'),
      },
      render: (row: CampaignUserResult) => row.email,
    },
    {
      field: 'username',
      header: t('generic.username'),
      sortable: false,
      filterable: true,
      filters: {
        field: FilterNamesEnum.USER_BY_USERNAME_NESTED,
        type: FilterTypeEnum.TEXT,
        placeholder: t('filter.username'),
      },
      render: (row: CampaignUserResult) =>
        row.user?.username ? row.user.username : <span>&#8212;</span>,
    },
    {
      field: 'branch',
      header: t('branch'),
      sortable: false,
      filterable: true,
      filters: {
        placeholder: t('filter.branch'),
        field: FilterNamesEnum.SUMMARY_REPORTS_BRANCH,
        type: FilterTypeEnum.MULTI_SELECT,
        options: branches?.map(({ id, name }) => ({
          label: name,
          value: id,
        })),
      },
      render: (row: CampaignUserResult) => {
        const id = uniqueId('branch');
        return (
          <>
            {row?.user?.branch ? (
              <>
                {row?.user?.branch && (
                  <Tooltip
                    position="mouse"
                    target={`.${id}`}
                    content={getBranchName(row.user.branch)
                      .reverse()
                      .join(' / ')}
                  />
                )}
                <div className={id}>{row?.user?.branch?.name}</div>
              </>
            ) : (
              <div className="group-row">&#8212;</div>
            )}
          </>
        );
      },
    },
    ...(!isBranchAdmin
      ? [
          {
            field: 'group',
            header: t('group'),
            sortable: false,
            filterable: true,
            filters: {
              placeholder: t('filter.group'),
              field: FilterNamesEnum.SUMMARY_REPORTS_GROUP,
              type: FilterTypeEnum.MULTI_SELECT,
              options: groups?.result?.map(({ id, name }) => ({
                label: name,
                value: id,
              })),
            },
            className: 'no-padding',
            render: (row: CampaignUserResult) => {
              const id = uniqueId('group');
              return (
                <>
                  {row?.user?.groups.length ? (
                    row.user.groups.map((group) => (
                      <div key={group.id} style={{ minWidth: 'max-content' }}>
                        {group.name}
                      </div>
                    ))
                  ) : (
                    <div className="group-row">&#8212;</div>
                  )}
                </>
              );
            },
          },
        ]
      : []),
    {
      field: 'companyName',
      header: t('generic.companyName'),
      sortable: false,
      filterable: true,
      filters: {
        field: FilterNamesEnum.SUMMARY_REPORTS_COMPANY_NAME,
        type: FilterTypeEnum.TEXT,
        placeholder: t('filter.companyName'),
      },
      render: (row: CampaignUserResult) =>
        row.user?.meta?.companyName ? (
          row.user?.meta?.companyName
        ) : (
          <span>&#8212;</span>
        ),
    },
    {
      field: 'department',
      header: t('generic.department'),
      sortable: false,
      filterable: true,
      filters: {
        field: FilterNamesEnum.SUMMARY_REPORTS_DEPARTMENT,
        type: FilterTypeEnum.TEXT,
        placeholder: t('filter.department'),
      },
      render: (row: CampaignUserResult) =>
        row.user?.meta?.department ? (
          row.user?.meta?.department
        ) : (
          <span>&#8212;</span>
        ),
    },
    {
      field: 'manager',
      header: t('generic.manager'),
      sortable: false,
      filterable: true,
      filters: {
        field: FilterNamesEnum.SUMMARY_REPORTS_MANAGER,
        type: FilterTypeEnum.TEXT,
        placeholder: t('filter.manager'),
      },
      render: (row: CampaignUserResult) =>
        row.user?.meta?.manager ? (
          row.user?.meta?.manager
        ) : (
          <span>&#8212;</span>
        ),
    },
    {
      field: 'country',
      header: t('generic.country'),
      sortable: false,
      filterable: true,
      filters: {
        field: FilterNamesEnum.SUMMARY_REPORTS_COUNTRY,
        type: FilterTypeEnum.TEXT,
        placeholder: t('filter.country'),
      },
      render: (row: CampaignUserResult) =>
        row.user?.meta?.country ? (
          row.user?.meta?.country
        ) : (
          <span>&#8212;</span>
        ),
    },
    {
      field: 'jobTitle',
      header: t('generic.jobTitle'),
      sortable: false,
      filterable: true,
      filters: {
        field: FilterNamesEnum.SUMMARY_REPORTS_JOB,
        type: FilterTypeEnum.TEXT,
        placeholder: t('filter.jobTitle'),
      },
      render: (row: CampaignUserResult) =>
        row.user?.meta?.jobTitle ? (
          row.user?.meta?.jobTitle
        ) : (
          <span>&#8212;</span>
        ),
    },
    {
      field: 'mobilePhone',
      header: t('generic.mobilePhone'),
      sortable: false,
      filterable: true,
      filters: {
        field: FilterNamesEnum.SUMMARY_REPORTS_MOBILE_PHONE,
        type: FilterTypeEnum.TEXT,
        placeholder: t('filter.mobilePhone'),
      },
      render: (row: CampaignUserResult) =>
        row.user?.meta?.mobilePhone ? (
          row.user?.meta?.mobilePhone
        ) : (
          <span>&#8212;</span>
        ),
    },
    {
      field: 'officeLocation',
      header: t('generic.officeLocation'),
      sortable: false,
      filterable: true,
      filters: {
        field: FilterNamesEnum.SUMMARY_REPORTS_OFFICE_LOCATION,
        type: FilterTypeEnum.TEXT,
        placeholder: t('filter.officeLocation'),
      },
      render: (row: CampaignUserResult) =>
        row.user?.meta?.officeLocation ? (
          row.user?.meta?.officeLocation
        ) : (
          <span>&#8212;</span>
        ),
    },
    {
      field: 'status',
      header: t('generic.status'),
      sortable: true,
      filterable: true,
      filters: {
        field: FilterNamesEnum.CAMPAIGNS_REPORT_BY_STATUS,
        type: FilterTypeEnum.MULTI_SELECT,
        placeholder: t('generic.status.search'),
        options: getCampaignUserResultStatuses(
          t,
          currentAccount?.meta?.showCampaignOpenEvents,
        ),
      },
      render: (row: CampaignUserResult) => getStatusSeverity(row.status, t),
    },
    { field: 'spacer', header: '', sortable: false, filterable: false },
  ];

  // Set the preselected columns
  const [visibleColumns, setVisibleColumns] = useState<string[]>([]);
  const defaultVisibleColumns = columns
    .filter(
      (column) =>
        ![
          'companyName',
          'country',
          'officeLocation',
          'department',
          'manager',
          'jobTitle',
          'mobilePhone',
        ].includes(column.field),
    )
    .map((column) => column.field);
  const alwaysVisibleColumns = ['email', 'spacer'];
  //

  const onFilter = (values: Record<FilterNamesEnum, FilterValue>) => {
    setSkip(0);
    setFilterValues(values);
    setFilters(getFilters(values));
  };

  const toolbar = (
    <DataTableToolbar>
      <FlexContainer
        justify="space-between"
        gap={8}
        align="flex-start"
        wrap="wrap"
      >
        <DataTableFilters
          filters={getFiltersFromColumns(columns)}
          onFilter={onFilter}
          defaultValues={defaultTableFilters}
          tableName={TableNamesEnum.CAMPAIGN_REPORTS}
        />
        {toolbarAdditional}
      </FlexContainer>

      <FlexContainer gap={12} justify="flex-end">
        <AppButton
          icon="pi pi-download"
          label={t('generic.export')}
          onClick={() => setShowExportModal(true)}
        />
      </FlexContainer>

      <ReportsExportModal
        filters={apiFilters}
        type={UsersReportType.PHISHING}
        visible={showExportModal}
        onHide={() => setShowExportModal(false)}
        campaign={campaign}
      />

      <DataTableColumnsMultiselect
        columns={columns}
        tableName={TableNamesEnum.CAMPAIGN_REPORTS}
        visibleColumns={visibleColumns}
        setVisibleColumns={setVisibleColumns}
        defaultVisibleColumns={defaultVisibleColumns}
        alwaysVisibleColumns={alwaysVisibleColumns}
      />
    </DataTableToolbar>
  );

  const rowExpansionTemplate = (data: CampaignUserResult) => (
    <CampaignUserEventsTimeline
      userResults={data}
      status={data.status}
      campaignCreationDate={campaign?.created}
    />
  );

  return (
    <DataTable
      data={campaignUserResults?.result}
      count={campaignUserResults?.count}
      isLoading={isLoading || isBranchesLoading || isGroupsLoading}
      toolbar={withToolbar ? toolbar : null}
      columns={columns}
      visibleColumns={visibleColumns}
      rows={take}
      skip={skip}
      onPage={onPage}
      onSort={onSort}
      sort={sort}
      expandedRows={expandedRows}
      onRowToggle={(e: any) => {
        setExpandedRows(e.data);
      }}
      rowExpansionTemplate={rowExpansionTemplate}
      allowExpansion
    />
  );
};
