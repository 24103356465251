import { processListingParams } from '@/api/helpers';
import { getExceptionFromAxiosError } from '@/client/helpers';
import { MessageResponseModel } from '@/client/models';
import {
  ListSenderProfilesRequest,
  ListSenderProfilesResponse,
  SaveSenderProfileRequest,
  SendingProfile,
  UpdateSenderProfileRequest,
} from '@/client/sender-profiles';
import { AxiosError, AxiosInstance, AxiosResponse } from 'axios';

export const senderProfilesService = (client: AxiosInstance) => {
  const SENDER_PROFILES_BASE_URL = '/gophish/sending-profiles';

  const getSenderProfiles = async (
    params: ListSenderProfilesRequest,
  ): Promise<ListSenderProfilesResponse> => {
    try {
      const result = await client.get<
        ListSenderProfilesResponse,
        AxiosResponse<ListSenderProfilesResponse>
      >(SENDER_PROFILES_BASE_URL, {
        withCredentials: true,
        params: processListingParams(params),
      });

      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const getSenderProfile = async (
    senderProfileId?: string,
  ): Promise<SendingProfile> => {
    try {
      const result = await client.get<
        SendingProfile,
        AxiosResponse<SendingProfile>
      >(`${SENDER_PROFILES_BASE_URL}/${senderProfileId}`, {
        withCredentials: true,
      });

      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const saveSenderProfile = async (
    request: SaveSenderProfileRequest,
  ): Promise<SendingProfile> => {
    try {
      const result = await client.post<
        SendingProfile,
        AxiosResponse<SendingProfile>
      >(SENDER_PROFILES_BASE_URL, request, {
        withCredentials: true,
      });

      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const updateSenderProfile = async (
    request: UpdateSenderProfileRequest,
  ): Promise<SendingProfile> => {
    try {
      const result = await client.patch<
        SendingProfile,
        AxiosResponse<SendingProfile>
      >(
        `${SENDER_PROFILES_BASE_URL}/${request.senderProfileId}`,
        request.updates,
        {
          withCredentials: true,
        },
      );

      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const deleteSenderProfile = async (
    senderProfileId: string,
  ): Promise<MessageResponseModel> => {
    try {
      const result = await client.delete<
        MessageResponseModel,
        AxiosResponse<MessageResponseModel>
      >(`${SENDER_PROFILES_BASE_URL}/${senderProfileId}`, {
        withCredentials: true,
      });

      return new MessageResponseModel(result.data);
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const senderProfilesJobNotifyUrl = (jobId: string) =>
    `${client.defaults.baseURL}gophish/sending-profiles/notify/${jobId}`;

  return {
    getSenderProfiles,
    getSenderProfile,
    saveSenderProfile,
    updateSenderProfile,
    senderProfilesJobNotifyUrl,
    deleteSenderProfile,
  };
};
