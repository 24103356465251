import classNames from 'classnames';
import { Tag, TagProps } from 'primereact/tag';
import React from 'react';
import styled from 'styled-components';

const StyledAppTag = styled(Tag)`
  font-size: var(--xxsmall-font-size: 10px;);
  line-height: 1;
  border-radius: var(--small-border-radius);
  padding: 4px var(--small-padding);
  text-transform: uppercase;

  &.tag-primary {
    color: var(--white-main);
    background: var(--green-main);
  }

  &.tag-secondary {
    color: var(--white-main);
    background: var(--gray-darker);
  }

  &.tag-error {
    color: var(--white-main);
    background: var(--red-main);
  }
`;

type AppTagProps = {
  value: string | undefined;
  type: 'primary' | 'secondary' | 'error';
  className?: string;
};

const AppTag: React.FC<AppTagProps & TagProps & React.HTMLAttributes<any>> = ({
  value,
  type,
  className,
  ...rest
}) => {
  const styles = classNames(
    {
      [`tag-${type}`]: type,
    },
    className,
  );

  return <StyledAppTag value={value} className={styles} {...rest} />;
};

export default AppTag;
