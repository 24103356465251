import { handleAxiosError } from '@/api/helpers';
import { FiltersType } from '@/api/types';
import { getFiltersFromColumns } from '@/client/helpers';
import { SendingProfile } from '@/client/sender-profiles';
import { Actions, Subjects } from '@/client/users';
import {
  RedirectPaths,
  RedirectPathsEnum,
  TableNamesEnum,
} from '@/common/constants';
import { DialogContext } from '@/common/context';
import {
  DataTable,
  DataTableActions,
  DataTableColumnsMultiselect,
  DataTableColumnType,
  DataTableFilters,
  DataTableToolbar,
  FilterTypeEnum,
} from '@/components/tables/crud';
import { useDeleteSenderProfile, useSenderProfiles } from '@/hooks/query';
import { useAppSelector } from '@/hooks/store';
import { useTable } from '@/hooks/table.hook';
import { usePermission } from '@/hooks/usePermission';
import { useToast } from '@/hooks/useToast';
import { selectCurrentAccount } from '@/store/features/account';
import { AppButton } from '@/ui/buttons';
import { FormatDate } from '@/ui/date';
import { FlexContainer } from '@/ui/styled-ui';
import { AxiosError } from 'axios';
import { MenuItem } from 'primereact/menuitem';
import React, { ReactNode, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

type SenderProfilesDatatableProps = {
  defaultFilters?: FiltersType;
  withActions?: boolean;
  withToolbar?: boolean;
  withAddNew?: boolean;
  toolbarAdditional?: ReactNode;
  filterByPredefined?: boolean;
};

export const SenderProfilesDatatable: React.FC<
  SenderProfilesDatatableProps
> = ({
  defaultFilters = [],
  withActions = false,
  withToolbar = false,
  withAddNew = false,
  toolbarAdditional,
  filterByPredefined,
}) => {
  const { t } = useTranslation();
  const toast = useToast();
  const account = useAppSelector(selectCurrentAccount);
  const { can } = usePermission();
  const { skip, take, sort, apiFilters, onFilter, onSort, onPage } = useTable();

  const { setDialogData } = useContext(DialogContext);

  const { isLoading, senderProfiles, refetch } = useSenderProfiles({
    take,
    skip,
    filters: [...apiFilters, ...defaultFilters],
    sort: sort && sort.length > 0 ? [sort.join(',')] : [],
  });

  const deleteSenderProfile = useDeleteSenderProfile();

  const navigate = useNavigate();

  let columns: DataTableColumnType[] = [
    {
      field: 'name',
      header: t('generic.name'),
      sortable: true,
      filterable: true,
      filters: {
        type: FilterTypeEnum.TEXT,
        placeholder: t('generic.name.search'),
      },
      render: (row: SendingProfile) => row.name,
    },
    {
      field: 'interface_type',
      header: t('sender.type'),
      sortable: false,
      filterable: false,
      render: (row: SendingProfile) => row.interfaceType,
    },
    {
      field: 'updated',
      header: t('generic.updated'),
      sortable: true,
      filterable: false,
      render: (row: SendingProfile) =>
        row?.updated ? <FormatDate date={row?.updated} /> : t('generic.never'),
    },
  ];

  if (withActions) {
    columns = [
      ...columns,
      {
        field: 'actions',
        header: t('generic.actions'),
        sortable: false,
        filterable: false,
        style: {
          width: '80px',
          textAlign: 'center',
        },
        render: (row: SendingProfile) => (
          <DataTableActions
            disabled={menuItems(row).length < 1}
            menuItems={menuItems(row)}
          />
        ),
      },
    ];
  }

  // Set the preselected columns
  const [visibleColumns, setVisibleColumns] = useState<string[]>([]);
  const defaultVisibleColumns = columns.map((column) => column.field);
  const alwaysVisibleColumns = ['name', 'actions'];
  //

  const menuItems = (senderProfile: SendingProfile) => {
    const actions: MenuItem[] = [];

    if (filterByPredefined && account?.isSystem) {
      if (can(Actions.CREATE, Subjects.SENDING_PROFILES)) {
        actions.push({
          label: t('generic.copyEdit'),
          icon: 'pi pi-copy',
          command: () => {
            navigate(
              RedirectPaths[RedirectPathsEnum.SENDER_PROFILES_CREATE](),
              {
                state: { senderProfile },
              },
            );
          },
        });
      }
      if (can(Actions.UPDATE, Subjects.SENDING_PROFILES)) {
        actions.push({
          label: t('generic.edit'),
          icon: 'pi pi-pencil',
          command: () => {
            navigate(
              RedirectPaths[RedirectPathsEnum.SENDER_PROFILES_EDIT](
                senderProfile.id,
              ),
            );
          },
        });
      }
      if (can(Actions.DELETE, Subjects.SENDING_PROFILES)) {
        actions.push({
          label: t('generic.delete'),
          icon: 'pi pi-times',
          command: () =>
            setDialogData({
              type: 'confirmation',
              show: true,
              header: t('dialog.delete'),
              message: t('dialog.delete.profile'),
              onAccept: async () =>
                await handleDeleteSenderProfile(senderProfile.id),
            }),
        });
      }
    } else if (filterByPredefined && !account?.isSystem) {
      if (can(Actions.CREATE, Subjects.SENDING_PROFILES)) {
        actions.push({
          label: t('generic.copyEdit'),
          icon: 'pi pi-copy',
          command: () => {
            navigate(
              RedirectPaths[RedirectPathsEnum.SENDER_PROFILES_CREATE](),
              {
                state: { senderProfile },
              },
            );
          },
        });
      }
    } else {
      if (can(Actions.CREATE, Subjects.SENDING_PROFILES)) {
        actions.push({
          label: t('generic.copyEdit'),
          icon: 'pi pi-copy',
          command: () => {
            navigate(
              RedirectPaths[RedirectPathsEnum.SENDER_PROFILES_CREATE](),
              {
                state: { senderProfile },
              },
            );
          },
        });
      }
      if (can(Actions.UPDATE, Subjects.SENDING_PROFILES)) {
        actions.push({
          label: t('generic.edit'),
          icon: 'pi pi-pencil',
          command: () => {
            navigate(
              RedirectPaths[RedirectPathsEnum.SENDER_PROFILES_EDIT](
                senderProfile.id,
              ),
            );
          },
        });
      }

      if (can(Actions.DELETE, Subjects.SENDING_PROFILES)) {
        actions.push({
          label: t('generic.delete'),
          icon: 'pi pi-times',
          command: () =>
            setDialogData({
              type: 'confirmation',
              show: true,
              header: t('dialog.delete'),
              message: t('dialog.delete.profile'),
              onAccept: async () =>
                await handleDeleteSenderProfile(senderProfile.id),
            }),
        });
      }
    }

    return actions;
  };

  const handleDeleteSenderProfile = async (senderProfileId: string) => {
    try {
      await deleteSenderProfile.delete(senderProfileId);
      setTimeout(() => {
        refetch();
      }, 100);
      toast?.success(t('toast.success'), t('sender.deleted'));
    } catch (e) {
      handleAxiosError(e as Error | AxiosError, toast);
    }
  };

  const toolbar = (
    <DataTableToolbar>
      <FlexContainer
        justify="space-between"
        gap={8}
        align="flex-start"
        wrap="wrap"
      >
        <DataTableFilters
          filters={getFiltersFromColumns(columns)}
          onFilter={onFilter}
          tableName={TableNamesEnum.SENDER_PROFILES_LIST}
        />
        <FlexContainer width="auto" gap={8}>
          {withAddNew && (
            <AppButton
              label={t('button.createNew')}
              severity="secondary"
              onClick={() => {
                navigate(
                  RedirectPaths[RedirectPathsEnum.SENDER_PROFILES_CREATE](),
                );
              }}
            />
          )}
          {toolbarAdditional}
        </FlexContainer>
      </FlexContainer>
      <DataTableColumnsMultiselect
        columns={columns}
        tableName={TableNamesEnum.SENDER_PROFILES_LIST}
        visibleColumns={visibleColumns}
        setVisibleColumns={setVisibleColumns}
        defaultVisibleColumns={defaultVisibleColumns}
        alwaysVisibleColumns={alwaysVisibleColumns}
      />
    </DataTableToolbar>
  );

  return (
    <>
      <DataTable
        data={senderProfiles?.result}
        count={senderProfiles?.count}
        isLoading={isLoading}
        toolbar={withToolbar ? toolbar : null}
        columns={columns}
        visibleColumns={visibleColumns}
        rows={take}
        skip={skip}
        onPage={onPage}
        onSort={onSort}
        sort={sort}
      />
    </>
  );
};
