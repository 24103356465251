import { RedirectPaths, RedirectPathsEnum } from '@/common/constants';
import classNames from 'classnames';
import { MenuItem } from 'primereact/menuitem';
import { TabMenu } from 'primereact/tabmenu';
import React, { HTMLAttributes, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

const getItemTemplate = (url: string, current: string) => (item: MenuItem) =>
  (
    <Link
      to={url}
      className={classNames({
        'p-menuitem-link': true,
        'p-menuitem-link-active': current.includes(url),
      })}
      role="menuitem"
    >
      <span className="p-menuitem-text">{item.label}</span>
    </Link>
  );

export const SystemEmailsTabs: React.FC<HTMLAttributes<HTMLUListElement>> = ({
  className,
}) => {
  const { t } = useTranslation();
  const location = useLocation();

  const menuItems = () => {
    const items: MenuItem[] = [
      {
        label: t('generic.email.templates'),
        template: getItemTemplate(
          RedirectPaths[RedirectPathsEnum.SYSTEM_EMAILS_TEMPLATES](),
          location.pathname,
        ),
        data: RedirectPaths[RedirectPathsEnum.SYSTEM_EMAILS_TEMPLATES](),
      },
      {
        label: t('generic.sending.configs'),
        template: getItemTemplate(
          RedirectPaths[RedirectPathsEnum.SYSTEM_EMAILS_SENDING_CONFIGS](),
          location.pathname,
        ),
        data: RedirectPaths[RedirectPathsEnum.SYSTEM_EMAILS_SENDING_CONFIGS](),
      },
      {
        label: t('generic.email.configs'),
        template: getItemTemplate(
          RedirectPaths[RedirectPathsEnum.SYSTEM_EMAILS_TYPES_CONFIGS](),
          location.pathname,
        ),
        data: RedirectPaths[RedirectPathsEnum.SYSTEM_EMAILS_TYPES_CONFIGS](),
      },
    ];

    return items;
  };

  const items = menuItems();
  const paths = items.map((item) => item.data);
  const currentPath = location.pathname;
  const [activeIndex, setActiveIndex] = useState<number>(
    paths.indexOf(currentPath),
  );

  return (
    <TabMenu
      className={className}
      model={items}
      activeIndex={activeIndex}
      onTabChange={(e) => setActiveIndex(e.index)}
    />
  );
};
