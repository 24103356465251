import { FiltersType, ListingRequest } from '@/api/types';
import { client } from '@/client';
import { Group } from '@/client/groups';
import { PartialRequestMultiselect } from '@/components/form/generic/PartialRequestMultiselect';
import { useAppSelector } from '@/hooks/store';
import { selectCurrentAccount } from '@/store/features/account';
import React, { HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';

type GroupsMultiselectInputProps = {
  onChange: (value: Group[]) => void;
  onBlur?: () => void;
  additionalFilters?: FiltersType;
  selectedOptions?: Group[];
  isDisabled?: boolean;
  courseId?: string;
  accountId?: string;
} & Omit<HTMLAttributes<HTMLSelectElement>, 'onChange'>;

export const GroupsMultiselectInput: React.FC<GroupsMultiselectInputProps> = ({
  onChange,
  onBlur,
  className,
  additionalFilters,
  selectedOptions,
  isDisabled,
  courseId,
  accountId,
}) => {
  const { t } = useTranslation();
  const currentAccount = useAppSelector(selectCurrentAccount);
  const fetchGroups = isDisabled
    ? undefined
    : async (params: ListingRequest) =>
        courseId
          ? await client.courses.getCourseGroups(
              params,
              courseId,
              accountId ?? currentAccount?.id,
            )
          : await client.groups.getGroups(params, currentAccount?.id);

  return (
    <PartialRequestMultiselect
      fetchService={fetchGroups}
      filter
      display="chip"
      optionLabel="name"
      optionValue="value"
      placeholder={t('group.select')}
      maxSelectedLabels={10}
      additionalFilters={additionalFilters}
      selectedOptions={selectedOptions}
      className={className}
      handleOnChange={onChange}
      onBlur={onBlur}
      disabled={isDisabled}
    />
  );
};
