import { languageDisplayCode, LanguagesEnum } from '@/api/enums';
import { handleAxiosError } from '@/api/helpers';
import { client } from '@/client';
import { Course } from '@/client/courses';
import { useAppSelector } from '@/hooks/store';
import { useToast } from '@/hooks/useToast';
import PagePlaceholderImage from '@/images/placeholders/page-template.svg';
import { selectCurrentUser } from '@/store/features/users';
import { AppButton } from '@/ui/buttons';
import { isUserLanguageAvailableinMaterial } from '@/utils/helpers';
import { AxiosError } from 'axios';
import classNames from 'classnames';
import { escapeRegExp } from 'lodash';
import { Dropdown } from 'primereact/dropdown';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { FlexContainer } from '../styled-ui';

const StyledImageCard = styled.div`
  &.poster-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--gray-dark);
    border-radius: var(--small-border-radius);
    padding: var(--default-padding);
    overflow: hidden;

    &.disabled {
      opacity: 0.6;
      pointer-events: none !important;
    }

    .thumbnail-container {
      width: 100%;
      max-width: 270px;
      height: 384px;
      position: relative;
      display: flex;
      align-items: stretch;
      overflow: hidden;
      margin-bottom: var(--default-padding);

      .image-card-thumbnail {
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        background-color: var(--gray-main);
      }
    }
  }
`;

const StyledAppButton = styled(AppButton)`
  &.p-button {
    padding: 0 var(--default-padding);
    border-radius: var(--default-border-radius);

    .p-button-icon {
      font-size: var(--small-font-size);
    }
  }
`;

const StyledDropdown = styled(Dropdown)`
  .p-dropdown-label {
    padding-right: 0;
  }
`;

export type PosterCardProps = {
  poster: Course;
  additionalClass?: string;
  isDisabled?: boolean;
};

export const PosterCard: React.FC<PosterCardProps> = ({
  poster,
  additionalClass,
  isDisabled,
  ...rest
}) => {
  const { t } = useTranslation();
  const toast = useToast();
  const currentUser = useAppSelector(selectCurrentUser);

  const [selectedLanguage, setSelectedLanguage] = useState<LanguagesEnum>(
    currentUser?.language &&
      isUserLanguageAvailableinMaterial(
        poster.courseLanguages,
        currentUser?.language as LanguagesEnum,
      )
      ? (currentUser?.language as LanguagesEnum)
      : LanguagesEnum.EN,
  );

  const className = classNames(
    'poster-card',
    {
      disabled: isDisabled,
    },
    additionalClass,
  );

  const downloadHandler = async (
    templateId: string,
    selectedLanguage: LanguagesEnum,
  ) => {
    try {
      const courseFile = await client.files.getCourseFile(
        templateId,
        selectedLanguage,
      );

      if (courseFile.file) {
        const link = document.createElement('a');
        link.href = courseFile.file.signedUrl;
        link.setAttribute('download', courseFile.file.fileName);

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        toast?.success(t('toast.success'), t('material.download.success'));
        // Clean up and remove the link
        link.parentNode && link.parentNode.removeChild(link);
      }
    } catch (e) {
      handleAxiosError(e as Error | AxiosError, toast);
    }
  };

  return (
    <StyledImageCard id={poster.id} className={className} {...rest}>
      <div className="thumbnail-container">
        <label
          className="image-card-thumbnail flex-1"
          style={{
            backgroundImage: `url(${escapeRegExp(
              poster.courseThumbnail?.signedUrl ?? PagePlaceholderImage,
            )}`,
          }}
        />
      </div>

      <FlexContainer gap={12}>
        <StyledDropdown
          value={selectedLanguage}
          onChange={(e) => setSelectedLanguage(e.value)}
          options={poster.courseLanguages.map((x) => ({
            label: languageDisplayCode[x],
            value: x,
          }))}
          placeholder={t('materials.selectLanguage')}
        />
        <StyledAppButton
          icon="pi pi-download"
          size="sm"
          label={t('generic.download')}
          severity={'secondary'}
          onClick={() => downloadHandler(poster.id, selectedLanguage)}
        />
      </FlexContainer>
    </StyledImageCard>
  );
};
