import { FiltersType, ListingRequest } from '@/api/types';
import { client } from '@/client';
import { Branch } from '@/client/branches';
import { PartialRequestMultiselect } from '@/components/form/generic/PartialRequestMultiselect';
import { useAppSelector } from '@/hooks/store';
import { selectCurrentAccount } from '@/store/features/account';
import React, { HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';

type BranchMultiselectInputProps = {
  onChange: (value: Branch[]) => void;
  onBlur?: () => void;
  additionalFilters?: FiltersType;
  selectedOptions?: Branch[];
  isDisabled?: boolean;
  courseId?: string;
  accountId?: string;
} & Omit<HTMLAttributes<HTMLSelectElement>, 'onChange' | 'defaultChecked'>;

export const BranchesMultiselectInput: React.FC<
  BranchMultiselectInputProps
> = ({
  onChange,
  onBlur,
  className,
  additionalFilters,
  selectedOptions,
  isDisabled,
  courseId,
  accountId,
}) => {
  const { t } = useTranslation();
  const currentAccount = useAppSelector(selectCurrentAccount);
  const fetchBranches = isDisabled
    ? undefined
    : async (params: ListingRequest) =>
        courseId
          ? await client.courses.getCourseBranches(
              params,
              courseId,
              accountId ?? currentAccount?.id,
            )
          : await client.branches.getBranches(params, currentAccount?.id);

  return (
    <PartialRequestMultiselect
      fetchService={fetchBranches}
      filter
      display="chip"
      optionLabel="name"
      optionValue="value"
      placeholder={t('branch.select')}
      maxSelectedLabels={10}
      additionalFilters={additionalFilters}
      selectedOptions={selectedOptions}
      className={className}
      handleOnChange={onChange}
      onBlur={onBlur}
      disabled={isDisabled}
    />
  );
};
