import { AppBreadCrumbTemplate } from '@/app/AppBreadCrumbTemplate';
import { Account } from '@/client/accounts';
import {
  getExceptionFromAxiosError,
  getFiltersFromColumns,
} from '@/client/helpers';
import { Reseller } from '@/client/resellers';
import { Actions, Subjects, SystemRoles } from '@/client/users';
import {
  RedirectPaths,
  RedirectPathsEnum,
  TableNamesEnum,
} from '@/common/constants';
import { DialogContext } from '@/common/context';
import { TranslationFunctionType } from '@/common/types';
import {
  DataTable,
  DataTableColumnsMultiselect,
  DataTableColumnType,
  DataTableFilters,
  DataTableToolbar,
} from '@/components/tables/crud';
import { DataTableActions } from '@/components/tables/crud/DataTableActions';
import { FilterTypeEnum } from '@/components/tables/crud/types';
import { useDeleteReseller, useResellers } from '@/hooks/query';
import { useAppSelector } from '@/hooks/store';
import { useTable } from '@/hooks/table.hook';
import { usePermission } from '@/hooks/usePermission';
import { useToast } from '@/hooks/useToast';
import { selectCurrentAccount } from '@/store/features/account';
import { selectCurrentUser } from '@/store/features/users';
import { AppBreadCrumb } from '@/ui/breadcrumb';
import { AppButton } from '@/ui/buttons';
import { FlexContainer } from '@/ui/styled-ui';
import { keycloakThemeOptions } from '@/utils/helpers';
import { AxiosError } from 'axios';
import { DataTableRowClickEvent } from 'primereact/datatable';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { ResellerTabs } from './ResellerTabs';

const StyledFlexContainer = styled(FlexContainer)`
  .reseller-logo {
    display: flex;
    flex-shrink: 0;
    width: 140px;
    height: 70px;
    background-color: var(--purple-dark);
    padding: var(--small-padding);
    overflow: hidden;
    border-radius: 4px;
    img {
      align-self: center;
      width: 100%;
      height: auto;
      object-fit: cover;
    }
  }

  .reseller-name {
    align-self: center;
  }
`;

const getpathItems = (currentAccount: Account, t: TranslationFunctionType) => [
  {
    label: currentAccount?.name,
    url: RedirectPaths[RedirectPathsEnum.EDIT_ACCOUNT](
      (currentAccount as Account)?.id,
    ),
    template: AppBreadCrumbTemplate,
  },
  {
    label: t('resellers'),
    url: RedirectPaths[RedirectPathsEnum.RESELLERS](),
    template: AppBreadCrumbTemplate,
  },
];

export const ResellersPage = () => {
  const { t } = useTranslation();
  const { can } = usePermission();
  const currentAccount = useAppSelector(selectCurrentAccount);
  const currentUser = useAppSelector(selectCurrentUser);
  const navigate = useNavigate();
  const toast = useToast();
  const { skip, take, sort, apiFilters, onPage, onSort, onFilter } = useTable();

  const { setDialogData } = useContext(DialogContext);

  const { isLoading, resellers, refetch } = useResellers({
    take,
    skip,
    filters: apiFilters,
    sort: sort && sort.length > 0 ? [sort.join(',')] : [],
  });

  const deleteReseller = useDeleteReseller();

  const handleDeleteReseller = async (resellerId: string) => {
    try {
      await deleteReseller.delete(resellerId);
      toast?.success(t('toast.success'), t('reseller.deleted'));
      refetch();
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const onRowClick = (event: DataTableRowClickEvent) => {
    navigate(RedirectPaths[RedirectPathsEnum.EDIT_RESELLERS](event?.data?.id));
  };

  const columns: DataTableColumnType[] = [
    {
      field: 'name',
      header: t('generic.name'),
      sortable: true,
      filterable: true,
      filters: { type: FilterTypeEnum.TEXT },
      render: (row: Reseller) => {
        return (
          <StyledFlexContainer gap={12} justify="flex-start">
            {row?.logo && (
              <div className="reseller-logo">
                <img src={row?.logo?.signedUrl} alt={row?.logo?.fileName} />
              </div>
            )}
            <span className="reseller-name">{row?.name}</span>
          </StyledFlexContainer>
        );
      },
    },
    {
      field: 'id',
      header: t('generic.id'),
      sortable: false,
      filterable: true,
      filters: { type: FilterTypeEnum.TEXT },
    },
    {
      field: 'baseUrl',
      header: t('reseller.baseUrl'),
      sortable: false,
      filterable: true,
      filters: { type: FilterTypeEnum.TEXT },
      render: (row: Reseller) => row.baseUrl,
    },
    {
      field: 'theme',
      header: t('reseller.theme'),
      sortable: false,
      filterable: true,
      filters: {
        type: FilterTypeEnum.MULTI_SELECT,
        options: keycloakThemeOptions,
      },
      render: (row: Reseller) => row.theme,
    },
    {
      field: 'email',
      header: t('generic.email'),
      sortable: false,
      filterable: true,
      filters: { type: FilterTypeEnum.TEXT },
      render: (row: Reseller) => row.email,
    },
    {
      field: 'phone',
      header: t('generic.phone'),
      sortable: false,
      filterable: true,
      filters: {
        type: FilterTypeEnum.TEXT,
      },
      render: (row: Reseller) => row.phone,
    },
    {
      field: 'actions',
      header: t('generic.actions'),
      sortable: false,
      filterable: false,
      style: {
        width: '80px',
        textAlign: 'center',
      },
      render: (row: Reseller) => (
        <>
          <DataTableActions
            disabled={menuItems(row).length < 1}
            menuItems={menuItems(row)}
          />
        </>
      ),
    },
  ];

  // Set the preselected columns
  const [visibleColumns, setVisibleColumns] = useState<string[]>([]);
  const defaultVisibleColumns = columns
    .filter((column) => column.field !== 'id')
    .map((column) => column.field);
  const alwaysVisibleColumns = ['name', 'actions'];
  //

  const menuItems = (reseller: Reseller) => {
    const actions = [];

    if (can(Actions.READ, Subjects.RESELLERS)) {
      actions.push({
        label: t('accounts'),
        icon: 'pi pi-users',
        command: () =>
          navigate(
            RedirectPaths[RedirectPathsEnum.RESELLERS_ACCOUNTS](reseller.id),
          ),
      });
    }

    if (can(Actions.UPDATE, Subjects.RESELLERS)) {
      actions.push({
        label: t('generic.edit'),
        icon: 'pi pi-pencil',
        command: () =>
          navigate(
            RedirectPaths[RedirectPathsEnum.EDIT_RESELLERS](reseller.id),
          ),
      });
    }

    if (
      can(Actions.DELETE, Subjects.RESELLERS) &&
      (!currentAccount?.isSystem ||
        currentUser?.role.code === SystemRoles.DEVELOPER)
    ) {
      actions.push({
        label: t('generic.delete'),
        icon: 'pi pi-times',
        command: () => {
          setDialogData({
            type: 'confirmation',
            show: true,
            header: t('dialog.delete'),
            message: t('dialog.delete.reseller'),
            onAccept: async () => handleDeleteReseller(reseller.id),
          });
        },
      });
    }

    return actions;
  };

  const toolbar = (
    <DataTableToolbar>
      <FlexContainer
        justify="space-between"
        gap={8}
        align="flex-start"
        wrap="wrap"
      >
        <DataTableFilters
          filters={getFiltersFromColumns(columns)}
          onFilter={onFilter}
          defaultValues={{
            name: ' ',
          }}
          tableName={TableNamesEnum.RESELLERS_LIST}
        />
        {can(Actions.CREATE, Subjects.RESELLERS) &&
          (!currentAccount?.isSystem ||
            currentUser?.role.code === SystemRoles.DEVELOPER) && (
            <AppButton
              label={t('button.createNew')}
              severity="secondary"
              onClick={() =>
                navigate(RedirectPaths[RedirectPathsEnum.CREATE_RESELLERS]())
              }
            />
          )}
      </FlexContainer>
      <DataTableColumnsMultiselect
        columns={columns}
        tableName={TableNamesEnum.RESELLERS_LIST}
        visibleColumns={visibleColumns}
        setVisibleColumns={setVisibleColumns}
        defaultVisibleColumns={defaultVisibleColumns}
        alwaysVisibleColumns={alwaysVisibleColumns}
      />
    </DataTableToolbar>
  );

  return (
    <>
      <AppBreadCrumb model={getpathItems(currentAccount as Account, t)} />
      <ResellerTabs />
      <h1>{t('resellers')}</h1>
      <DataTable
        data={resellers?.result}
        count={resellers?.count as number}
        isLoading={isLoading}
        toolbar={toolbar}
        columns={columns}
        visibleColumns={visibleColumns}
        onPage={onPage}
        rows={take}
        skip={skip}
        onSort={onSort}
        sort={sort}
        onRowClick={onRowClick}
      />
    </>
  );
};
