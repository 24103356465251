import { Account } from '@/client/accounts';
import { Actions, Subjects } from '@/client/users';
import { RedirectPaths, RedirectPathsEnum } from '@/common/constants';
import { TranslationFunctionType } from '@/common/types';
import { useAppSelector } from '@/hooks/store';
import { usePermission } from '@/hooks/usePermission';
import { selectCurrentAccount } from '@/store/features/account';
import { selectCurrentUser } from '@/store/features/users';
import { branchAdminCheck, isOutsideOfEfront } from '@/utils/helpers';
import classNames from 'classnames';
import { MenuItem } from 'primereact/menuitem';
import { TabMenu } from 'primereact/tabmenu';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

type AccountTabsProps = {
  account: Account;
};

const getMenuItems = (
  account: Account,
  currentAccount: Account,
  can: (action: Actions, subject: Subjects) => boolean,
  t: TranslationFunctionType,
  isBranchAdmin: boolean,
): MenuItem[] => {
  const items: MenuItem[] = [
    {
      label: t('generic.info'),
      template: getItemTemplate(
        !currentAccount?.isSystem
          ? RedirectPaths[RedirectPathsEnum.ACCOUNT](account?.id)
          : RedirectPaths[RedirectPathsEnum.EDIT_ACCOUNT](account?.id),
        location.pathname,
      ),
      data: !currentAccount?.isSystem
        ? RedirectPaths[RedirectPathsEnum.ACCOUNT](account?.id)
        : RedirectPaths[RedirectPathsEnum.EDIT_ACCOUNT](account?.id),
    },
  ];

  if (can(Actions.READ, Subjects.AD_SYNC) && !isBranchAdmin) {
    items.push({
      label: t('account.azure'),
      template: getItemTemplate(
        RedirectPaths[RedirectPathsEnum.ACCOUNT_AZURE_AD](account?.id),
        location.pathname,
      ),
      data: RedirectPaths[RedirectPathsEnum.ACCOUNT_AZURE_AD](account?.id),
    });
  }

  if (
    can(Actions.READ, Subjects.ACCOUNT_SSO) &&
    isOutsideOfEfront() &&
    !isBranchAdmin
  ) {
    items.push({
      label: t('sso'),
      template: getItemTemplate(
        RedirectPaths[RedirectPathsEnum.ACCOUNT_SSO](account?.id),
        location.pathname,
      ),
      data: RedirectPaths[RedirectPathsEnum.ACCOUNT_SSO](account?.id),
    });
  }

  if (
    currentAccount &&
    currentAccount?.isSystem &&
    can(Actions.READ, Subjects.ACCOUNTS)
  ) {
    items.push({
      label: t('integrations'),
      template: getItemTemplate(
        RedirectPaths[RedirectPathsEnum.ACCOUNT_INTEGRATIONS](account?.id),
        location.pathname,
      ),
      data: RedirectPaths[RedirectPathsEnum.ACCOUNT_INTEGRATIONS](account?.id),
    });
  }

  /*
if (currentAccount?.isSystem && can(Actions.READ, Subjects.ACCOUNTS)) {
items.push({
label: t('saml.config', { name: '' }),
template: getItemTemplate(
RedirectPaths[RedirectPathsEnum.ACCOUNT_SAML_CONFIG](account?.id),
location.pathname,
),
});
}
*/

  if (
    currentAccount?.isSystem &&
    can(Actions.READ, Subjects.USERS_MIGRATION) &&
    can(Actions.READ, Subjects.ACCOUNT_MIGRATION)
  ) {
    items.push({
      label: t('migration'),
      template: getItemTemplate(
        RedirectPaths[RedirectPathsEnum.ACCOUNT_MIGRATION](account?.id),
        location.pathname,
      ),
      data: RedirectPaths[RedirectPathsEnum.ACCOUNT_MIGRATION](account?.id),
    });
  }

  if (
    currentAccount?.isSystem &&
    can(Actions.CREATE, Subjects.COURSE_ACCOUNT_AVAILABILITY)
  ) {
    items.push(
      {
        label: t('courses'),
        template: getItemTemplate(
          RedirectPaths[RedirectPathsEnum.ACCOUNT_COURSES](account?.id),
          location.pathname,
        ),
        data: RedirectPaths[RedirectPathsEnum.ACCOUNT_COURSES](account?.id),
      },
      {
        label: t('materials'),
        template: getItemTemplate(
          RedirectPaths[RedirectPathsEnum.ACCOUNT_SECURITY_DOCUMENTS](
            account?.id,
          ),
          location.pathname,
        ),
        data: RedirectPaths[RedirectPathsEnum.ACCOUNT_SECURITY_DOCUMENTS],
      },
    );
  }

  return items;
};

const getItemTemplate = (url: string, current: string) => (item: MenuItem) =>
  (
    <Link
      to={url}
      className={classNames({
        'p-menuitem-link': true,
        'p-menuitem-link-active': current.includes(url),
      })}
      role="menuitem"
    >
      <span className="p-menuitem-text">{item.label}</span>
    </Link>
  );

export const AccountTabs: React.FC<AccountTabsProps> = ({ account }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const currentUser = useAppSelector(selectCurrentUser);
  const currentAccount = useAppSelector(selectCurrentAccount);
  const { can } = usePermission();

  const isBranchAdmin = branchAdminCheck(currentUser, currentAccount);

  const items = getMenuItems(
    account as Account,
    currentAccount as Account,
    can,
    t,
    isBranchAdmin,
  );

  const paths = items.map((item) => item.data);
  const currentPath = location.pathname;
  const [activeIndex, setActiveIndex] = useState<number>(
    paths.indexOf(currentPath),
  );

  return (
    <TabMenu
      model={items}
      activeIndex={activeIndex}
      onTabChange={(e) => setActiveIndex(e.index)}
    />
  );
};
