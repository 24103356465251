import { FilePurpose, GroupCondition } from '@/api/enums';
import { equal, group, nested } from '@/api/helpers';
import { ListingRequest } from '@/api/types';
import { client } from '@/client';
import { Account } from '@/client/accounts';
import { File } from '@/client/files';
import { ResellerFormState } from '@/client/resellers';
import { Actions, Subjects } from '@/client/users';
import { LoadingStateType } from '@/common/constants';
import { FormikDropdown } from '@/components/form';
import { FormikInput } from '@/components/form/FormikInput';
import { PartialRequestMultiselect } from '@/components/form/generic';
import { LazyLoadingSelect } from '@/components/form/generic/LazyLoadingSelect';
import { usePermission } from '@/hooks/usePermission';
import { AppButton } from '@/ui/buttons';
import { ImageUpload } from '@/ui/image-upload';
import { FlexContainer, FormContainer } from '@/ui/styled-ui';
import { keycloakThemeOptions } from '@/utils/helpers';
import { Field, Form, Formik } from 'formik';
import { DropdownChangeEvent } from 'primereact/dropdown';
import { classNames } from 'primereact/utils';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { resellerSchema } from './validations';

type ResellerFormProps = {
  initialValues: ResellerFormState;
  isCreate?: boolean;
  resellerId?: string;
  preselectedLogo?: File;
  preselectedEmailLogo?: File;
  preselectedFavIcon?: File;
  state?: LoadingStateType;
  onSubmit: (data: ResellerFormState) => void;
  disabled?: boolean;
  locked?: boolean;
};

export const ResellerForm: React.FC<ResellerFormProps> = ({
  initialValues,
  isCreate,
  resellerId,
  preselectedLogo,
  preselectedEmailLogo,
  preselectedFavIcon,
  state,
  onSubmit,
  disabled,
  locked,
}) => {
  const { t } = useTranslation();
  const { cannot } = usePermission();
  const [isSubmitDisabled, setIsSubmitDisabled] = useState<boolean>(false);

  const fetchAccounts = disabled
    ? undefined
    : async (params: ListingRequest) =>
        await client.accounts.getAccounts(params);

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={resellerSchema(t)}
      onSubmit={onSubmit}
      validateOnMount
    >
      {({ values, errors, touched, setFieldValue, setFieldTouched }) => {
        return (
          <FormContainer width={100}>
            <h1 className="mt-4 mb-4">
              {isCreate
                ? t('reseller.createNew')
                : t('reseller.edit', { name: initialValues.name })}
            </h1>
            <Form className="w-full" autoComplete="off">
              <FlexContainer
                gap={24}
                width="100%"
                justify="space-between"
                align="flex-start"
              >
                <div className="w-full">
                  <div className="field w-full mb-4">
                    <Field
                      id="name"
                      name="name"
                      label={t('generic.name')}
                      className="w-full"
                      component={FormikInput}
                      placeholder={t('reseller.name')}
                      required
                      disabled={disabled || locked}
                    />
                  </div>
                  <div className="field w-full mb-4">
                    <Field
                      id="email"
                      name="email"
                      label={t('generic.email')}
                      className="w-full"
                      component={FormikInput}
                      placeholder={t('reseller.email')}
                      required
                      disabled={disabled || locked}
                    />
                  </div>
                  <div className="field w-full mb-4">
                    <Field
                      id="phone"
                      name="phone"
                      label={t('generic.phone')}
                      className="w-full"
                      component={FormikInput}
                      placeholder={t('reseller.phone')}
                      required
                      disabled={disabled || locked}
                    />
                  </div>
                  <div className="field w-full mb-4">
                    <label
                      htmlFor="mainAccount"
                      className={classNames({
                        'p-error': touched.mainAccount && !!errors.mainAccount,
                      })}
                    >
                      {t('reseller.mainAccount')}
                      <span className="red"> *</span>
                    </label>
                    <LazyLoadingSelect
                      required
                      fetchService={!isCreate ? undefined : fetchAccounts}
                      additionalFilters={
                        !resellerId
                          ? [equal('mainAccountOfReseller', null)]
                          : [
                              group(GroupCondition.OR, [
                                equal('mainAccountOfReseller', resellerId),
                                equal('mainAccountOfReseller', null),
                              ]),
                            ]
                      }
                      isDisabled={disabled || !isCreate || locked}
                      className="w-full"
                      selectedValue={values?.mainAccount}
                      optionLabel="name"
                      filter
                      placeholder={t('account.select')}
                      filterPlaceholder={t('accounts.search')}
                      handleOnChange={(value: Account) =>
                        setFieldValue('mainAccount', value)
                      }
                      onBlur={() => setFieldTouched('mainAccount', true)}
                    />
                    {touched.mainAccount && !!errors.mainAccount ? (
                      <small className="p-error">{errors.mainAccount}</small>
                    ) : null}
                  </div>
                  <div className="field w-full mb-4">
                    <Field
                      id="baseUrl"
                      name="baseUrl"
                      label={t('reseller.baseUrl')}
                      className="w-full"
                      component={FormikInput}
                      placeholder={t('reseller.baseUrl')}
                      required
                      disabled={
                        disabled ||
                        (!isCreate &&
                          cannot(
                            Actions.READ,
                            Subjects.RESELLERS_DOMAIN_CONFIG,
                          )) ||
                        locked
                      }
                    />
                  </div>
                  <div className="field w-full mb-4">
                    <label>{t('accounts')}</label>
                    <PartialRequestMultiselect
                      id="accounts"
                      name="accounts"
                      filter
                      dataKey="id"
                      optionLabel="name"
                      display="chip"
                      handleOnChange={(values: Account[]) =>
                        setFieldValue('accounts', values)
                      }
                      className="w-full"
                      placeholder={t('accounts.select')}
                      fetchService={fetchAccounts}
                      maxSelectedLabels={3}
                      additionalFilters={
                        isCreate && !resellerId
                          ? [
                              equal('reseller', null),
                              equal('mainAccountOfReseller', null),
                            ]
                          : [
                              group(GroupCondition.OR, [
                                nested('reseller', [equal('id', resellerId)]),
                                equal('reseller', null),
                              ]),
                              equal('mainAccountOfReseller', null),
                            ]
                      }
                      selectedOptions={values?.accounts}
                      isDisabled={disabled}
                    />
                  </div>
                  <AppButton
                    isSubmit
                    severity="secondary"
                    label={isCreate ? t('button.create') : t('button.save')}
                    className="w-4 mt-4 mb-5"
                    state={state}
                    isDisabled={
                      !!Object.keys(errors).length ||
                      disabled ||
                      isSubmitDisabled
                    }
                  />
                </div>
                <div className="w-full">
                  <div className="field w-full mb-4">
                    <Field
                      id="theme"
                      label={t('reseller.theme')}
                      name="theme"
                      required
                      showClear
                      filter
                      onChange={(e: DropdownChangeEvent) =>
                        setFieldValue('theme', e.value)
                      }
                      onBlur={() => setFieldTouched('theme', true)}
                      className="w-full"
                      component={FormikDropdown}
                      placeholder={t('generic.select')}
                      options={keycloakThemeOptions}
                      disabled={disabled || locked}
                    />
                  </div>
                  <div className="field w-full mb-4">
                    <ImageUpload
                      label={t('reseller.platformLogo')}
                      onFileUpload={(file: File | null) =>
                        setFieldValue('logo', file?.id)
                      }
                      purpose={FilePurpose.THUMBNAIL}
                      preselectedFile={preselectedLogo}
                      setIsSubmitDisabled={setIsSubmitDisabled}
                      isDisabled={disabled || locked}
                    />
                  </div>
                  <div className="field w-full mb-4">
                    <ImageUpload
                      label={t('reseller.emailLogo')}
                      onFileUpload={(file: File | null) =>
                        setFieldValue('emailLogo', file?.id)
                      }
                      purpose={FilePurpose.THUMBNAIL}
                      preselectedFile={preselectedEmailLogo}
                      setIsSubmitDisabled={setIsSubmitDisabled}
                      isDisabled={disabled || locked}
                    />
                  </div>
                  <div className="field w-full mb-4">
                    <ImageUpload
                      label={t('reseller.favIcon')}
                      onFileUpload={(file: File | null) =>
                        setFieldValue('favIcon', file?.id)
                      }
                      purpose={FilePurpose.THUMBNAIL}
                      preselectedFile={preselectedFavIcon}
                      setIsSubmitDisabled={setIsSubmitDisabled}
                      isDisabled={disabled || locked}
                    />
                  </div>
                </div>
              </FlexContainer>
            </Form>
          </FormContainer>
        );
      }}
    </Formik>
  );
};
