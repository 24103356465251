import { FlexContainer } from '@/ui/styled-ui';
import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { LearnerProvider } from './context';
import { LearnerDashboardTabs } from './LearnerDashboardTabs';

const StyledPageContainer = styled(FlexContainer)`
  @media screen and (max-width: 812px) {
    padding-top: 0;
  }
  position: relative;
  padding-top: 76px;
  min-height: 100%;
`;

type LearnerLayoutProps = {
  children: ReactNode;
  isLoading: boolean;
};

export const LearnerLayout: React.FC<LearnerLayoutProps> = ({
  children,
  isLoading,
}) => {
  return (
    <LearnerProvider isLoading={isLoading}>
      <LearnerDashboardTabs />
      <StyledPageContainer
        direction="column"
        justify="flex-start"
        align="flex-start"
        className="w-full"
      >
        {children}
      </StyledPageContainer>
    </LearnerProvider>
  );
};
