export enum Actions {
  READ = 'read',
  CREATE = 'create',
  UPDATE = 'update',
  DELETE = 'delete',
  MANAGE = 'manage', // System casl ability, will grant all,
  ADD_USER = 'add_user',
  REMOVE_USER = 'remove_user',
  ADD_COURSE = 'add_course',
}
