import { FiltersType, GroupedFilterConditions } from '@/api/types';
import { client } from '@/client';
import { MessageResponseModel } from '@/client/models';
import {
  AddCoursePlannersRequest,
  CoursePlanner,
  DeletePlannerRequest,
  ListCoursePlannerResponse,
  SavePlannerRequest,
} from '@/client/planner/types';
import {
  useMutation,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query';
import { GenericForbiddenApiError } from './types';
import {
  AddCoursePlannersMutation,
  DeletePlannerMutation,
  SavePlannerMutation,
} from './types/planner.type';

const queryKey = 'planners';

export const usePlanners = (
  params: UseQueryOptions & {
    take?: number;
    skip?: number;
    sort?: string[];
    filters?: FiltersType | GroupedFilterConditions;
  },
  accountId?: string,
): UseQueryResult<ListCoursePlannerResponse> & {
  planners: ListCoursePlannerResponse | undefined;
} => {
  const {
    take = 200,
    skip = 0,
    sort,
    filters,
    retry = false,
    retryDelay = 500,
    staleTime = 0,
    cacheTime = 0,
    ...restOptions
  } = params;

  const { data, ...rest } = useQuery(
    [queryKey, take, skip, JSON.stringify(sort), JSON.stringify(filters)],
    () => client.planner.getPlanners(params, accountId),
    {
      retry,
      retryDelay,
      staleTime,
      cacheTime,
      ...(restOptions as any),
    },
  );

  return {
    planners: data,
    ...(rest as UseQueryResult<ListCoursePlannerResponse>),
  };
};

export const useCreatePlanner = (): {
  create: (payload: SavePlannerRequest) => Promise<CoursePlanner>;
} & SavePlannerMutation => {
  const { mutateAsync, ...rest } = useMutation<
    CoursePlanner,
    GenericForbiddenApiError,
    SavePlannerRequest
  >('addPlanner', (payload: SavePlannerRequest) =>
    client.planner.createPlanner(payload.accountId, payload),
  );

  return { create: mutateAsync, ...(rest as any) };
};

export const useUpdatePlanner = (): {
  update: (payload: SavePlannerRequest) => Promise<CoursePlanner>;
} & SavePlannerMutation => {
  const { mutateAsync, ...rest } = useMutation<
    CoursePlanner,
    GenericForbiddenApiError,
    SavePlannerRequest
  >('updatePlanner', (payload: SavePlannerRequest) =>
    client.planner.updatePlanner(payload.accountId, payload),
  );

  return { update: mutateAsync, ...(rest as any) };
};

export const useDeletePlanner = (): {
  delete: (payload: DeletePlannerRequest) => Promise<MessageResponseModel>;
} & DeletePlannerMutation => {
  const { mutateAsync, ...rest } = useMutation<
    MessageResponseModel,
    GenericForbiddenApiError,
    DeletePlannerRequest
  >('deletePlanner', (payload: DeletePlannerRequest) =>
    client.planner.deletePlanner(payload),
  );

  return { delete: mutateAsync, ...(rest as any) };
};

export const usePlannersSchedules = (): {
  add: (payload: AddCoursePlannersRequest) => Promise<MessageResponseModel>;
} & AddCoursePlannersMutation => {
  const { mutateAsync, ...rest } = useMutation<
    MessageResponseModel,
    GenericForbiddenApiError,
    AddCoursePlannersRequest
  >('saveBranchesSchedules', (payload: AddCoursePlannersRequest) =>
    client.planner.addCoursePlanners({
      courses: payload.courses,
      date: payload.date,
    }),
  );

  return { add: mutateAsync, ...(rest as any) };
};
