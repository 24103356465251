import { SaveGroupRequest } from '@/client/groups';
import { LoadingStateType } from '@/common/constants';
import { FormikSwitch } from '@/components/form';
import { FormikInput } from '@/components/form/FormikInput';
import { groupSchema } from '@/components/groups/forms/validations';
import { AppButton } from '@/ui/buttons';
import { FormContainer } from '@/ui/styled-ui';
import { Field, Form, Formik, FormikValues } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';

type GroupFormProps = {
  initialValues: SaveGroupRequest;
  state?: LoadingStateType;
  onSubmit: (data: FormikValues) => void;
  disabled?: boolean;
};

export const GroupForm: React.FC<GroupFormProps> = ({
  initialValues,
  state,
  onSubmit,
  disabled,
}) => {
  const { t } = useTranslation();

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={groupSchema(t)}
      onSubmit={onSubmit}
    >
      {({ errors }) => (
        <FormContainer>
          <Form className="w-full" autoComplete="off">
            <div className="field w-full mb-0">
              <Field
                id="name"
                name="name"
                label={t('generic.name')}
                className="w-full"
                component={FormikInput}
                placeholder={t('group.name')}
                required={!initialValues?.name}
                disabled={disabled}
              />
            </div>
            <div className="field-checkbox mt-3">
              <Field
                inputId="autoAssign"
                name="autoAssign"
                label={t('group.autoAssign')}
                component={FormikSwitch}
                disabled={disabled}
              />
            </div>
            <AppButton
              isSubmit
              severity="secondary"
              label={initialValues.name ? t('button.save') : t('button.create')}
              className="w-4 mt-4 mb-4"
              state={state}
              isDisabled={!!Object.keys(errors).length || disabled}
            />
          </Form>
        </FormContainer>
      )}
    </Formik>
  );
};
